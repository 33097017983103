import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../styles/style.scss";
import Fraction from "fraction.js";
import FullChatBot from "./FullChatBot";
import Tiptap from "./Tiptap";
import "../styles/Tiptap.css";

function EditRecipe() {
  //Chatbot
  const [closeChat, setCloseChat] = useState(false);
  const [inverseConversion, setInverseConversion] = useState(false);

  const handleToggleConversion = () => {
    setInverseConversion(!inverseConversion);
  };

  function toggleCloseChat() {
    if (!hasSilver) {
      alert("A Silver subscription is required for this feature");
      return;
    }
    if (closeChat) {
      const confirmed = window.confirm(
        `Are you sure you want to close you chat with Remy? Your conversation will be erased!`
      );
      if (!confirmed) return;
    }
    setCloseChat(!closeChat);
  }

  const toggleClearChat = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the chat history? You cannot undo?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/delete-chat-history`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }), // Send recipeId in the request body
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      setCloseChat(!closeChat);
    } catch (error) {
      console.error(error);
    }
  };
  //
  const navigate = useNavigate();
  const { id } = useParams();
  const [isMetric, setIsMetric] = useState(false);
  const [hasGold, setHasGold] = useState(false);
  const [hasSilver, setHasSilver] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/gold`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasGold(true);
          setHasSilver(true);
          console.log("Gold");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasGold(false);
        } else {
          console.log("Error");
          setHasGold(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasGold(false);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/silver`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasSilver(true);
          console.log("Silver");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasSilver(false);
        } else {
          console.log("Error");
          setHasSilver(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasSilver(false);
      });
  }, [id]);

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, [id]);

  //Macro
  let [totalProtein, setTotalProtein] = useState(0);
  let [totalCarbs, setTotalCarbs] = useState(0);
  let [totalFat, setTotalFat] = useState(0);
  let [totalFiber, setTotalFiber] = useState(0);
  let [totalSugar, setTotalSugar] = useState(0);
  let [totalCalories, setTotalCalories] = useState(0);
  let [totalPrice, setTotalPrice] = useState(0);

  //Editing Ingredient
  const [selectedRecipeIngredient, setSelectedRecipeIngredient] =
    useState(null);
  const [editRecipeIngredientName, setEditRecipeIngredientName] = useState("");
  const [editIngredientQty, setEditIngredientQty] = useState("");
  const [editIngredientCalorie, setEditIngredientCalorie] = useState("");
  const [editIngredientUnit, setEditIngredientUnit] = useState("");
  const [editIngredientSuffix, setEditIngredientSuffix] = useState("");
  const [selectedRecipeIngredientId, setSelectedRecipeIngredientId] =
    useState("");

  // Editing Instruction
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [editInstructionStep, setEditInstructionStep] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [userIsOwner, setUserIsOwner] = useState(false);
  // Insturctions
  const [addInstruction, setAddInstruction] = useState("");

  const [imageUrl, setImageUrl] = useState("");

  const fetchUserMetricSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      setCurrentUser(user.id);
      setIsMetric(user.default_unit_of_measurement === "metric");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggle = () => {
    setIsMetric(!isMetric);

    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        default_unit_of_measurement: isMetric ? "traditional" : "metric",
      }),
    }).then((response) => {
      fetchRecipeIngredients(id);
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const [recipeData, setRecipeData] = useState(null);
  const [recipeName, setRecipeName] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [newSelectedIngredient, setNewSelectedIngredient] = useState("");

  const [
    editIngredientConversionsOptions,
    setEditIngredientConversionsOptions,
  ] = useState([]);

  const handleEditIngredient = (ingredient) => {
    setSelectedRecipeIngredient(ingredient);
    setSelectedRecipeIngredientId(ingredient.id);
    setEditRecipeIngredientName(ingredient.name);
    setEditIngredientQty(ingredient.qty);
    setEditIngredientCalorie(ingredient.ingredient_calorie_percent);
    setEditIngredientUnit(ingredient.unit_name);
    setEditIngredientSuffix(ingredient.suffix);
    const option = {
      value: ingredient.old_id,
      label: ingredient.name,
    };
    handleEditChange(option);
  };

  const handleEditInstruction = (instruction) => {
    setSelectedInstruction(instruction);
    setEditInstructionStep(instruction.instruction);
  };

  // NEW
  const [newIngredientConversionsOptions, setNewIngredientConversionsOptions] =
    useState([]);
  const [
    newIngredientConversionsOptionsId,
    setNewIngredientConversionsOptionsId,
  ] = useState("");
  const [
    newIngredientConversionsOptionsName,
    setNewIngredientConversionsOptionsName,
  ] = useState("");
  const [newIngredientConversionFactor, setNewIngredientConversionFactor] =
    useState("");

  //Edit

  const [
    editIngredientConversionsOptionsId,
    setEditIngredientConversionsOptionsId,
  ] = useState("");
  const [
    editIngredientConversionsOptionsName,
    setEditIngredientConversionsOptionsName,
  ] = useState("");
  const [editIngredientConversionFactor, setEditIngredientConversionFactor] =
    useState("");

  const [newQty, setNewQty] = useState("");
  const [newSuffix, setNewSuffix] = useState("");
  const [newCalorie, setNewCalorie] = useState(100);

  const courseOptions = [
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Entree", label: "Entree" },
    { value: "Appetizer", label: "Appetizer" },
    { value: "Snack", label: "Snack" },
    { value: "Dessert", label: "Dessert" },
    { value: "Side", label: "Side" },
    { value: "Dips and Sauce", label: "Dips and Sauce" },
    { value: "Marinade", label: "Marinade" },
    { value: "Beverage", label: "Beverage" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const preloadedCourseOption = recipeData?.course
    ? { value: recipeData.course, label: recipeData.course }
    : null;

  const [recipeCourse, setRecipeCourse] = useState(
    preloadedCourseOption
      ? courseOptions.find(
          (option) => option.value === preloadedCourseOption.value
        )
      : null
  );

  const [recipeServings, setRecipeServings] = useState("");
  const [recipeServingSize, setRecipeServingSize] = useState("");
  const [recipeTags, setRecipeTags] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [recipeInstructions, setRecipeInstructions] = useState([]);

  const fetchRecipeData = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/recipes/user-check/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setRecipeData(data);
        setRecipeName(data.name ? data.name : "");
        setRecipeCourse(
          data.course
            ? courseOptions.find((option) => option.value === data.course)
            : null
        );
        setRecipeServings(data.servings ? data.servings : 0);
        setRecipeServingSize(data.serving_size ? data.serving_size : 0);
        setRecipeTags(data.tags ? data.tags : "");
        setRecipeDescription(data.description ? data.description : "");
        setImageUrl(`${data.image_url}?updated=${Date.now()}`);
        setPublicNotesContent(data.public_notes);
      })
      .catch((error) => console.error("Error retrieving recipe data:", error));
  };

  // Call fetchRecipeData with the recipe ID to fetch the data
  useEffect(() => {
    fetchRecipeData(id);
  }, [id]);

  const fetchPrivateNotes = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/get_private_recipe_notes/${id}`,
      {
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data[0] && data[0].private_note !== undefined) {
          setNotesContent(data[0].private_note);
        } else {
          // Set notesContent to an empty string if private_note is undefined
          setNotesContent(null);
        }
      })
      .catch((error) => console.error("Error retrieving recipe data:", error));
  };

  // Call fetchRecipeData with the recipe ID to fetch the data
  useEffect(() => {
    fetchPrivateNotes(id);
  }, [recipeData]);

  const handleSetUnitOptionDefault = (ingredientRowId) => {
    console.log(ingredientRowId);

    fetch(`${process.env.REACT_APP_API_URL}/api/setunit`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientRowId: ingredientRowId,
        conversionId: 0,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success");
        fetchRecipeIngredients(id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSetUnitOption = (ingredientRowId, conversionId) => {
    console.log(ingredientRowId);
    console.log(conversionId);

    fetch(`${process.env.REACT_APP_API_URL}/api/setunit`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientRowId: ingredientRowId,
        conversionId: conversionId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success");
        fetchRecipeIngredients(id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Handle Form Changes
  const handleRecipeNameChange = (event) => {
    setRecipeName(event.target.value);
  };

  //Handle Form Changes
  const handleRecipeCourseChange = (selectedOption) => {
    setRecipeCourse(selectedOption);
  };

  //Handle Form Changes
  const handleRecipeServingsChange = (event) => {
    if (!isNaN(event)) {
      setRecipeServings(event);
    } else {
      setRecipeServings("");
    }
  };

  //Handle Form Changes
  const handleRecipeServingSizeChange = (event) => {
    setRecipeServingSize(event.target.value);
  };

  //Handle Form Changes
  const handleRecipeTagsChange = (event) => {
    setRecipeTags(event.target.value);
  };

  //Handle Form Changes
  const handleRecipeDescriptionChange = (event) => {
    setRecipeDescription(event.target.value);
  };

  // Update Recipe
  const handleUpdateRecipe = () => {
    const updatedRecipe = {
      name: recipeName,
      course: recipeCourse.value,
      servings: recipeServings,
      serving_size: recipeServingSize,
      tags: recipeTags,
      description: recipeDescription,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/recipes/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedRecipe),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Recipe updated successfully.");
          fetchRecipeData(id); // Call the function here
        } else {
          throw new Error("Failed to update recipe");
        }
      })
      .catch((error) => console.error(error));
  };

  // New Qty:
  const handleNewQtyChange = (newQtyValue) => {
    if (isNaN(newQtyValue)) {
      setNewQty("");
    } else {
      setNewQty(newQtyValue);
    }
  };

  // Edit Qty:
  const handleEditQtyChange = (editIngredientQty) => {
    if (isNaN(editIngredientQty)) {
      setEditIngredientQty("");
    } else {
      setEditIngredientQty(editIngredientQty);
    }
  };

  // New Calorie:
  const handleNewCalorieChange = (newCalorieValue) => {
    if (isNaN(newCalorieValue)) {
      setNewCalorie("");
    } else {
      setNewCalorie(newCalorieValue);
    }
  };

  // Edit Calorie:
  const handleEditCalorieChange = (editIngredientCalorie) => {
    if (isNaN(editIngredientCalorie)) {
      setEditIngredientCalorie("");
    } else {
      setEditIngredientCalorie(editIngredientCalorie);
    }
  };

  // New Suffix:
  const handleNewSuffixChange = (event) => {
    setNewSuffix(event.target.value);
  };

  // Edit Suffix
  const handleEditSuffixChange = (event) => {
    setEditIngredientSuffix(event.target.value);
  };

  // Fetch Ingredients for Chart
  const fetchIngredients = () => {
    fetch(`${process.env.REACT_APP_API_URL}/ingredients`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        // Map data to an array of options
        const options = data.map((ingredient) => ({
          label: ingredient.ingredient_name,
          value: ingredient.id,
        }));
        setIngredients(options);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  const handleEditChange = (selectedOption) => {
    // Determine custom_ingredient flag
    console.log(selectedOption);
    setSelectedRecipeIngredientId(selectedOption.value);
    setEditRecipeIngredientName(selectedOption.label);
    setNewSelectedIngredient(selectedOption.value);
    const customIngredientFlag = selectedOption.value.includes("user") ? 1 : 0;
    const ingredientId = selectedOption.value.split(",")[0];

    // Make API call to retrieve unit conversions
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ingredient-all-units/${ingredientId}?custom_ingredient=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setEditIngredientConversionsOptions(data);
      })
      .catch((error) => {
        console.error("Error retrieving ingredient conversions:", error);
        setEditIngredientConversionsOptions([]);
      });

    console.log(`Option selected:`, selectedOption);
  };

  //Handle Form Changes
  const handleInstructionChange = (event) => {
    setAddInstruction(event.target.value);
  };

  //Handle Form Changes
  const handleEditInstructionChange = (event) => {
    setEditInstructionStep(event.target.value);
  };

  // Add this useEffect block after the `handleEditChange` function
  useEffect(() => {
    const selectedUnit = editIngredientConversionsOptions.find(
      (option) => option.unit_name === editIngredientUnit
    );
    if (selectedUnit) {
      setEditIngredientConversionsOptionsId(selectedUnit.unit_id);
      setEditIngredientConversionsOptionsName(selectedUnit.unit_name);
      setEditIngredientConversionFactor(selectedUnit.conversion_factor);
    }
  }, [editIngredientConversionsOptions, editIngredientUnit]);

  const handleChange = (selectedOption) => {
    // Determine custom_ingredient flag
    console.log(selectedOption.value);
    setNewSelectedIngredient(selectedOption.value);
    const customIngredientFlag = selectedOption.value.includes("user") ? 1 : 0;
    const ingredientId = selectedOption.value.split(",")[0];

    // Make API call to retrieve unit conversions
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ingredient-all-units/${ingredientId}?custom_ingredient=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((data) => {
        setNewIngredientConversionsOptions(data);
        setNewIngredientConversionsOptionsId(data[0].unit_id);
        setNewIngredientConversionsOptionsName(data[0].unit_name);
        setNewIngredientConversionFactor(data[0].conversion_factor);
      })
      .catch((error) => {
        console.error("Error retrieving ingredient conversions:", error);
        setNewIngredientConversionsOptions([]);
      });

    console.log(`Option selected:`, selectedOption);
  };

  const fetchRecipeIngredients = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRecipeIngredients(data);
        const totalProtein = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.protein),
          0
        );
        const totalCarbs = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.carbs),
          0
        );
        const totalFat = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.fat),
          0
        );
        const totalFiber = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.fiber),
          0
        );

        const totalSugar = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.sugar),
          0
        );
        const totalCalories = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.calories),
          0
        );
        const totalPrice = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.price),
          0
        );

        // send PUT request to update recipe with total values
        fetch(`${process.env.REACT_APP_API_URL}/api/recipe/totals/${id}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            total_calories: totalCalories,
            total_price: totalPrice,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success");
          })
          .catch((error) => {
            console.error(error);
          });

        setTotalProtein(totalProtein);
        setTotalCarbs(totalCarbs);
        setTotalFat(totalFat);
        setTotalFiber(totalFiber);
        setTotalSugar(totalSugar);
        setTotalCalories(totalCalories);
        setTotalPrice(totalPrice);
      });
  };

  //Add New Ingredient to Recipe
  const handleAddIngredientToRecipe = () => {
    // Split ingredientId to get the ID
    const ingredientId = newSelectedIngredient.split(",")[0];

    // Determine if ingredient is custom
    const customIngredientFlag = newSelectedIngredient.includes("user") ? 1 : 0;

    // Send POST request to add new ingredient to recipe
    fetch(`${process.env.REACT_APP_API_URL}/api/add-ingredient-to-recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        ingredient_id: ingredientId,
        custom_ingredient: customIngredientFlag,
        qty: newQty * newIngredientConversionFactor,
        suffix: newSuffix,
        old_id: newSelectedIngredient,
        calorie_percent: newCalorie != null ? newCalorie : 100,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload recipe data
          fetchRecipeData(id);
          // setNewSelectedIngredient("");
          setNewQty(0);
          setNewSuffix("");
          setNewCalorie(100);
          setNewIngredientConversionFactor(1);
          fetchRecipeIngredients(id);
        } else {
          throw new Error("Error adding new ingredient to recipe");
        }
      })
      .catch((err) => {
        console.error("Error adding new ingredient to recipe:", err);
      });
  };

  function updateIngredientColor(ingredientId, color) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/ingredients/${ingredientId}`;
    const options = {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ color }),
    };

    fetch(url, options)
      .then((response) => {
        fetchRecipeIngredients(id);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success ");

        // handle response as needed
      })
      .catch((error) => {
        console.error("API error: ", error);
        // handle error as needed
      });
  }
  ///
  ///
  function updateInstructionColor(instructionId, color) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/instruction/${instructionId}`;
    const options = {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ color }),
    };

    fetch(url, options)
      .then((response) => {
        fetchRecipeInstructions(id);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success");

        // handle response as needed
      })
      .catch((error) => {
        console.error("API error: ", error);
        // handle error as needed
      });
  }
  ///
  ///
  ///
  const handleUpdateRecipeIngredient = () => {
    const ingredientId = selectedRecipeIngredientId.split(",")[0];

    // Determine if ingredient is custom
    const customIngredientFlag = selectedRecipeIngredientId.includes("user")
      ? 1
      : 0;

    // Send POST request to add new ingredient to recipe
    fetch(`${process.env.REACT_APP_API_URL}/api/update-ingredient-to-recipe`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        row_id: selectedRecipeIngredient.id,
        ingredient_id: ingredientId,
        custom_ingredient: customIngredientFlag,
        qty: editIngredientQty * editIngredientConversionFactor,
        suffix: editIngredientSuffix,
        old_id: newSelectedIngredient,
        calorie_percent:
          editIngredientCalorie != null ? editIngredientCalorie : 100,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload recipe data
          fetchRecipeData(id);
          setSelectedRecipeIngredient(null);
          setEditIngredientQty(0);
          setEditIngredientCalorie(100);
          setEditIngredientSuffix("");
          setEditIngredientConversionFactor(1);
          fetchRecipeIngredients(id);
        } else {
          throw new Error("Error adding new ingredient to recipe");
        }
      })
      .catch((err) => {
        console.error("Error adding new ingredient to recipe:", err);
      });
  };

  const handleDeleteIngredient = async (ingredient) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the ingredient "${ingredient.name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/ingredient/delete/${ingredient.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      fetchRecipeIngredients(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRecipe = async (id) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the recipe - "${recipeData.name}"? You CANNOT undo this.`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/recipe/delete/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recipes`;
    } catch (error) {
      console.error(error);
    }
  };

  // handleUpArrowClick function
  function handleUpArrowClick(currentIndex, currentId, currentSortOrder) {
    const previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      // handle case where there is no previous ingredient

      return;
    }
    const previousId = recipeIngredients[previousIndex].id;
    const previousSortOrder = recipeIngredients[previousIndex].sort_order;
    // do something with currentId and previousId

    fetch(`${process.env.REACT_APP_API_URL}/api/ingredients/sort`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id1: currentId,
        sortOrder1: previousSortOrder,
        id2: previousId,
        sortOrder2: currentSortOrder,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload  data
          fetchRecipeIngredients(id);
        } else {
          throw new Error("Error swapping ingredients");
        }
      })
      .catch((err) => {
        console.error("Error swapping ingredients:", err);
      });
  }

  function handleDownArrowClick(currentIndex, currentId, currentSortOrder) {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= recipeIngredients.length) {
      // handle case where there is no previous ingredient

      return;
    }
    const nextId = recipeIngredients[nextIndex].id;
    const nextSortOrder = recipeIngredients[nextIndex].sort_order;
    // do something with currentId and previousId

    fetch(`${process.env.REACT_APP_API_URL}/api/ingredients/sort`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id1: currentId,
        sortOrder1: nextSortOrder,
        id2: nextId,
        sortOrder2: currentSortOrder,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload  data
          fetchRecipeIngredients(id);
        } else {
          throw new Error("Error swapping ingredients");
        }
      })
      .catch((err) => {
        console.error("Error swapping ingredients:", err);
      });
  }

  // Add Instruction to Recipe
  const handleAddInstructionToRecipe = () => {
    // Send POST request to add new ingredient to recipe
    fetch(`${process.env.REACT_APP_API_URL}/api/add-instruction-to-recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        instructionStep: addInstruction,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload recipe data
          fetchRecipeInstructions(id);
          fetchRecipeData(id);
          fetchRecipeIngredients(id);
          setAddInstruction("");
        } else {
          throw new Error("Error adding new ingredient to recipe");
        }
      })
      .catch((err) => {
        console.error("Error adding new ingredient to recipe:", err);
      });
  };

  // handleUpArrowClick function
  function handleInstructionUpArrowClick(
    currentIndex,
    currentId,
    currentSortOrder
  ) {
    const previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      // handle case where there is no previous ingredient

      return;
    }
    const previousId = recipeInstructions[previousIndex].id;
    const previousSortOrder = recipeInstructions[previousIndex].sort_order;
    // do something with currentId and previousId

    fetch(`${process.env.REACT_APP_API_URL}/api/instruction/sort`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id1: currentId,
        sortOrder1: previousSortOrder,
        id2: previousId,
        sortOrder2: currentSortOrder,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload  data
          fetchRecipeInstructions(id);
        } else {
          throw new Error("Error swapping instruction");
        }
      })
      .catch((err) => {
        console.error("Error swapping instruction:", err);
      });
  }

  function handleInstructionDownArrowClick(
    currentIndex,
    currentId,
    currentSortOrder
  ) {
    const nextIndex = currentIndex + 1;
    if (nextIndex >= recipeInstructions.length) {
      // handle case where there is no previous ingredient
      return;
    }
    const nextId = recipeInstructions[nextIndex].id;
    const nextSortOrder = recipeInstructions[nextIndex].sort_order;
    // do something with currentId and previousId

    fetch(`${process.env.REACT_APP_API_URL}/api/instruction/sort`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id1: currentId,
        sortOrder1: nextSortOrder,
        id2: nextId,
        sortOrder2: currentSortOrder,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload  data
          fetchRecipeInstructions(id);
        } else {
          throw new Error("Error swapping instruction");
        }
      })
      .catch((err) => {
        console.error("Error swapping instruction:", err);
      });
  }

  const handleDeleteInstruction = async (instruction) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this instruction step? You cannot undo.`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/instruction/delete/${instruction.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      fetchRecipeInstructions(id);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRecipeInstructions = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/instructions/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setRecipeInstructions(data);
      });
  };

  const handleUpdateRecipeInstruction = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/update-instruction-to-recipe`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        row_id: selectedInstruction.id,
        instruction: editInstructionStep,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload recipe data
          fetchRecipeData(id);
          setSelectedInstruction(null);
          setEditInstructionStep("");
          fetchRecipeInstructions(id);
        } else {
          throw new Error("Error editing new instruction to recipe");
        }
      })
      .catch((err) => {
        console.error("Error adding new instruction to recipe:", err);
      });
  };

  const handleBackClick = () => {
    if (
      document.referrer &&
      document.referrer.startsWith(window.location.origin)
    ) {
      navigate(-1);
    } else {
      navigate("/recipes", { replace: true });
    }
  };

  async function updateImageRecord(imageUrl) {
    const updateData = {
      image_url: imageUrl.imageUrl,
      // any other fields you want to update
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/recipe-image/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateData),
      }
    );
    if (!response.ok) {
      // handle error
    }
    fetchRecipeData(id);
  }

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("id", id); // <--- add this line
    formData.append("image", file);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      // handle error
    }

    const imageUrl = await response.json();

    // Update the record in your database with the new image URL
    updateImageRecord(imageUrl);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleImageUpGenerate = async (event) => {
    handleUpdateRecipe();

    if (!hasGold) {
      alert("A Gold subscription is required for this feature");
      return;
    }

    setIsLoading(true);
    // Display confirmation message to user
    const confirmed = window.confirm(
      "Please make sure your recipe is complete before proceeding. The image generation is based off your RECIPE DESCRIPTION. The RECIPE TITLE, INGREDIENTS, and INSTRUCTIONS also impact the image that is generated. These should be complete for a more accurate image."
    );

    if (!confirmed) {
      setIsLoading(false);
      return;
    }

    const ingredientList = recipeIngredients.map(
      (ingredient) =>
        `${ingredient.qty}x ${ingredient.unit_name} ${ingredient.name} ${ingredient.suffix}`
    );
    const instructionList = recipeInstructions.map(
      (instruction, index) => `${index + 1} - ${instruction.instruction}`
    );

    const message = [
      {
        role: "user",
        content: `Respond with nothing but a Dall-e prompt I can use to generate a realistic image for my recipe. The style should be for a website. The dish should be plated. My recipe called "${recipeData.name}". Here are the ingredients: "${ingredientList}". Here are the instructions "${instructionList}". Here is the description: "${recipeData.description}"`,
      },
    ];

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/generate_image`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          message: message,
        }),
      }
    );

    if (!response.ok) {
      // handle error
    }

    const imageUrl = await response.json();

    // Update the record in your database with the new image URL
    setIsLoading(false);
    updateImageRecord(imageUrl);
  };

  // ADD NEW INGREDIENT OPTIONS
  // variables for adding new ingredient:
  const [showNewModal, setShowNewModal] = useState(false);
  const [newIngredientName, setNewIngredientName] = useState("");
  const [newUnitOfMeasurement, setNewUnitOfMeasurement] = useState("");
  const [newUnitOfMeasurementLabel, SetNewUnitOfMeasurementLabel] =
    useState("");
  const [newShoppingAisle, setNewShoppingAisle] = useState("");
  const [newProtein, setNewProtein] = useState("");
  const [newCarbs, setNewCarbs] = useState("");
  const [newFat, setNewFat] = useState("");
  const [newFiber, setNewFiber] = useState("");
  const [newSugar, setNewSugar] = useState("");
  const [newStoreAmount, setNewStoreAmount] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newCalculatedPrice, setNewCalculatedPrice] = useState(0);
  const [newCustomUnitFlag, setNewCustomUnitFlag] = useState(0);

  // Private Notes Variables
  const [showPrivateNotesModal, setShowPrivateNotesModal] = useState(false);

  const handlePrivateNotesOpenModal = () => {
    setShowPrivateNotesModal(true);
  };

  const handlePrivateNotesCloseModal = () => {
    setShowPrivateNotesModal(false);
  };

  const updateNotesContent = (updatedContent) => {
    setNotesContent(updatedContent);
  };

  // Holding the notes content state
  const [notesContent, setNotesContent] = useState("");
  const handlePrivatelSaveNotes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/private_recipe_notes_save`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        updatedNotes: notesContent,
      }),
    }).then((response) => {
      handlePrivateNotesCloseModal();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Public Notes Variables
  const [showPublicNotesModal, setShowPublicNotesModal] = useState(false);

  const handlePublicNotesOpenModal = () => {
    setShowPublicNotesModal(true);
  };

  const handlePublicNotesCloseModal = () => {
    setShowPublicNotesModal(false);
  };

  const updatePublicNotesContent = (updatedContent) => {
    setPublicNotesContent(updatedContent);
  };

  // Holding the notes content state
  const [publicNotesContent, setPublicNotesContent] = useState(null);

  const handlePublicSaveNotes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/public_recipe_notes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        updatedNotes: publicNotesContent,
      }),
    }).then((response) => {
      handlePublicNotesCloseModal();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Add New Unit Modal Show/Hide
  const handleAddClickModal = () => {
    setShowNewModal(true);
  };

  const handleAddCloseModal = () => {
    setShowNewModal(false);
  };

  // Add New Unit Handle Form Changes
  const handleNewIngredientNameChange = (event) => {
    setNewIngredientName(event.target.value);
  };

  const handleNewStoreBaseAmountChange = (event) => {
    const value = event.target.value;
    setNewStoreAmount(value);
    setNewCalculatedPrice(newPrice / value);
  };

  const handleNewStorePriceAmountChange = (event) => {
    const value = event.target.value;
    setNewPrice(value);
    setNewCalculatedPrice(value / newStoreAmount);
  };

  // New Unit Macros:
  const handleNewProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setNewProtein(newProteinValue);
    } else {
      setNewProtein(""); // Set a default value
    }
  };

  const handleNewCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setNewCarbs(newCarbsValue);
    } else {
      setNewCarbs(""); // Set a default value
    }
  };

  const handleNewFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setNewFat(newFatValue);
    } else {
      setNewFat(""); // Set a default value
    }
  };

  const handleNewFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setNewFiber(newFiberValue);
    } else {
      setNewFiber(""); // Set a default value
    }
  };

  const handleNewSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setNewSugar(newSugarValue);
    } else {
      setNewSugar(""); // Set a default value
    }
  };
  const handleAddIngredient = () => {
    // Check that required fields have a value
    if (!newIngredientName) {
      alert("Please enter an ingredient name.");
      return;
    }
    if (!newUnitOfMeasurement) {
      alert("Please select a unit of measurement for the ingredient.");
      return;
    }
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-ingredient`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientName: newIngredientName.trim(),
        unitOfMeasurement: newUnitOfMeasurement,
        customUnitFlag: newCustomUnitFlag,
        shoppingAisleId: newShoppingAisle,
        protein: newProtein,
        carbs: newCarbs,
        fat: newFat,
        fiber: newFiber,
        sugar: newSugar,
        price: newCalculatedPrice,
        storePrice: newPrice,
        storeAmount: newStoreAmount,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Parse the JSON response
          return res.json();
        } else {
          throw new Error("Error adding new ingredient");
        }
      })
      .then((resultIngredient) => {
        // Set variables based on the ingredient data
        // Assuming ingredient contains properties such as id, name, unitOfMeasurement, etc.

        // Set your variables with the retrieved values
        // Example:
        console.log(resultIngredient[0]);
        setSelectedIngredient(resultIngredient[0]);
        setEditIngredientName(resultIngredient[0].ingredient_name);
        setEditUnitOfMeasurement(resultIngredient[0].unit_id);
        setEditUnitOfMeasurementId(resultIngredient[0].default_unit_id);
        setEditShoppingAisle(resultIngredient[0].shopping_aisle);
        setEditShoppingAisleId(resultIngredient[0].custom_shopping_aisle_id);
        setEditProtein(resultIngredient[0].protein);
        setEditCarbs(resultIngredient[0].carbs);
        setEditFat(resultIngredient[0].fat);
        setEditFiber(resultIngredient[0].fiber);
        setEditSugar(resultIngredient[0].sugar);
        setEditStoreAmount(resultIngredient[0].store_amount);
        setEditPrice(resultIngredient[0].store_price);
        setEditCalculatedPrice(resultIngredient[0].price);
        setEditCustomUnitFlag(resultIngredient[0].custom_unit);

        // Reload units data
        fetchIngredients();
        setNewIngredientName("");
        setNewUnitOfMeasurement("");
        SetNewUnitOfMeasurementLabel("");
        setNewShoppingAisle("");
        setNewProtein("");
        setNewCarbs("");
        setNewFat("");
        setNewFiber("");
        setNewSugar("");
        setNewStoreAmount("");
        setNewPrice("");
        setNewCalculatedPrice(0);
        setNewCustomUnitFlag(0);
        handleAddCloseModal();
      })
      .catch((err) => {
        console.error("Error adding new ingredient:", err);
      });
  };

  // variables for editing ingredient
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [editIngredientName, setEditIngredientName] = useState("");
  const [editUnitOfMeasurement, setEditUnitOfMeasurement] = useState("");
  const [editUnitOfMeasurementId, setEditUnitOfMeasurementId] = useState("");
  const [editShoppingAisle, setEditShoppingAisle] = useState("");
  const [editShoppingAisleId, setEditShoppingAisleId] = useState("");
  const [editProtein, setEditProtein] = useState("");
  const [editCarbs, setEditCarbs] = useState("");
  const [editFat, setEditFat] = useState("");
  const [editFiber, setEditFiber] = useState("");
  const [editSugar, setEditSugar] = useState("");
  const [editStoreAmount, setEditStoreAmount] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editCalculatedPrice, setEditCalculatedPrice] = useState(0);
  const [editCustomUnitFlag, setEditCustomUnitFlag] = useState(0);
  const [ingredientConversionList, setIngredientConversionList] = useState([]);
  const [addConversionInput, setAddConversionInput] = useState("");

  // Variables for Editing Conversions
  const [selectedConversionToEdit, setSelectedConversionToEdit] =
    useState(null);
  const [selectedConversionToEditId, setSelectedConversionToEditId] =
    useState(null);
  const [editConversionUnitOfMeasurement, setEditConversionUnitOfMeasurement] =
    useState("");
  const [
    editConversionUnitOfMeasurementId,
    setEditConversionUnitOfMeasurementId,
  ] = useState("");
  const [editConversionCustomUnitFlag, setEditConversionCustomUnitFlag] =
    useState(0);
  const [editConversionFactor, setEditConversionFactor] = useState("");

  // variable for unit options
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitOption, setSelectedUnitOption] = useState(null);

  // var for shopping options:
  const [shoppingOptions, setShoppingOptions] = useState([]);

  const handleEditConversion = (conversion) => {
    setSelectedConversionToEdit(conversion);
    setSelectedConversionToEditId(conversion.id);
    setEditConversionUnitOfMeasurement(conversion.unit_name);
    setEditConversionUnitOfMeasurementId(conversion.unit_id);
    setEditConversionCustomUnitFlag(conversion.custom_unit);
    setEditConversionFactor(conversion.conversion_factor);
    console.log(conversion);
  };

  const handleAddConversionInput = (newConverionValue) => {
    if (!isNaN(newConverionValue)) {
      setAddConversionInput(newConverionValue);
    } else {
      setAddConversionInput("");
    }
  };

  const handleEditConversionInput = (newConverionValue) => {
    if (!isNaN(newConverionValue)) {
      setEditConversionFactor(newConverionValue);
    } else {
      setEditConversionFactor("");
    }
  };

  // fetch available unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/units`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setUnitOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  // fetch shopping list unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_aisle`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setShoppingOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  // Funciton to Fetch Unit Converions of the Selected Ingredient
  const fetchIngredientConversions = async (id) => {
    // Determine custom_ingredient flag
    const customIngredientFlag = id.includes("user") ? 1 : 0;

    const ingredientId = id.split(",")[0];

    // Make API call to retrieve unit conversions
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/ingredient-conversions/${ingredientId}?custom_ingredient=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(response.statusText);
      return [];
    }
  };

  const handleSelectIngredient = async (ingredient) => {
    const old_ingredient_id = ingredient.old_id;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getIngredientToEdit/${old_ingredient_id}`,
        {
          credentials: "include",
        }
      );

      if (response.ok) {
        response.json().then((result) => {
          const editingIngredient = result[0];
          console.log(editingIngredient);

          setSelectedIngredient(editingIngredient);
          setEditIngredientName(editingIngredient.ingredient_name);
          setEditUnitOfMeasurement(editingIngredient.unit_id);
          setEditUnitOfMeasurementId(editingIngredient.default_unit_id);
          setEditShoppingAisle(editingIngredient.shopping_aisle);
          setEditShoppingAisleId(editingIngredient.custom_shopping_aisle_id);
          setEditProtein(editingIngredient.protein);
          setEditCarbs(editingIngredient.carbs);
          setEditFat(editingIngredient.fat);
          setEditFiber(editingIngredient.fiber);
          setEditSugar(editingIngredient.sugar);
          setEditStoreAmount(editingIngredient.store_amount);
          setEditPrice(editingIngredient.store_price);
          setEditCalculatedPrice(editingIngredient.price);
          setEditCustomUnitFlag(editingIngredient.custom_unit);

          fetchIngredientConversions(ingredient.old_id).then((data) => {
            setIngredientConversionList(data);
          });
        });
      } else {
        console.error("Error retrieving ingredient:", response.status);
        // Handle error case
      }
    } catch (error) {
      console.error("Error retrieving ingredient:", error);
      // Handle error case
    }
  };

  // Add Edit Unit Handle Form Changes
  const handleEditIngredientNameChange = (event) => {
    setEditIngredientName(event.target.value);
  };

  const handleEditStoreBaseAmountChange = (event) => {
    const value = event.target.value;
    setEditStoreAmount(value);
    setEditCalculatedPrice(editPrice / value);
  };

  const handleEditStorePriceAmountChange = (event) => {
    const value = event.target.value;
    setEditPrice(value);
    setEditCalculatedPrice(value / editStoreAmount);
  };

  // Edit Unit Macros:
  const handleEditProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setEditProtein(newProteinValue);
    } else {
      setEditProtein(""); // Set a default value
    }
  };

  const handleEditCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setEditCarbs(newCarbsValue);
    } else {
      setEditCarbs(""); // Set a default value
    }
  };

  const handleEditFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setEditFat(newFatValue);
    } else {
      setEditFat(""); // Set a default value
    }
  };

  const handleEditFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setEditFiber(newFiberValue);
    } else {
      setEditFiber(""); // Set a default value
    }
  };

  const handleEditSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setEditSugar(newSugarValue);
    } else {
      setEditSugar(""); // Set a default value
    }
  };

  const handleSaveIngredient = () => {
    if (selectedIngredient.id.includes("user")) {
      const ingredient_id = selectedIngredient.id.split(",")[0];
      // This is a user ingredient, send PUT request to backend
      fetch(
        `${process.env.REACT_APP_API_URL}/edit-ingredient?id=${ingredient_id}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: ingredient_id,
            ingredientName: editIngredientName.trim(),
            unitOfMeasurement: editUnitOfMeasurementId,
            customUnitFlag: editCustomUnitFlag,
            shoppingAisleId: editShoppingAisleId,
            protein: editProtein,
            carbs: editCarbs,
            fat: editFat,
            fiber: editFiber,
            sugar: editSugar,
            price: editCalculatedPrice,
            storePrice: editPrice,
            storeAmount: editStoreAmount,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            // Reload ingredients data
            fetchRecipeIngredients(id);
            fetchIngredients();
            setSelectedIngredient(null);
            setEditIngredientName("");
            setEditUnitOfMeasurement("");
            setEditUnitOfMeasurementId("");
            setEditShoppingAisle("");
            setEditShoppingAisleId("");
            setEditProtein("");
            setEditCarbs("");
            setEditFat("");
            setEditFiber("");
            setEditSugar("");
            setEditStoreAmount("");
            setEditPrice("");
            setEditCalculatedPrice(0);
            setEditCustomUnitFlag(0);
          } else {
            throw new Error("Error updating ingredient");
          }
        })
        .catch((err) => {
          console.error("Error updating ingredient:", err);
        });
    } else {
      const ingredient_id = selectedIngredient.id.split(",")[0];
      // This is not a user ingredient, query user_ingredient_customizations table
      const url = `${process.env.REACT_APP_API_URL}/user-ingredient-customization?ingredient_id=${ingredient_id}`;
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error querying user ingredient customization");
          }
        })
        .then((data) => {
          console.log(data);
          try {
            if (data.length === 0) {
              // No match, create new entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/user-ingredient-customization`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: ingredient_id,
                    shoppingAisleId: editShoppingAisleId,
                    price: editCalculatedPrice,
                    storePrice: editPrice,
                    storeAmount: editStoreAmount,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    fetchRecipeIngredients(id);
                    fetchIngredients();
                    setSelectedIngredient(null);
                    setEditIngredientName("");
                    setEditUnitOfMeasurement("");
                    setEditUnitOfMeasurementId("");
                    setEditShoppingAisle("");
                    setEditShoppingAisleId("");
                    setEditProtein("");
                    setEditCarbs("");
                    setEditFat("");
                    setEditFiber("");
                    setEditSugar("");
                    setEditStoreAmount("");
                    setEditPrice("");
                    setEditCalculatedPrice(0);
                    setEditCustomUnitFlag(0);
                  } else {
                    throw new Error(
                      "Error creating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error creating user ingredient customization:",
                    err
                  );
                });
            } else {
              // Match found, update existing entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/user-ingredient-customization/${data[0].ingredient_id}`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: ingredient_id,
                    shoppingAisleId: editShoppingAisleId,
                    price: editCalculatedPrice,
                    storePrice: editPrice,
                    storeAmount: editStoreAmount,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    fetchRecipeIngredients(id);
                    fetchIngredients();
                    setSelectedIngredient(null);
                    setEditIngredientName("");
                    setEditUnitOfMeasurement("");
                    setEditUnitOfMeasurementId("");
                    setEditShoppingAisle("");
                    setEditShoppingAisleId("");
                    setEditProtein("");
                    setEditCarbs("");
                    setEditFat("");
                    setEditFiber("");
                    setEditSugar("");
                    setEditStoreAmount("");
                    setEditPrice("");
                    setEditCalculatedPrice(0);
                    setEditCustomUnitFlag(0);
                  } else {
                    throw new Error(
                      "Error updating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error updating user ingredient customization:",
                    err
                  );
                });
            }
          } catch (err) {
            console.error("Error parsing JSON response:", err);
          }
        })
        .catch((err) => {
          console.error("Error querying user ingredient customization:", err);
        });
    }
  };

  const handleAddUnitConversion = () => {
    if (!addConversionInput) {
      alert("Please add a conversion qty.");
      return;
    }
    const conversionFactor = inverseConversion
      ? 1 / addConversionInput
      : addConversionInput;

    const customIngredientFlag = selectedIngredient.id.includes("user") ? 1 : 0;
    const ingredientId = selectedIngredient.id.split(",")[0];

    // Check for existing unit conversion
    fetch(
      `${process.env.REACT_APP_API_URL}/api/check-unit-conversion?ingredientId=${ingredientId}&newUnitOfMeasurement=${newUnitOfMeasurement}&newCustomUnitFlag=${newCustomUnitFlag}&customIngredientFlag=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          // No existing conversion found, continue with adding a new conversion
          return fetch(
            `${process.env.REACT_APP_API_URL}/api/add-unit-conversion`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customIngredientFlag: customIngredientFlag,
                ingredientId: ingredientId,
                newUnitOfMeasurement: newUnitOfMeasurement,
                newCustomUnitFlag: newCustomUnitFlag,
                addConversionInput: conversionFactor,
              }),
            }
          );
        } else {
          // Existing conversion found, display error message
          const error =
            "Duplicate Entry - You already have an existing conversion for this unit";
          throw new Error(error);
        }
      })
      .then((response) => response.json())
      .then((data) => {
        //Fetch the updated ingredient conversions after successful addition
        fetchIngredientConversions(selectedIngredient.id)
          .then((data) => setIngredientConversionList(data))
          .catch((error) => console.error(error));
        //Clear the addConversionInput field
        setAddConversionInput("");
        fetchRecipeIngredients(id);
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  };

  // Update Unit Conversion
  const handleUpdateUnitConversion = async () => {
    try {
      const conversionFactor = inverseConversion
        ? 1 / editConversionFactor
        : editConversionFactor;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/${selectedConversionToEditId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            unit_id: editConversionUnitOfMeasurementId,
            custom_unit: editConversionCustomUnitFlag,
            conversion_factor: conversionFactor,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Update the state with the new values
      setSelectedConversionToEdit({
        id: selectedConversionToEditId,
        unit_id: editConversionUnitOfMeasurementId,
        custom_unit: editConversionCustomUnitFlag,
        conversion_factor: editConversionFactor,
      });

      // Clear the form fields
      fetchIngredientConversions(selectedIngredient.id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
      setSelectedConversionToEdit(null);
      setEditConversionUnitOfMeasurement("");
      setEditConversionUnitOfMeasurementId("");
      setEditConversionCustomUnitFlag(0);
      setEditConversionFactor("");
      fetchRecipeIngredients(id);
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Delete Conversion
  const handleDeleteConversion = async (conversion) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the conversion "${conversion.unit_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/delete/${conversion.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Remove the deleted conversion from the ingredientConversionList state
      fetchIngredientConversions(selectedIngredient.id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
      fetchRecipeIngredients(id);
    } catch (error) {
      console.error(error);
    }
  };

  const isUserRecipeOwner = () => {
    if (recipeData && currentUser && recipeData.user_id === currentUser) {
      console.log(`Recipe Data: ${recipeData.user_id}`);
      console.log(`Current user: ${currentUser}`);
      setUserIsOwner(true);
      console.log(userIsOwner);
    }
  };

  useEffect(() => {
    // Fetch ingredients
    isUserRecipeOwner();
  }, [recipeData, currentUser]);

  useEffect(() => {
    // Fetch ingredients
    fetchIngredients();
    fetchRecipeInstructions(id);
    fetchRecipeIngredients(id);
    fetchUserMetricSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  if (!recipeData) {
    return <div>Only the user that created this recipe can edit it.</div>;
  }

  return (
    <div>
      <div className="chatbot-widget">
        {closeChat ? (
          <>
            <Button
              className="hide-button"
              variant="primary"
              onClick={toggleCloseChat}
            >
              Close
            </Button>
            <Button
              className="clear-chat-button"
              variant="danger"
              onClick={toggleClearChat}
            >
              Clear Chat
            </Button>
            <FullChatBot
              recipeIngredients={recipeIngredients}
              recipeData={recipeData}
              recipeInstructions={recipeInstructions}
            />
          </>
        ) : (
          <div className="chatbot-tab" onClick={toggleCloseChat}>
            Chat with Remy
          </div>
        )}
      </div>

      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        <div className="row align-items-center">
          <div className="col-sm-1"></div>
          <div className="col-sm-2">
            <Button onClick={handleBackClick}> Back </Button>
          </div>
          <div className="col-sm-2">
            <Form.Switch
              type="switch"
              id="custom-switch"
              label={"Use Metric"}
              checked={isMetric}
              onChange={handleToggle}
            />
          </div>
          <div className="col-sm-5"></div>
          <div className="col-sm-2">
            <Button variant="danger" onClick={() => handleDeleteRecipe(id)}>
              Delete Recipe
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 ">
          <h3>Update Recipe Info</h3>
          <div>
            <label className="mr-2">Recipe Name:</label>
            <input
              type="text"
              className="form-control mb-2"
              value={recipeName}
              onChange={handleRecipeNameChange}
            />
          </div>
          <div>
            <label className="mr-2">Course:</label>
            <Select
              options={courseOptions}
              isSearchable
              value={recipeCourse}
              onChange={handleRecipeCourseChange}
            />
          </div>
          <div>
            <label className="mr-2">Servings:</label>
            <input
              type="number"
              className="form-control mb-2"
              min="0"
              value={recipeServings}
              onChange={(e) =>
                handleRecipeServingsChange(parseFloat(e.target.value))
              }
            />
          </div>
          <div>
            <label className="mr-2">Serving Size:</label>
            <input
              type="text"
              className="form-control mb-2"
              value={recipeServingSize}
              onChange={handleRecipeServingSizeChange}
            />
          </div>
          <div>
            <label className="mr-2">Tags:</label>
            <input
              type="text"
              className="form-control mb-2"
              value={recipeTags}
              onChange={handleRecipeTagsChange}
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="form-control mb-2"
              value={recipeDescription}
              onChange={handleRecipeDescriptionChange}
            />
            <button
              className="btn btn-primary my-1"
              onClick={handleUpdateRecipe}
            >
              Save Updates
            </button>
          </div>
        </div>
        <div className="col-sm-4 ">
          <h3>Saved Info:</h3>
          <h5>Cost to Make:</h5>
          <div>
            <label className="mr-2">
              <b>Recipe Cost:</b> ${parseFloat(totalPrice).toFixed(2)}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Serving Cost:</b> $
              {parseFloat(totalPrice / recipeData.servings).toFixed(2)}
            </label>
          </div>
          <h5 className="mt-3">Macros:</h5>
          <div>
            <label className="mr-2">
              <b>Calories:</b>{" "}
              {parseFloat(totalCalories / recipeData.servings).toFixed(0)}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Protein:</b>{" "}
              {parseFloat(totalProtein / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Carbs:</b>{" "}
              {parseFloat(totalCarbs / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Fat:</b>{" "}
              {parseFloat(totalFat / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Fiber:</b>{" "}
              {parseFloat(totalFiber / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Sugar:</b>{" "}
              {parseFloat(totalSugar / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <h5 className="mt-3">Recipe Info:</h5>
          <div>
            <label className="mr-2">
              <b>Recipe Name:</b> {recipeData.name}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Course:</b> {recipeData.course}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Servings:</b> {recipeData.servings}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Serving Size:</b> {recipeData.serving_size}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Tags:</b> {recipeData.tags}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Description:</b> {recipeData.description}
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <h3>Image:</h3>
          {isLoading ? (
            <div className="chat-bubble mt-2 ms-2">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          ) : (
            <>
              {imageUrl ? (
                <img
                  className="recipe-image rounded"
                  src={imageUrl}
                  alt={recipeData.name}
                />
              ) : (
                <div>No image available</div>
              )}
            </>
          )}
          <div className="d-flex align-items-center">
            <div className="my-2">
              <label htmlFor="file-upload" className="custom-file-upload">
                Upload Image
              </label>
              <input
                type="file"
                className="file-upload"
                id="file-upload"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>

            <div className="ms-2">
              {hasGold ? (
                <button
                  className="btn btn-primary my-1"
                  onClick={handleImageUpGenerate}
                >
                  Generate Image
                </button>
              ) : (
                <button
                  className="btn btn-secondary my-1"
                  onClick={handleImageUpGenerate}
                >
                  Generate Image
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <h3>Recipe Notes:</h3>
        <div className="col-sm-6 text-center">
          <button
            className={`btn ${
              publicNotesContent !== null && publicNotesContent !== "<p></p>"
                ? "btn-success"
                : "btn-secondary"
            } my-2 me-2`}
            onClick={handlePublicNotesOpenModal}
          >
            General Recipe Notes
          </button>

          <button
            className={`btn ${
              notesContent !== null && notesContent !== "<p></p>"
                ? "btn-success"
                : "btn-secondary"
            } my-2`}
            onClick={handlePrivateNotesOpenModal}
          >
            Personal Recipe Notes
          </button>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-sm-6">
          <h3>Add Ingredient:</h3>
          <button
            className="btn btn-sm btn-secondary mt-1"
            onClick={handleAddClickModal}
          >
            Create New Ingredient
          </button>
          <div>
            <label className="mr-2">Ingredient:</label>
            <Select
              options={ingredients}
              isSearchable
              onChange={handleChange}
            />
            <div className="row mt-2">
              <div className="col-sm-4">
                <label className="mr-2">Qty:</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Qty"
                  min="0"
                  value={newQty}
                  onChange={(e) =>
                    handleNewQtyChange(parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="col-sm-1">
                <label className="mr-2"> </label>
                <p className="text-center fw-bold fs-5">X</p>
              </div>
              <div className="col-sm-7">
                <label className="mr-2">Unit:</label>
                <Select
                  options={newIngredientConversionsOptions.map((option) => ({
                    value: option.unit_id,
                    label: option.unit_name,
                    conversion_factor: option.conversion_factor,
                  }))}
                  value={{
                    value: newIngredientConversionsOptionsId,
                    label: newIngredientConversionsOptionsName,
                    conversion_factor: newIngredientConversionFactor,
                  }}
                  onChange={(selectedOption) => {
                    setNewIngredientConversionsOptionsId(selectedOption.value);
                    setNewIngredientConversionsOptionsName(
                      selectedOption.label
                    );
                    setNewIngredientConversionFactor(
                      selectedOption.conversion_factor
                    );
                  }}
                  isSearchable={true}
                  isDisabled={false}
                />{" "}
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <label className="mr-2">Unit Suffix:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter suffix (eg. 'At room temp', 'minced', 'etc')"
                    value={newSuffix}
                    onChange={handleNewSuffixChange}
                  />
                </div>
                <div className="col-sm-3">
                  <label className="mr-2">
                    Calorie %
                    <OverlayTrigger
                      delay={{ hide: 150, show: 150 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          Adjust what % of the ingredient's calories are
                          calculated in the total. Useful for items like
                          marinades, frying oil, breaded dishes, etc., where not
                          all calories are consumed.
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <FontAwesomeIcon
                        className="mx-2"
                        icon={faQuestionCircle}
                      />
                    </OverlayTrigger>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="100%"
                    min="0"
                    value={newCalorie}
                    onChange={(e) =>
                      handleNewCalorieChange(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary my-2"
              onClick={handleAddIngredientToRecipe}
            >
              Add Ingredient To Recipe
            </button>
            <div>
              <h2>Recipe Ingredients</h2>

              <div className="table-responsive" style={{ overflow: "visible" }}>
                <table className="table">
                  {recipeIngredients.length > 0 && (
                    <thead className="text-center">
                      <tr>
                        <th></th>
                        <th>
                          {" "}
                          <OverlayTrigger
                            delay={{ hide: 150, show: 150 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                Click an ingredient to edit the ingredient's
                                settings (calories, conversions, etc)
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <FontAwesomeIcon
                              className=""
                              icon={faQuestionCircle}
                            />
                          </OverlayTrigger>
                        </th>
                        <th className="align-text-bottom">
                          <OverlayTrigger
                            delay={{ hide: 150, show: 150 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                Use colors to make it easier to read recipes or
                                align colors with relevant instruction steps.
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <FontAwesomeIcon
                              className=""
                              icon={faQuestionCircle}
                            />
                          </OverlayTrigger>
                        </th>
                        <th className="align-text-bottom">
                          <OverlayTrigger
                            delay={{ hide: 150, show: 150 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                Don't like how a unit is being displayed? Set it
                                yourself using the below dropdown and it will
                                automatically be converted and saved.
                              </Tooltip>
                            )}
                            placement="bottom"
                          >
                            <FontAwesomeIcon
                              className=""
                              icon={faQuestionCircle}
                            />
                          </OverlayTrigger>
                        </th>
                        <th><OverlayTrigger
                      delay={{ hide: 150, show: 150 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          A black "Edit Entry" button means the calorie % has been edited and is not set to 100%.
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <FontAwesomeIcon
                        className="mx-2"
                        icon={faQuestionCircle}
                      />
                    </OverlayTrigger></th>
                        <th></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {recipeIngredients.map((ingredient, index) => (
                      <tr key={ingredient.id}>
                        <td className="text-center">
                          <div
                            onClick={() =>
                              handleUpArrowClick(
                                index,
                                ingredient.id,
                                ingredient.sort_order
                              )
                            }
                          >
                            ↑
                          </div>{" "}
                          <div
                            onClick={() =>
                              handleDownArrowClick(
                                index,
                                ingredient.id,
                                ingredient.sort_order
                              )
                            }
                          >
                            ↓
                          </div>
                        </td>
                        <td
                          className="align-middle"
                          style={{ backgroundColor: ingredient.color_tag }}
                        >
                          <b>
                            {(() => {
                              const result = parseFloat(ingredient.qty);
                              let displayValue;

                              if (isMetric && !Number.isInteger(result)) {
                                displayValue = result.toFixed(2);
                              } else if (!isMetric) {
                                if (
                                  (result % 1).toFixed(2) >= 0.31 &&
                                  (result % 1).toFixed(2) <= 0.35
                                ) {
                                  displayValue = new Fraction(
                                    Math.round(3 * result)
                                  )
                                    .div(3)
                                    .toFraction(true);
                                } else if (
                                  (result % 1).toFixed(2) >= 0.65 &&
                                  (result % 1).toFixed(2) <= 0.68
                                ) {
                                  displayValue = new Fraction(
                                    Math.round(3 * result)
                                  )
                                    .div(3)
                                    .toFraction(true);
                                } else {
                                  displayValue = new Fraction(
                                    Math.round(8 * result)
                                  )
                                    .div(8)
                                    .toFraction(true);
                                }
                              } else {
                                displayValue = result;
                              }

                              return <b>{displayValue}</b>;
                            })()}
                          </b>{" "}
                          {ingredient.unit_name}
                          <button
                            className="EditIngredientButton"
                            onClick={() => handleSelectIngredient(ingredient)}
                          >
                            {ingredient.name}
                          </button>{" "}
                          {ingredient.suffix}{" "}
                        </td>

                        <td className="align-middle">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary dropdown-toggle-2"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Color
                            </button>
                            <div className="dropdown-menu">
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(ingredient.id, "")
                                }
                              >
                                <span className="color-swatch"></span>
                                <span>None</span>
                              </button>

                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#98F5E1"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-1"></span>
                                <span>Aqua Blue</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#FBF8CC"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-2"></span>
                                <span>Pale Yellow</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#FDE4CF"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-3"></span>
                                <span>Peach</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#90DBF4"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-4"></span>
                                <span>Sky Blue</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#F1C0E8"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-5"></span>
                                <span>Pink Lavender</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#8EECF5"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-6"></span>
                                <span>Baby Blue</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#CFBAF0"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-7"></span>
                                <span>Mauve</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#B9FBC0"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-8"></span>
                                <span>Light Green</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#FFCFD2"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-9"></span>
                                <span>Light Pink</span>
                              </button>
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  updateIngredientColor(
                                    ingredient.id,
                                    "#A3C4F3"
                                  )
                                }
                              >
                                <span className="color-swatch swatch-10"></span>
                                <span>Powder Blue</span>
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          <div className="btn-group">
                            <button
                              type="button"
                              className={`btn btn-sm ${
                                parseInt(ingredient.override) !== 0
                                  ? "btn-success"
                                  : "btn-secondary"
                              } dropdown-toggle-2`}
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Set Unit
                            </button>
                            <div className="dropdown-menu">
                              <button
                                type="button"
                                className="dropdown-item"
                                key={index}
                                onClick={() =>
                                  handleSetUnitOptionDefault(ingredient.id)
                                }
                              >
                                <span>Default</span>
                              </button>
                              {ingredient.conversion_options.map(
                                (option, index) => (
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    key={index}
                                    onClick={() =>
                                      handleSetUnitOption(
                                        ingredient.id,
                                        option.id
                                      )
                                    }
                                  >
                                    <span>{option.unit_name}</span>
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
  <Button
    variant={ingredient.ingredient_calorie_percent !== 100 ? "dark" : "primary"}
    size="sm"
    onClick={() => handleEditIngredient(ingredient)}
  >
    Edit Entry
  </Button>
</td>

                        <td className="align-middle">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteIngredient(ingredient)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <Modal
                  show={selectedRecipeIngredient !== null}
                  onHide={() => setSelectedRecipeIngredient(null)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Recipe Ingredient</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <label className="mr-2">Ingredient:</label>
                      <Select
                        options={ingredients}
                        value={{
                          value: selectedRecipeIngredientId,
                          label: editRecipeIngredientName,
                        }}
                        onChange={handleEditChange}
                        isSearchable={true}
                        isDisabled={false}
                        onLoad={handleEditChange}
                      />
                      <div className="row mt-2">
                        <div className="col-sm-4">
                          <label className="mr-2">Qty:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Qty"
                            min="0"
                            value={editIngredientQty}
                            onChange={(e) =>
                              handleEditQtyChange(parseFloat(e.target.value))
                            }
                          />
                        </div>
                        <div className="col-sm-1">
                          <label className="mr-2"> </label>
                          <p className="text-center fw-bold fs-5">X</p>
                        </div>
                        <div className="col-sm-7">
                          <label className="mr-2">Unit:</label>
                          <Select
                            options={editIngredientConversionsOptions.map(
                              (option) => ({
                                value: option.unit_id,
                                label: option.unit_name,
                                conversion_factor: option.conversion_factor,
                              })
                            )}
                            value={{
                              value: editIngredientConversionsOptionsId,
                              label: editIngredientConversionsOptionsName,
                              conversion_factor: editIngredientConversionFactor,
                            }}
                            onChange={(selectedOption) => {
                              setEditIngredientConversionsOptionsId(
                                selectedOption.value
                              );
                              setEditIngredientConversionsOptionsName(
                                selectedOption.label
                              );
                              setEditIngredientConversionFactor(
                                selectedOption.conversion_factor
                              );
                            }}
                            isSearchable={true}
                            isDisabled={false}
                          />{" "}
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <label className="mr-2">Unit Suffix:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter suffix (eg. 'At room temp', 'minced', 'etc')"
                              value={editIngredientSuffix}
                              onChange={handleEditSuffixChange}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label className="mr-2">
                              Calorie %
                              <OverlayTrigger
                                delay={{ hide: 150, show: 150 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    Adjust what % of the ingredient's calories
                                    are calculated in the total. Useful for
                                    items like marinades, frying oil, breaded
                                    dishes, etc., where not all calories are
                                    consumed.
                                  </Tooltip>
                                )}
                                placement="bottom"
                              >
                                <FontAwesomeIcon
                                  className="mx-2"
                                  icon={faQuestionCircle}
                                />
                              </OverlayTrigger>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="100%"
                              min="0"
                              value={editIngredientCalorie}
                              onChange={(e) =>
                                handleEditCalorieChange(
                                  parseFloat(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={handleUpdateRecipeIngredient}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <h3>Add Instruction Step:</h3>
          <label>Instruction Step:</label>
          <textarea
            className="form-control mb-2"
            value={addInstruction}
            onChange={handleInstructionChange}
            rows="6"
          />
          <button
            className="btn btn-primary my-1"
            onClick={handleAddInstructionToRecipe}
          >
            Add Instruction Step
          </button>
          <div>
            <h3>Recipe Instructions</h3>
            <div className="table-responsive" style={{ overflow: "visible" }}>
              <table className="table">
                <tbody>
                  {recipeInstructions.map((instruction, index) => (
                    <tr key={instruction.id}>
                      <td className="text-center">
                        <div
                          onClick={() =>
                            handleInstructionUpArrowClick(
                              index,
                              instruction.id,
                              instruction.sort_order
                            )
                          }
                        >
                          ↑
                        </div>{" "}
                        <div
                          onClick={() =>
                            handleInstructionDownArrowClick(
                              index,
                              instruction.id,
                              instruction.sort_order
                            )
                          }
                        >
                          ↓
                        </div>
                      </td>
                      <td
                        className="align-middle"
                        style={{ backgroundColor: instruction.color_tag }}
                      >
                        {instruction.instruction}{" "}
                      </td>
                      <td className="align-middle">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary dropdown-toggle-2"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Color
                          </button>
                          <div className="dropdown-menu">
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(instruction.id, "")
                              }
                            >
                              <span className="color-swatch"></span>
                              <span>None</span>
                            </button>

                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#98F5E1"
                                )
                              }
                            >
                              <span className="color-swatch swatch-1"></span>
                              <span>Aqua Blue</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#FBF8CC"
                                )
                              }
                            >
                              <span className="color-swatch swatch-2"></span>
                              <span>Pale Yellow</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#FDE4CF"
                                )
                              }
                            >
                              <span className="color-swatch swatch-3"></span>
                              <span>Peach</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#90DBF4"
                                )
                              }
                            >
                              <span className="color-swatch swatch-4"></span>
                              <span>Sky Blue</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#F1C0E8"
                                )
                              }
                            >
                              <span className="color-swatch swatch-5"></span>
                              <span>Pink Lavender</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#8EECF5"
                                )
                              }
                            >
                              <span className="color-swatch swatch-6"></span>
                              <span>Baby Blue</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#CFBAF0"
                                )
                              }
                            >
                              <span className="color-swatch swatch-7"></span>
                              <span>Mauve</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#B9FBC0"
                                )
                              }
                            >
                              <span className="color-swatch swatch-8"></span>
                              <span>Light Green</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#FFCFD2"
                                )
                              }
                            >
                              <span className="color-swatch swatch-9"></span>
                              <span>Light Pink</span>
                            </button>
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                updateInstructionColor(
                                  instruction.id,
                                  "#A3C4F3"
                                )
                              }
                            >
                              <span className="color-swatch swatch-10"></span>
                              <span>Powder Blue</span>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleEditInstruction(instruction)}
                        >
                          Edit
                        </Button>
                      </td>
                      <td className="align-middle">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteInstruction(instruction)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              show={selectedInstruction !== null}
              onHide={() => setSelectedInstruction(null)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Instruction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {" "}
                  <textarea
                    className="form-control mb-2"
                    value={editInstructionStep}
                    onChange={handleEditInstructionChange}
                    rows="6"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={handleUpdateRecipeInstruction}
                >
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -------------------- */}
            {/* Add Ingredient Modal */}
            {/* -------------------- */}
            <Modal show={showNewModal} onHide={handleAddCloseModal} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Add Ingredient</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="mb-2 col-sm">
                    <label htmlFor="add-ingredient-name" className="form-label">
                      Ingredient Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter new ingredient name"
                      value={newIngredientName}
                      onChange={handleNewIngredientNameChange}
                    />
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm">
                      <label htmlFor="add-base-unit" className="form-label">
                        Base Unit of Measurement
                      </label>
                      <Select
                        options={unitOptions}
                        value={unitOptions.find(
                          (unit) => unit.unit_id === newUnitOfMeasurement
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption.unit_id.startsWith("D")) {
                            setNewUnitOfMeasurement(
                              parseInt(selectedOption.unit_id.substring(1))
                            );
                            SetNewUnitOfMeasurementLabel(
                              selectedOption.unit_name
                            );
                            setNewCustomUnitFlag(0);
                          } else {
                            setNewUnitOfMeasurement(selectedOption.unit_id);
                            SetNewUnitOfMeasurementLabel(
                              selectedOption.unit_name
                            );
                            setNewCustomUnitFlag(1);
                          }
                        }}
                        getOptionLabel={(option) => option.unit_name}
                        getOptionValue={(option) => option.unit_id}
                        isSearchable={true}
                      />
                    </div>

                    <div className="mb-3 col-sm">
                      <label
                        htmlFor="add-shopping-aisle"
                        className="form-label"
                      >
                        Shopping Aisle
                      </label>
                      <Select
                        options={shoppingOptions}
                        value={shoppingOptions.find(
                          (aisle) => aisle.id === newShoppingAisle
                        )}
                        onChange={(selectedOption) => {
                          setNewShoppingAisle(selectedOption.id);
                        }}
                        getOptionLabel={(option) => option.aisle_name}
                        getOptionValue={(option) => option.id}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="add-new-protein" className="form-label">
                        Protein (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="add-new-protein"
                        min="0"
                        value={newProtein}
                        onChange={(e) =>
                          handleNewProteinChange(parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="add-new-carbs" className="form-label">
                        Carbs (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="add-new-carbs"
                        min="0"
                        value={newCarbs}
                        onChange={(e) =>
                          handleNewCarbsChange(parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="add-new-fat" className="form-label">
                        Fat (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="add-new-fat"
                        min="0"
                        value={newFat}
                        onChange={(e) =>
                          handleNewFatChange(parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="add-new-fiber" className="form-label">
                        Fiber (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="add-new-fiber"
                        min="0"
                        value={newFiber}
                        onChange={(e) =>
                          handleNewFiberChange(parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="add-new-sugar" className="form-label">
                        Sugar (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="add-new-sugar"
                        min="0"
                        value={newSugar}
                        onChange={(e) =>
                          handleNewSugarChange(parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <h4>Set Price:</h4>
                    <div className="row">
                      <div className="mb-3 col-sm-6">
                        <label
                          htmlFor="add-store-amount"
                          className="form-label"
                        >
                          How many <b>{newUnitOfMeasurementLabel}</b> per store
                          container?
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter amount"
                          min="0"
                          value={newStoreAmount}
                          onChange={handleNewStoreBaseAmountChange}
                        />
                      </div>
                      <div className="mb-3 col-sm-6">
                        <label htmlFor="add-store-price" className="form-label">
                          Price for store container?
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter"
                          min="0"
                          value={newPrice}
                          onChange={handleNewStorePriceAmountChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleAddCloseModal}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleAddIngredient}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* --------------------- */}
            {/* Edit Ingredient Modal */}
            {/* --------------------- */}
            <Modal
              show={selectedIngredient !== null}
              onHide={() => setSelectedIngredient(null)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Ingredient</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="mb-2 col-sm">
                    <label
                      htmlFor="edit-ingredient-name"
                      className="form-label"
                    >
                      Ingredient Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={editIngredientName}
                      onChange={handleEditIngredientNameChange}
                      disabled={
                        !selectedIngredient ||
                        !selectedIngredient.id.includes("user")
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm">
                      <label htmlFor="edit-base-unit" className="form-label">
                        Base Unit of Measurement
                      </label>
                      <Select
                        options={unitOptions}
                        value={{
                          unit_id: editUnitOfMeasurementId,
                          unit_name: editUnitOfMeasurement,
                        }}
                        onChange={(selectedOption) => {
                          if (selectedOption.unit_id.startsWith("D")) {
                            setEditUnitOfMeasurementId(
                              parseInt(selectedOption.unit_id.substring(1))
                            );
                            setEditUnitOfMeasurement(selectedOption.unit_name);
                            setEditCustomUnitFlag(0);
                          } else {
                            setEditUnitOfMeasurementId(selectedOption.unit_id);
                            setEditUnitOfMeasurement(selectedOption.unit_name);
                            setEditCustomUnitFlag(1);
                          }
                        }}
                        getOptionLabel={(option) => option.unit_name}
                        getOptionValue={(option) => option.unit_id}
                        isSearchable={true}
                        isDisabled={true}
                      />
                    </div>

                    <div className="mb-3 col-sm">
                      <label
                        htmlFor="edit-shopping-aisle"
                        className="form-label"
                      >
                        Shopping Aisle
                      </label>
                      <Select
                        options={shoppingOptions}
                        value={{
                          aisle_name: editShoppingAisle,
                          id: editShoppingAisleId,
                        }}
                        onChange={(selectedOption) => {
                          setEditShoppingAisleId(selectedOption.id);
                          setEditShoppingAisle(selectedOption.aisle_name);
                        }}
                        getOptionLabel={(option) => option.aisle_name}
                        getOptionValue={(option) => option.id}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="edit-new-protein" className="form-label">
                        Protein (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="edit-new-protein"
                        min="0"
                        value={editProtein !== null ? editProtein : ""}
                        onChange={(e) =>
                          handleEditProteinChange(parseFloat(e.target.value))
                        }
                        disabled={
                          !selectedIngredient ||
                          !selectedIngredient.id.includes("user")
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="edit-new-carbs" className="form-label">
                        Carbs (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="edit-new-carbs"
                        min="0"
                        value={editCarbs !== null ? editCarbs : ""}
                        onChange={(e) =>
                          handleEditCarbsChange(parseFloat(e.target.value))
                        }
                        disabled={
                          !selectedIngredient ||
                          !selectedIngredient.id.includes("user")
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="edit-new-fat" className="form-label">
                        Fat (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="edit-new-fat"
                        min="0"
                        value={editFat !== null ? editFat : ""}
                        onChange={(e) =>
                          handleEditFatChange(parseFloat(e.target.value))
                        }
                        disabled={
                          !selectedIngredient ||
                          !selectedIngredient.id.includes("user")
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="edit-new-fiber" className="form-label">
                        Fiber (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="edit-new-fiber"
                        min="0"
                        value={editFiber !== null ? editFiber : ""}
                        onChange={(e) =>
                          handleEditFiberChange(parseFloat(e.target.value))
                        }
                        disabled={
                          !selectedIngredient ||
                          !selectedIngredient.id.includes("user")
                        }
                      />
                    </div>
                    <div className="mb-3 col-sm-1-5">
                      <label htmlFor="edit-new-sugar" className="form-label">
                        Sugar (g)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="edit-new-sugar"
                        min="0"
                        value={editSugar !== null ? editSugar : ""}
                        onChange={(e) =>
                          handleEditSugarChange(parseFloat(e.target.value))
                        }
                        disabled={
                          !selectedIngredient ||
                          !selectedIngredient.id.includes("user")
                        }
                      />
                    </div>
                    <h4>Set Price:</h4>
                    <div className="row">
                      <div className="mb-3 col-sm-6">
                        <label
                          htmlFor="edit-store-amount"
                          className="form-label"
                        >
                          How many <b>{editUnitOfMeasurement}</b> per store
                          container?
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter amount"
                          min="0"
                          value={
                            editStoreAmount !== null ? editStoreAmount : ""
                          }
                          onChange={handleEditStoreBaseAmountChange}
                        />
                      </div>
                      <div className="mb-3 col-sm-6">
                        <label
                          htmlFor="edit-store-price"
                          className="form-label"
                        >
                          Price for store container?
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter"
                          min="0"
                          value={editPrice !== null ? editPrice : ""}
                          onChange={handleEditStorePriceAmountChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <Button
                  variant="secondary"
                  onClick={() => setSelectedIngredient(null)}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSaveIngredient}>
                  Save Changes
                </Button>
                <hr />
                <h4>Edit Unit Conversions</h4>
                <div className="row">
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Switch
                      type="switch"
                      id="custom-switch"
                      label={"Inverse Conversion"}
                      checked={inverseConversion}
                      onChange={handleToggleConversion}
                    />{" "}
                    <OverlayTrigger
                      delay={{ hide: 150, show: 150 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          Toggle this to change the conversion statement below
                          to make inputs easier. Eg, swithes "How many
                          Serving(s) in 1x Cup(s)" to "How many Cup(s) in 1x
                          Serving(s)".
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <FontAwesomeIcon
                        className="mx-2"
                        icon={faQuestionCircle}
                      />
                    </OverlayTrigger>{" "}
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-4">
                      <label htmlFor="add-base-unit" className="form-label">
                        Add unit to convert to:
                      </label>
                      <Select
                        options={unitOptions}
                        value={unitOptions.find(
                          (unit) => unit.unit_id === newUnitOfMeasurement
                        )}
                        onChange={(selectedOption) => {
                          if (selectedOption.unit_id.startsWith("D")) {
                            setNewUnitOfMeasurement(
                              parseInt(selectedOption.unit_id.substring(1))
                            );
                            setNewCustomUnitFlag(0);
                            setSelectedUnitOption(selectedOption.unit_name);
                          } else {
                            setNewUnitOfMeasurement(selectedOption.unit_id);
                            setNewCustomUnitFlag(1);
                            setSelectedUnitOption(selectedOption.unit_name);
                          }
                        }}
                        getOptionLabel={(option) => option.unit_name}
                        getOptionValue={(option) => option.unit_id}
                        isSearchable={true}
                      />
                    </div>

                    <div className="mb-3 col-sm-5">
                      {inverseConversion ? (
                        <label
                          htmlFor="add-conversion-input"
                          className="form-label"
                        >
                          {/* Content when inverseConversion is true */}
                          How many <b>{selectedUnitOption}</b> in 1x{" "}
                          <b>{editUnitOfMeasurement}</b>
                        </label>
                      ) : (
                        <label
                          htmlFor="add-conversion-input"
                          className="form-label"
                        >
                          {/* Content when inverseConversion is false */}
                          How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                          <b>{selectedUnitOption}</b>
                        </label>
                      )}
                      <input
                        type="number"
                        className="form-control"
                        id="add-conversion-input"
                        min="0"
                        value={addConversionInput}
                        onChange={(e) =>
                          handleAddConversionInput(parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-3 col-sm-2 d-flex align-items-end">
                    <Button variant="primary" onClick={handleAddUnitConversion}>
                      Add
                    </Button>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Unit Conversion</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingredientConversionList.map((conversion) => (
                        <tr key={conversion.id}>
                          <td>{`${conversion.unit_name} (${conversion.conversion_factor})`}</td>
                          <td>
                            {" "}
                            {conversion.user_id !== 1 ? (
                              <Button
                                variant="primary"
                                onClick={() => handleEditConversion(conversion)}
                              >
                                Edit
                              </Button>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {" "}
                            {conversion.user_id !== 1 ? (
                              <Button
                                variant="danger"
                                onClick={() =>
                                  handleDeleteConversion(conversion)
                                }
                              >
                                Delete
                              </Button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
            {/* --------------------- */}
            {/* Edit Conversion Modal */}
            {/* --------------------- */}
            <Modal
              show={selectedConversionToEdit !== null}
              onHide={() => setSelectedConversionToEdit(null)}
              centered
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Conversion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Switch
                      type="switch"
                      id="custom-switch"
                      label={"Inverse Conversion"}
                      checked={inverseConversion}
                      onChange={handleToggleConversion}
                    />{" "}
                    <OverlayTrigger
                      delay={{ hide: 150, show: 150 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          Toggle this to change the conversion statement below
                          to make inputs easier. Eg, swithes "How many
                          Serving(s) in 1x Cup(s)" to "How many Cup(s) in 1x
                          Serving(s)".
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <FontAwesomeIcon
                        className="mx-2"
                        icon={faQuestionCircle}
                      />
                    </OverlayTrigger>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col">
                    <label htmlFor="add-base-unit" className="form-label">
                      Edit unit to convert to:
                    </label>
                    <Select
                      options={unitOptions}
                      value={{
                        unit_id: editConversionUnitOfMeasurementId,
                        unit_name: editConversionUnitOfMeasurement,
                      }}
                      onChange={(selectedOption) => {
                        if (selectedOption.unit_id.startsWith("D")) {
                          setEditConversionUnitOfMeasurementId(
                            parseInt(selectedOption.unit_id.substring(1))
                          );
                          setEditConversionUnitOfMeasurement(
                            selectedOption.unit_name
                          );
                          setEditConversionCustomUnitFlag(0);
                        } else {
                          setEditConversionUnitOfMeasurementId(
                            selectedOption.unit_id
                          );
                          setEditConversionUnitOfMeasurement(
                            selectedOption.unit_name
                          );
                          setEditConversionCustomUnitFlag(1);
                        }
                      }}
                      getOptionLabel={(option) => option.unit_name}
                      getOptionValue={(option) => option.unit_id}
                      isSearchable={true}
                    />
                  </div>

                  <div className="mb-3 col">
                    {inverseConversion ? (
                      <label
                        htmlFor="add-conversion-input"
                        className="form-label"
                      >
                        {/* Content when inverseConversion is true */}
                        How many <b>{editConversionUnitOfMeasurement}</b> in 1x{" "}
                        <b>{editUnitOfMeasurement}</b>
                      </label>
                    ) : (
                      <label
                        htmlFor="add-conversion-input"
                        className="form-label"
                      >
                        {/* Content when inverseConversion is false */}
                        How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                        <b>{editConversionUnitOfMeasurement}</b>
                      </label>
                    )}
                    <input
                      type="number"
                      className="form-control"
                      id="add-conversion-input"
                      min="0"
                      value={editConversionFactor}
                      onChange={(e) =>
                        handleEditConversionInput(parseFloat(e.target.value))
                      }
                    />
                  </div>
                </div>

                <div className="mb-3 col-sm-2 d-flex align-items-end">
                  <Button
                    variant="primary"
                    onClick={handleUpdateUnitConversion}
                  >
                    Update
                  </Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setSelectedConversionToEdit(null)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -------------------- */}
            {/* Edit Private Notes*/}
            {/* -------------------- */}
            <Modal
              show={showPrivateNotesModal}
              onHide={handlePrivateNotesCloseModal}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Private Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tiptap
                  initialContent={notesContent}
                  onUpdateContent={updateNotesContent}
                  editingStatus={userIsOwner}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handlePrivateNotesCloseModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handlePrivatelSaveNotes}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -------------------- */}
            {/* Edit Public Notes*/}
            {/* -------------------- */}
            <Modal
              show={showPublicNotesModal}
              onHide={handlePublicNotesCloseModal}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Public Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tiptap
                  initialContent={publicNotesContent}
                  onUpdateContent={updatePublicNotesContent}
                  editingStatus={userIsOwner}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handlePublicNotesCloseModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handlePublicSaveNotes}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRecipe;
