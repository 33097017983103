import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";

function NonCookingItems() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);
  // variables for adding new ingredient:
  const [newShoppingUnit, setNewShoppingUnit] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [shoppingOptions, setShoppingOptions] = useState([]);
  const [newShoppingAisle, setNewShoppingAisle] = useState("");

  // variables for editing ingredient
  const [editShoppingAisle, setEditShoppingAisle] = useState("");
  const [editShoppingAisleId, setEditShoppingAisleId] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [editShoppingUnit, setEditShoppingUnit] = useState("");
  const [editIngredientName, setEditIngredientName] = useState("");
  const [editPrice, setEditPrice] = useState("");
  // variables for table settings (search, sort, paging)
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortedColumn, setSortedColumn] = useState("item_name");
  const [sortDirection, setSortDirection] = useState("asc");

  const [items, setItems] = useState([]);

  const handleDeleteIngredient = async (item) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the ingredient "${item.item_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/delete-non-cooking-item/${item.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      fetchNonShoppingItems();
    } catch (error) {
      console.error(error);
    }
  };

  // fetch shopping list unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_aisle`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setShoppingOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchNonShoppingItems();
  }, []);


  const handleShoppingUnitChange = (event) => {
    setNewShoppingUnit(event.target.value);
  };

  const handleNewItemNameChange = (event) => {
    setNewItemName(event.target.value);
  };

  const handlePriceChange = (price) => {
    setNewPrice(price);
  };

  const handleEditPriceChange = (price) => {
    setEditPrice(price);
  };
  // Active Table Setup
  //
  // Set up filter
  const fileterdItem = items.filter((item) =>
    item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Set up pagnation
  const pagesCount = Math.ceil(fileterdItem.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  // set up sorting
  const sortedUnits = fileterdItem.sort((a, b) => {
    try {
      if (sortDirection === "asc") {
        return a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      } else {
        return a[sortedColumn].toLowerCase() < b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      }
    } catch {}
  });

  // handle sorting
  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  // handle page paging
  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  // handle page paging
  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  // Fetch Ingredients for Chart
  const fetchNonShoppingItems = () => {
    fetch(`${process.env.REACT_APP_API_URL}/non-shopping-items`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setItems(data);
      })
      .catch((err) => {
        console.error("Error retrieving user items:", err);
      });
  };

  const handleAddItem = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-non-cooking-item`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item_name: newItemName.trim(),
        shopping_unit: newShoppingUnit,
        price: newPrice,
        shopping_aisle_id: newShoppingAisle,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchNonShoppingItems();
          setNewItemName("");
          setNewShoppingUnit("");
          setNewPrice("");
          setNewShoppingAisle("");
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleSelectItem = async (item) => {
    setSelectedItem(item);
    setEditIngredientName(item.item_name);

    setEditShoppingUnit(item.shopping_unit)

    setEditShoppingAisle(item.aisle_name);
    setEditShoppingAisleId(item.shopping_aisle_id);

    setEditPrice(item.price)

  };

  // Add Edit Unit Handle Form Changes
  const handleEditIngredientNameChange = (event) => {
    setEditIngredientName(event.target.value);
  };

  
  // Add Edit Unit Handle Form Changes
  const handleEditUnitShoppingNameChange = (event) => {
    setEditShoppingUnit(event.target.value);
  };

  const handleSaveIngredient = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/update-non-cooking-item`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedItem.id,
        itemName: editIngredientName.trim(),
        unit: editShoppingUnit,
        price: editPrice,
        shoppingAisle: editShoppingAisleId
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          setSelectedItem(null)
          fetchNonShoppingItems();
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchNonShoppingItems();
  }, []);

  if (!isLoggedIn) {
    return <h3>Please <a className="btn btn-primary" href={`/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
    Sign In
  </a> to view</h3>;
  }

  return (
    <div>
      <div>
        <h2>Non-Cooking Items</h2>
        <p>
          {" "}
          Items here can be added to your shopping list, but will not appear in
          selection when creating recipes.
        </p>
        <div className="mb-3 col-sm-4">
          {" "}
          <label>Item:</label>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Enter item name"
            value={newItemName}
            onChange={handleNewItemNameChange}
          />
          <label>Unit Size:</label>
          <input
            type="text"
            className="form-control mb-2 w-50"
            placeholder="Unit Size (eg. container)"
            value={newShoppingUnit}
            onChange={handleShoppingUnitChange}
          />
          <label>Price:</label>
          <input
            type="number"
            className="form-control mb-2 w-50"
            placeholder="Enter price"
            value={newPrice}
            onChange={(e) => handlePriceChange(parseFloat(e.target.value))}
          />
          <label htmlFor="add-shopping-aisle" className="form-label">
            Shopping Aisle:
          </label>
          <Select
            className="mb-2"
            options={shoppingOptions}
            value={shoppingOptions.find(
              (aisle) => aisle.id === newShoppingAisle
            )}
            onChange={(selectedOption) => {
              setNewShoppingAisle(selectedOption.id);
            }}
            getOptionLabel={(option) => option.aisle_name}
            getOptionValue={(option) => option.id}
            isSearchable={true}
          />
          <button className="btn btn-secondary" onClick={handleAddItem}>
            Add Item
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Non-Cooking Items</h2>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4 ">
              <label htmlFor="page-size-select" className="mr-2">
                Show:
              </label>{" "}
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {pageSize}
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(25)}
                  >
                    25
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(50)}
                  >
                    50
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search units"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: pagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          currentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(currentPage - halfMaxPagesToShow, 0),
                pagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, pagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== pagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${currentPage === i && "active"}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          currentPage === pagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={handleNextPage}
          disabled={currentPage === pagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>

          </div>
          <div className="table-responsive">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("item_name")}
                  >
                    Item{" "}
                    {sortedColumn === "item_name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "item_name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("shopping_unit")}
                  >
                    Unit Size{" "}
                    {sortedColumn === "shopping_unit" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "shopping_unit" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("price")}
                  >
                    Price{" "}
                    {sortedColumn === "price" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "price" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("shopping_aisle_id")}
                  >
                    Aisle{" "}
                    {sortedColumn === "shopping_aisle_id" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "shopping_aisle_id" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {fileterdItem.slice(startIndex, endIndex).map((item) => (
                  <tr key={item.id}>
                    <td>{item.item_name}</td>
                    <td className="text-center">{item.shopping_unit}</td>
                    <td className="text-center">${item.price}</td>
                    <td className="text-center">{item.aisle_name}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleSelectItem(item)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteIngredient(item)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* --------------------- */}
        {/* Edit Ingredient Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedItem !== null}
          onHide={() => setSelectedItem(null)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-2 col-sm">
                <label htmlFor="edit-ingredient-name" className="form-label">
                  Item Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={editIngredientName}
                  onChange={handleEditIngredientNameChange}
                />
              </div>
              <div className="mb-2 col-sm">
                <label htmlFor="edit-ingredient-name" className="form-label">
                  Unit Size
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={editShoppingUnit}
                  onChange={handleEditUnitShoppingNameChange}
                />
              </div>
              <label>Price:</label>
          <input
            type="number"
            className="form-control mb-2 w-50"
            placeholder="Enter price"
            value={editPrice}
            onChange={(e) => handleEditPriceChange(parseFloat(e.target.value))}
          />
              <div className="mb-3 col-sm">
                  <label htmlFor="edit-shopping-aisle" className="form-label">
                    Shopping Aisle
                  </label>
                  <Select
                    options={shoppingOptions}
                    value={{
                      aisle_name: editShoppingAisle,
                      id: editShoppingAisleId,
                    }}
                    onChange={(selectedOption) => {
                      setEditShoppingAisleId(selectedOption.id);
                      setEditShoppingAisle(selectedOption.aisle_name);
                    }}
                    getOptionLabel={(option) => option.aisle_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                  />
                </div>




            </form>
            <Button
              variant="secondary"
              onClick={() => setSelectedItem(null)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveIngredient}>
              Save Changes
            </Button>
          </Modal.Body>
        </Modal>
        {/* --------------------- */}
        {/* Edit Conversion Modal */}
        {/* --------------------- */}
      </div>
    </div>
  );
}

export default NonCookingItems;
