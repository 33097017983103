import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";

function MyMeals() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [meals, setMeals] = useState([]);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(false);
  const [isFree, setIsFree] = useState(true);

  //Table View Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  const tokenizer = (query) =>
    query
      .toLowerCase()
      .split(/[\s,]+/) // split on whitespace or comma
      .filter((token) => token.trim().length > 0);

  const filteredMealsWithoutFree = meals.filter(
    (meal) =>
      (!onlyUserRecipes || meal.user_id !== 1) &&
      tokenizer(searchQuery).every((token) => {
        const fieldsToSearch = [
          meal.meal_name.toLowerCase(),
          meal.meal_notes.toLowerCase(),
          meal.meal_tags.toLowerCase(),
          meal.created_at ? meal.created_at.toLowerCase() : null,
        ];
        return fieldsToSearch.some((field) => field && field.includes(token));
      })
  );

  let filteredMeals = filteredMealsWithoutFree;

  if (isFree) {
    const freeRecipes = filteredMealsWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredMealsWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredMeals = [...freeRecipes, ...first10PremiumRecipes];
  }

  const pagesCount = Math.ceil(filteredMeals.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const [recipes, setRecipes] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([
    { label: "", value: null },
  ]);
  const filteredRecipesWithoutFree = recipes.filter(
    (recipe) => !onlyUserRecipes || recipe.user_id !== 1
  );

  let filteredRecipes = filteredRecipesWithoutFree;

  if (isFree) {
    const freeRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  }

  filteredRecipes.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { caseFirst: "false" })
  );

  const recipeOptions = filteredRecipes.map((recipe) => {
    // Handle NaN case for multiplier
    const multiplier = isNaN(recipe.meal_mutliplyer)
      ? 1
      : recipe.meal_mutliplyer;

    return {
      label: recipe.name,
      value: recipe.recipe_id,
      servings: recipe.servings,
      course: recipe.course,
      calories: recipe.total_calories,
      price: recipe.total_price,
      multi: multiplier,
    };
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortedUnits = filteredMeals.sort((a, b) => {
    let aValue, bValue; // create variables to hold the value for each item being compared
    if (sortedColumn === "calories") {
      aValue = parseFloat(a.total_calories / a.servings);
      bValue = parseFloat(b.total_calories / b.servings);
    } else if (sortedColumn === "cost") {
      aValue = parseFloat(a.total_price / a.servings);
      bValue = parseFloat(b.total_price / b.servings);
    } else {
      aValue = a[sortedColumn];
      bValue = b[sortedColumn];
    }
    // sort based on the calculated or raw values, depending on the column
    if (sortDirection === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setOnlyUserRecipes(user.only_user_recipes === 1);

      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };



  const handleToggleUserRecipes = () => {
    setOnlyUserRecipes(!onlyUserRecipes);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/only_user_recipes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        only_user_recipes: onlyUserRecipes ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Function to fetch user meals list
  const fetchUserMeals = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/user_meals`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving meals from server");
        }
      })
      .then((data) => {
        console.log(data);

        setMeals(data)
        // const removedHiddenMeals = data.filter((meal) => meal.hidden === 0);
        // setMeals(removedHiddenMeals);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Fetch user meals list when the page loads
  useEffect(() => {
    fetchUserMeals();
  }, []);

  const fetchRecipes = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/recipes`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        console.log(data);
        // Filter out recipes where data.hidden === 0
        const removedHiddenRecipes = data.filter(
          (recipe) => recipe.hidden === 0
        );
        return removedHiddenRecipes;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetchRecipes()
      .then((recipes) => {
        setRecipes(recipes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedServings, setSelectedServings] = useState([]);

  const [selectedCalories, setSelectedCalories] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedMultiplyer, setSelectedMultiplyer] = useState([1]);

  const handleSelectedRecipe = (selectedOption, index) => {
    const newSelectedRecipes = [...selectedRecipes];
    newSelectedRecipes[index] = selectedOption;
    setSelectedRecipes(newSelectedRecipes);

    const newSelectedServings = [...selectedServings];
    newSelectedServings[index] = selectedOption.servings;
    setSelectedServings(newSelectedServings);

    const newSelectedCourses = [...selectedCourses];
    newSelectedCourses[index] = selectedOption.course;
    setSelectedCourses(newSelectedCourses);

    const newSelectedCalories = [...selectedCalories];
    newSelectedCalories[index] = selectedOption.calories;
    setSelectedCalories(newSelectedCalories);

    const newSelectedPrices = [...selectedPrices];
    newSelectedPrices[index] = selectedOption.price;
    setSelectedPrices(newSelectedPrices);

    const newSelectedMultiplyer = [...selectedMultiplyer];
    newSelectedMultiplyer[index] = selectedOption.multi;
    setSelectedMultiplyer(newSelectedMultiplyer);

  };

  // Function to add a new row
  const handleAddRow = () => {
    setSelectedRecipes([...selectedRecipes, { label: "", value: null }]);
    setSelectedMultiplyer([...selectedMultiplyer, 1]); // Set default multiplier to 1
  };

  // Create Meal
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newMealName, setNewMealName] = useState("");
  const [newMealNotes, setNewMealNotes] = useState("");
  const [newMealTags, setNewMealTags] = useState("");

  const handleOpenCreateModal = () => {
    if (isFree === false) {
    setShowCreateModal(true)
  }else {
    alert("A bronze membership or above is required to manage meals.")
  }
  }

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleCreateNewMeal = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/add_new_meal`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mealName: newMealName,
        mealNotes: newMealNotes,
        mealTags: newMealTags,
      }),
    }).then((response) => {
      handleCloseCreateModal();
      fetchUserMeals();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const handleNewMealNameChange = (event) => {
    setNewMealName(event.target.value);
  };

  const handleNewMealNotesChange = (event) => {
    setNewMealNotes(event.target.value);
  };

  const handleNewMealTagsChange = (event) => {
    setNewMealTags(event.target.value);
  };

  // Edit Meal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [editMealName, setEditMealName] = useState("");
  const [editMealNotes, setEditMealNotes] = useState("");
  const [editMealTags, setEditMealTags] = useState("");
  const [recipeDetails, setRecipeDetails] = useState([]);

  const handleMealClick = async (meal) => {
    if (isFree === false) {
    setShowEditModal(true); // Show the edit modal
    setSelectedMeal(meal); // Set the selected meal info
    setEditMealName(meal.meal_name);
    setEditMealNotes(meal.meal_notes);
    setEditMealTags(meal.meal_tags);
    console.log("----");
    console.log(meal);

    // Fetch recipe details for the selected meal
    const details = await fetchRecipeDetails(meal.id);
    setRecipeDetails(details);

    console.log(details);

    // Prepopulate selectedRecipes with existing recipe names if details is not null
    const prepopulatedRecipes =
      details &&
      details.map((recipe) => ({
        label: recipe.name,
        value: recipe.id,
      }));
      console.log("::::::")
      console.log(details)
    setSelectedRecipes(prepopulatedRecipes || []);

    const prepopulatedCourses =
      details && details.map((recipe) => recipe.course);
    setSelectedCourses(prepopulatedCourses || []);

    const prepopulatedServings =
      details && details.map((recipe) => recipe.servings);
    setSelectedServings(prepopulatedServings || []);

    const prepopulatedCalories =
      details && details.map((recipe) => recipe.total_calories);
    setSelectedCalories(prepopulatedCalories || []);

    const prepopulatedPrices =
      details && details.map((recipe) => recipe.total_price);
    setSelectedPrices(prepopulatedPrices || []);

    const prepopulatedMulti =
      details && details.map((recipe) => recipe.meal_mutliplyer);
    setSelectedMultiplyer(prepopulatedMulti || []);
  } else {
    alert("A bronze membership or above is required to manage meals.")
  }};

  // Function to fetch recipe details for the selected meal
  const fetchRecipeDetails = async (mealId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/meal-recipes/${mealId}`, {
        method: "GET",
        credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
    );
    if (response.ok) {
      const details = await response.json();
      return details.length > 0 ? details : null; // Return null if details array is empty
    } else {
      throw new Error("Failed to fetch recipe details");
    }
  };

  const [showHideTrigger, setShowHideTrigger] = useState(false);

  const handleShowHide = (selectedOption) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/showhide_meal`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        meal_id: selectedOption.id,
        isHidden: selectedOption.hidden,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      } else {
        // Update the trigger state to force useEffect to run again
        fetchUserMeals();
        setShowHideTrigger((prev) => !prev);
      }
    });
  };


  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditMealNameChange = (event) => {
    setEditMealName(event.target.value);
  };

  const handleEditMealNotesChange = (event) => {
    setEditMealNotes(event.target.value);
  };

  const handleEditMealTagsChange = (event) => {
    setEditMealTags(event.target.value);
  };

  // Add this function to your component to handle row removal
  const handleRemoveRow = (indexToRemove) => {
    setSelectedRecipes((prevSelectedRecipes) =>
      prevSelectedRecipes.filter((_, index) => index !== indexToRemove)
    );

    setSelectedMultiplyer((prevSelectedMultiplyer) =>
      prevSelectedMultiplyer.filter((_, index) => index !== indexToRemove)
    );

    setSelectedCourses((prevSelectedCourses) =>
      prevSelectedCourses.filter((_, index) => index !== indexToRemove)
    );

    setSelectedServings((prevSelectedServings) =>
      prevSelectedServings.filter((_, index) => index !== indexToRemove)
    );

    setSelectedCalories((prevSelectedCalories) =>
      prevSelectedCalories.filter((_, index) => index !== indexToRemove)
    );

    setSelectedPrices((prevSelectedPrices) =>
      prevSelectedPrices.filter((_, index) => index !== indexToRemove)
    );

    // Make sure to update all other related state arrays accordingly
  };

  const [showAlert, setShowAlert] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // Added to manage entry and exit animations

  // Show alert effect
  useEffect(() => {
    if (showAlert) {
      setIsVisible(true); // Make div visible for fading in
      const timer = setTimeout(() => {
        setShowAlert(false); // This will trigger fade out after 3 seconds
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  // Effect for handling fade out
  useEffect(() => {
    if (!showAlert && isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false); // Delay visibility hidden until after fade-out
      }, 1000); // Assuming the fade-out duration is 1s

      return () => clearTimeout(timer);
    }
  }, [showAlert, isVisible]);

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleSave = async () => {
    try {
      if (selectedRecipes.some((recipe) => recipe.value === null)) {
        // Check if any selectedRecipe has a null value
        alert("Please select a recipe for all rows before saving.");
        return;
      }

      // Update meal_name, meal_notes, and meal_tags
      await updateUserMeal(selectedMeal.id, {
        meal_name: editMealName,
        meal_notes: editMealNotes,
        meal_tags: editMealTags,
      });

      // Delete existing entries
      await deleteMealsRecipes(selectedMeal.id);

      // Add recipes
      await Promise.all(
        selectedRecipes.map(async (selectedRecipe, index) => {
          console.log(
            `Adding recipe ${selectedRecipe.label} with multiplier ${selectedMultiplyer[index]} to ${selectedMeal.id}`
          );
          await addMealRecipe(
            selectedMeal.id,
            selectedRecipe.value,
            selectedMultiplyer[index]
          );
        })
      );

      // Ensure alert is visible
      setIsAlertVisible(true);

      // Show alert
      setShowAlert(true);

      // Hide alert after 3 seconds by starting fade out
      setTimeout(() => {
        setShowAlert(false);

        // Wait for fade out to complete before hiding
        setTimeout(() => {
          setIsAlertVisible(false);
        }, 1000); // Assuming fade out transition is 1s
      }, 2000);

      // Fetch user meals
      fetchUserMeals();
    } catch (error) {
      console.error("Error saving:", error);
      // Handle error
    }
  };

  const updateUserMeal = async (mealId, data) => {
    // Send a PUT or PATCH request to update user_meals with the provided data for the specified mealId
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUserMeal/${mealId}`, {
      method: "PUT", // Assuming you want to use PUT method
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Use the data parameter here
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const deleteMealsRecipes = async (mealId) => {
    // Send a DELETE request to delete all entries in meals_recipes table for the specified mealId
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deleteMealsRecipes/${mealId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meal_id: mealId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // Optionally, handle the response here if needed
    } catch (error) {
      console.error(error);
      // Optionally, handle the error here (e.g., display error message to the user)
    }
  };

  const addMealRecipe = async (mealId, recipeId, multiplier) => {
    // Send a POST request to add a new entry in meals_recipes table with the specified mealId and recipeId
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/addMealRecipe/${mealId}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            recipe_id: recipeId,
            multiplier: multiplier,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // Optionally, handle the response here if needed
    } catch (error) {
      console.error(error);
      // Optionally, handle the error here (e.g., display error message to the user)
    }
  };

  const handleDeleteMeal = async (meal) => {
    // Show confirmation dialog
    const shouldDelete = window.confirm("Are you sure you want to delete this meal?");
    
    if (shouldDelete) {
      // Proceed with deletion
      await deleteMeal(meal.id);
      await deleteMealsRecipes(meal.id);
      fetchUserMeals();
    } else {
      // Do nothing or handle cancellation
    }
  }
  
  const deleteMeal = async (mealId) => {
    // Send a DELETE request to delete all entries in meals_recipes table for the specified mealId
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deleteMeal/${mealId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meal_id: mealId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // Optionally, handle the response here if needed
    } catch (error) {
      console.error(error);
      // Optionally, handle the error here (e.g., display error message to the user)
    }
  };

  const handleMultiplierChange = (index, value) => {
    const newMultipliers = [...selectedMultiplyer];
    newMultipliers[index] = value;
    setSelectedMultiplyer(newMultipliers);
  };

  useEffect(() => {
    fetchUserSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"Only Your Recipes"}
                checked={onlyUserRecipes}
                onChange={handleToggleUserRecipes}
              />{" "}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => handleOpenCreateModal()}
              >
                Add Meal
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">Ready to customize your culinary creations? You're in the right place! This is where you can fine-tune your meal combinations and add new meals to your collection. Explore and modify your meals to perfection.</div>

      <input
        type="text"
        className="form-control"
        placeholder="Search meals"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <br />

      <div>
        <div className="table-responsive">
          {" "}
          <table className="table table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_name")}
                >
                  Meal{" "}
                  {sortedColumn === "meal_name" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_name" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_notes")}
                >
                  Notes{" "}
                  {sortedColumn === "meal_notes" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_notes" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("id")}
                >
                  Date Created{" "}
                  {sortedColumn === "id" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "id" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_tags")}
                >
                  Tags{" "}
                  {sortedColumn === "meal_tags" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_tags" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("hidden")}
                >
                  Visibility{" "}
                  {sortedColumn === "hidden" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "hidden" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  
                >
                  Delete{" "}
                 
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMeals.slice(startIndex, endIndex).map((meal) => (
                <tr key={meal.id}>
                  <td>
                  {meal.hidden === 0 ? (
    <button
        className="btn btn-sm btn-outline-success mb-0"
        onClick={(event) => {
            event.stopPropagation();
            handleShowHide(meal);
        }}
    >
        Shown
    </button>
) : (
    <button
        className="btn btn-sm btn-outline-danger mb-0"
        onClick={(event) => {
            event.stopPropagation();
            handleShowHide(meal);
        }}
    >
        Hidden
    </button>
)} {" "}
                    <b
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => handleMealClick(meal)}
                    >
                      {meal.meal_name}
                    </b>
                  </td>
                  <td className="text-center">
  {meal.meal_notes ? meal.meal_notes.slice(0, 50) + "..." : ""}
</td>

                  <td className="text-center">
                    {new Date(meal.created_at).toLocaleDateString()}
                  </td>
                  <td className="text-center">
                    {meal.meal_tags
                      ? meal.meal_tags.length > 50
                        ? meal.meal_tags.slice(0, 50) + "..."
                        : meal.meal_tags
                      : ""}
                  </td>
                  <td className="text-center">
  {meal.hidden === 0 ? "Show" : "Hide"}
</td>
                  <td className="text-center"><Button variant="danger btn-sm" onClick={() => handleDeleteMeal(meal)} >Delete</Button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showCreateModal} onHide={handleCloseCreateModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Create New Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-2 col-sm">
              <label htmlFor="add-meal-name" className="form-label">
                Meal Name:
              </label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter meal name"
                value={newMealName}
                onChange={handleNewMealNameChange}
              />
              <label htmlFor="add-meal-notes" className="form-label">
                Meal Notes
              </label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter any meal notes (optional)"
                value={newMealNotes}
                onChange={handleNewMealNotesChange}
                rows="4"
              />

              <label htmlFor="add-meal-tags" className="form-label">
                Meal Tags:
              </label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter meal tags"
                value={newMealTags}
                onChange={handleNewMealTagsChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateNewMeal}>
            Create New Meal
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Meal Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="mb-2 col-3">
              <form>
                <div className="mb-2 col">
                  <label htmlFor="add-meal-name" className="form-label">
                    Meal Name:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter meal name"
                    value={editMealName}
                    onChange={handleEditMealNameChange}
                  />
                  <label htmlFor="add-meal-notes" className="form-label">
                    Meal Notes
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter any meal notes (optional)"
                    value={editMealNotes}
                    onChange={handleEditMealNotesChange}
                    rows="4"
                  />

                  <label htmlFor="add-meal-tags" className="form-label">
                    Meal Tags:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter meal tags"
                    value={editMealTags}
                    onChange={handleEditMealTagsChange}
                  />
                </div>
              </form>
            </div>

            <div className="mb-2 col-9">
              <table className="table">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Recipe</th>
                    <th scope="col">Multiplier</th>
                    <th scope="col">Course</th>
                    <th scope="col">Servings</th>
                    <th scope="col">Calories Per Serving</th>
                    <th scope="col">Price</th>
                    <th scope="col">Remove</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedRecipes.map((selectedRecipe, index) => {
                    if (!selectedRecipe) {
                      return null;
                    }

                    let newMultiServings;
                    if (!isNaN(selectedServings[index])) {
                      newMultiServings = `${(
                        (selectedServings[index] || 0) *
                        selectedMultiplyer[index]
                      ).toFixed(0)}`;
                    } else {
                      newMultiServings = 0;
                    }

                    // Calculate calories per serving with multiplier
                    let caloriesPerServing;
                    if (
                      !isNaN(selectedCalories[index]) &&
                      !isNaN(selectedServings[index]) &&
                      selectedServings[index] !== 0
                    ) {
                      caloriesPerServing = Math.round(
                        selectedCalories[index] / selectedServings[index]
                      );
                    } else {
                      // If either selectedCalories[index] or selectedServings[index] is NaN or 0, set caloriesPerServing to 0
                      caloriesPerServing = 0;
                    }

                    let formattedPrice;
                    if (!isNaN(selectedPrices[index])) {
                      formattedPrice = `$${(
                        (selectedPrices[index] || 0) * selectedMultiplyer[index]
                      ).toFixed(2)}`;
                    } else {

                      formattedPrice = 0;
                    }

                    // // Format price with multiplier
                    // const formattedPrice = `$${(selectedPrices[index] || 0 * selectedMultiplyer[index]).toFixed(2)}`;

                    return (
                      <tr key={index}>
                        <td className="col-5">
                          <Select
                            isSearchable={true}
                            options={recipeOptions}
                            value={selectedRecipe}
                            onChange={(selectedOption) =>
                              handleSelectedRecipe(selectedOption, index)
                            }
                          />
                        </td>
                        <td className="col-1 text-center">
                          <input
                            type="number"
                            min="0"
                            value={selectedMultiplyer[index]}
                            onChange={(e) =>
                              handleMultiplierChange(index, e.target.value)
                            }
                            className="form-control form-control-sm"
                          />
                        </td>
                        <td className="col-2 text-center">{selectedCourses[index]}</td>
                        <td className="col-1 text-center">{newMultiServings}</td>

                        <td className="col-2 text-center">{caloriesPerServing}</td>
                        <td className="col-1 text-center">{formattedPrice}</td>
                        <td className="col-1 text-center">
                          <Button variant="danger btn-sm" onClick={() => handleRemoveRow(index)}>
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                  {/* Total row */}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      <strong>
                        {selectedRecipes.reduce((totalCalories, _, index) => {
                          if (
                            selectedRecipes[index] &&
                            !isNaN(selectedCalories[index]) &&
                            !isNaN(selectedServings[index]) &&
                            selectedServings[index] !== 0
                          ) {
                            const caloriesPerServing = Math.round(
                              selectedCalories[index] / selectedServings[index]
                            );
                            totalCalories += caloriesPerServing; // Accumulate calories per serving
                          }
                          return totalCalories;
                        }, 0)}
                      </strong>
                    </td>

                    <td className="text-center">
                      <strong>
                        {/* Total price calculation remains the same */}$
                        {selectedPrices
                          .reduce((totalPrice, _, index) => {
                            if (selectedRecipes[index]) {
                              totalPrice +=
                                selectedPrices[index] *
                                selectedMultiplyer[index];
                            }
                            return totalPrice;
                          }, 0)
                          .toFixed(2)}
                      </strong>
         
                    </td>             <td></td>
                  </tr>
                </tbody>
              </table>
              <Button variant="primary" onClick={handleAddRow}>
                Add Row
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className={`alert alert-success ${showAlert ? "show" : ""}`}
            role="alert"
            style={{
              opacity: showAlert ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              visibility: isAlertVisible ? "visible" : "hidden",
            }}
          >
            Meal Saved
          </div>

          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyMeals;
