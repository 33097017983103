import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "../styles/style.scss";
import { UncachedImage } from "react-uncached-image";
import Fraction from "fraction.js";
import FullChatBot from "./FullChatBot";
import Tiptap from "./Tiptap";
import "../styles/Tiptap.css";

function ViewRecipe() {
  const { id } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userIsOwner, setUserIsOwner] = useState(false);

  // Private Notes Variables
  const [showPrivateNotesModal, setShowPrivateNotesModal] = useState(false);

  const handlePrivateNotesOpenModal = () => {
    if (!isLoggedIn) {
      // Display an error message, for example:
      alert("You need to log in to access your notes.");
    } else {
      setShowPrivateNotesModal(true);
    }
  };
  const handlePrivateNotesCloseModal = () => {
    setShowPrivateNotesModal(false);
  };

  const updateNotesContent = (updatedContent) => {
    setNotesContent(updatedContent);
  };

  // Holding the notes content state
  const [notesContent, setNotesContent] = useState("");


  const handlePrivatelSaveNotes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/private_recipe_notes_save`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        updatedNotes: notesContent,
      }),
    }).then((response) => {
      handlePrivateNotesCloseModal();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Public Notes Variables
  const [showPublicNotesModal, setShowPublicNotesModal] = useState(false);

  const handlePublicNotesOpenModal = () => {
    setShowPublicNotesModal(true);
  };

  const handlePublicNotesCloseModal = () => {
    setShowPublicNotesModal(false);
  };

  const updatePublicNotesContent = (updatedContent) => {
    setPublicNotesContent(updatedContent);
  };

  // Holding the notes content state
  const [publicNotesContent, setPublicNotesContent] = useState("");

  const handlePublicSaveNotes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/public_recipe_notes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: recipeData.id,
        updatedNotes: publicNotesContent,
      }),
    }).then((response) => {
      handlePublicNotesCloseModal();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, [id]);

  const [hasGold, setHasGold] = useState(false);
  const [hasSilver, setHasSilver] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/gold`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasGold(true);
          setHasSilver(true);
          console.log("Gold");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasGold(false);
        } else {
          console.log("Error");
          setHasGold(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasGold(false);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/silver`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasSilver(true);
          console.log("Silver");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasSilver(false);
        } else {
          console.log("Error");
          setHasSilver(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasSilver(false);
      });
  }, [id]);

  //Chatbot
  const [closeChat, setCloseChat] = useState(false);

  function toggleCloseChat() {
    if (!hasSilver) {
      alert("A Silver subscription is required for this feature");
      return;
    }

    // Toggle closeChat only if hasSilver is true
    setCloseChat(!closeChat);
  }

  const toggleClearChat = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the chat history? You cannot undo?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/delete-chat-history`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }), // Send recipeId in the request body
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      setCloseChat(!closeChat);
    } catch (error) {
      console.error(error);
    }
  };

  //
  const navigate = useNavigate();
  const [recipeData, setRecipeData] = useState("");
  const [isMetric, setIsMetric] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [urlName, setUrlName] = useState("");

  const fetchRecipeData = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/recipes/user-check-or-default/${id}`,
      {
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRecipeData(data);
        setPublicNotesContent(data.public_notes);
      })
      .catch((error) => console.error("Error retrieving recipe data:", error));
  };

  // Call fetchRecipeData with the recipe ID to fetch the data
  useEffect(() => {
    fetchRecipeData(id);
  }, [id]);

  const fetchPrivateNotes = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/get_private_recipe_notes/${id}`,
      {
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data[0] && data[0].private_note !== undefined) {
          setNotesContent(data[0].private_note);
        } else {
          // Set notesContent to an empty string if private_note is undefined
          setNotesContent(null);
        }
      })
      .catch((error) => console.error("Error retrieving recipe data:", error));setNotesContent(null);
  };

  // Call fetchRecipeData with the recipe ID to fetch the data
  useEffect(() => {
    fetchPrivateNotes(id);
  }, [recipeData]);

  const fetchRecipeCreatorUser = async (id, recipeData) => {
    console.log("Editing:");
    console.log(recipeData.user_id);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/recipes/public-recipe-check/${id}?creator_user_id=${recipeData.user_id}&individual_recipe_public=${recipeData.public}&recipe_hidden=${recipeData.hidden}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const results = await response.json();

      setIsPublic(results.isPublic);
      setDisplayName(results.display_name);
      setUrlName(results.public_url_name);

      if (!isPublic) {
        // Handle error
      }
    } catch (error) {
      // Handle error
    }
  };

  const [multiplyer, setMultiplyer] = useState(1);

  //Macro
  let [totalProtein, setTotalProtein] = useState(0);
  let [totalCarbs, setTotalCarbs] = useState(0);
  let [totalFat, setTotalFat] = useState(0);
  let [totalFiber, setTotalFiber] = useState(0);
  let [totalSugar, setTotalSugar] = useState(0);
  let [totalCalories, setTotalCalories] = useState(0);
  let [totalPrice, setTotalPrice] = useState(0);

  const fetchUserMetricSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      setCurrentUser(user.id);
      setIsMetric(user.default_unit_of_measurement === "metric");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggle = () => {
    setIsMetric(!isMetric);

    if (isLoggedIn) {
      // Make a POST request to update user's default_unit_of_measurement value
      fetch(`${process.env.REACT_APP_API_URL}/api/updateUser`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          default_unit_of_measurement: isMetric ? "traditional" : "metric",
        }),
      }).then((response) => {
        if (!response.ok) {
          // Handle error
        }
      });
    } else {
      console.log("Not Logged In");
    }
  };

  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [recipeInstructions, setRecipeInstructions] = useState([]);

  const fetchRecipeIngredients = (id) => {
    const unitType = isMetric ? "metric" : "traditional";
    console.log(`fetchRecipeIngredients Is Metric: ${isMetric}`);

    fetch(
      `${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${id}?unitType=${unitType}`,
      {
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRecipeIngredients(data);

        const totalProtein = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.protein),
          0
        );
        const totalCarbs = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.carbs),
          0
        );
        const totalFat = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.fat),
          0
        );
        const totalFiber = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.fiber),
          0
        );

        const totalSugar = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.sugar),
          0
        );
        const totalCalories = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.calories),
          0
        );
        const totalPrice = data.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.price),
          0
        );

        setTotalProtein(totalProtein);
        setTotalCarbs(totalCarbs);
        setTotalFat(totalFat);
        setTotalFiber(totalFiber);
        setTotalSugar(totalSugar);
        setTotalCalories(totalCalories);
        setTotalPrice(totalPrice);
      });
  };

  const fetchRecipeInstructions = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/instructions/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setRecipeInstructions(data);
      });
  };

  // New Qty:
  const handleNewMultiplyer = (multiplyerQty) => {
    if (!isNaN(multiplyerQty)) {
      setMultiplyer(multiplyerQty);
    } else {
      setMultiplyer("");
    }
  };

  const handleBackClick = () => {
    if (
      document.referrer &&
      document.referrer.startsWith(window.location.origin)
    ) {
      navigate(-1);
    } else {
      navigate("/recipes", { replace: true });
    }
  };

  const isUserRecipeOwner = () => {
    if (recipeData && currentUser && recipeData.user_id === currentUser) {
      console.log(`Recipe Data: ${recipeData.user_id}`);
      console.log(`Current user: ${currentUser}`);
      setUserIsOwner(true);
      console.log(userIsOwner);
    }
  };

  useEffect(() => {
    // Fetch ingredients
    isUserRecipeOwner();
  }, [recipeData, currentUser]);

  useEffect(() => {
    // Fetch ingredients
    fetchRecipeCreatorUser(id, recipeData);
    fetchRecipeInstructions(id);
  }, [recipeData]);

  useEffect(() => {
    // Fetch ingredients
    fetchRecipeIngredients(id);
    fetchUserMetricSetting();
    console.log(`current metric ${isMetric}`);
  }, [isMetric]);

  // if (!isLoggedIn) {
  //   return (
  //     <h3>
  //       Please{" "}
  //       <a className="btn btn-primary" href={`/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
  //         Sign In
  //       </a>{" "}
  //       to view
  //     </h3>
  //   );
  // }

  if (!isPublic) {
    return <div>This user currently does not have their recipes public.</div>;
  }

  return (
    <div>
      <div className="chatbot-widget">
        {closeChat ? (
          <>
            <Button
              className="hide-button"
              variant="primary"
              onClick={toggleCloseChat}
            >
              Close
            </Button>
            <Button
              className="clear-chat-button"
              variant="danger"
              onClick={toggleClearChat}
            >
              Clear Chat
            </Button>
            <FullChatBot
              recipeIngredients={recipeIngredients}
              recipeData={recipeData}
              recipeInstructions={recipeInstructions}
            />
          </>
        ) : (
          <div className="chatbot-tab" onClick={toggleCloseChat}>
            Chat with Remy
          </div>
        )}
      </div>

      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-2">
            <Button onClick={handleBackClick}> Back </Button>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-end">
              <b>Multiplyer:</b>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="d-flex justify-content-center">
              <input
                type="number"
                className="form-control"
                placeholder="Enter new unit name"
                min="0"
                value={multiplyer}
                onChange={(e) =>
                  handleNewMultiplyer(parseFloat(e.target.value))
                }
              />{" "}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"Use Metric"}
                checked={isMetric}
                onChange={handleToggle}
              />{" "}
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex justify-content-center">
              {recipeData.user_id === currentUser && (
                <Button
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_CLIENT_URL}/edit-recipe/${id}`)
                  }
                >
                  Edit Recipe
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 ">
          <h3>{recipeData.name}</h3>
          <h5>Cost to Make:</h5>
          <div>
            <label className="mr-2">
              <b>Recipe Cost:</b> $
              {parseFloat(totalPrice * multiplyer).toFixed(2)}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Serving Cost:</b> $
              {parseFloat(totalPrice / recipeData.servings).toFixed(2)}
            </label>
          </div>
          <h5 className="mt-3">Macros:</h5>
          <div>
            <label className="mr-2">
              <b>Calories:</b>{" "}
              {parseFloat(totalCalories / recipeData.servings).toFixed(0)}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Protein:</b>{" "}
              {parseFloat(totalProtein / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Carbs:</b>{" "}
              {parseFloat(totalCarbs / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Fat:</b>{" "}
              {parseFloat(totalFat / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Fiber:</b>{" "}
              {parseFloat(totalFiber / recipeData.servings).toFixed(1)}g
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Sugar:</b>{" "}
              {parseFloat(totalSugar / recipeData.servings).toFixed(1)}g
            </label>
          </div>
        </div>
        <div className="col-sm-5 ">
          <h5 className="mt-2">Recipe Info:</h5>
          <div>
            <label className="mr-2">
              <b>Contributed By: </b>
              <a
                href={`${process.env.REACT_APP_CLIENT_URL}/recipes/${urlName}`}
              >
                {displayName}
              </a>
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Name:</b> {recipeData.name}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Course:</b> {recipeData.course}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Servings:</b> {recipeData.servings}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Serving Size:</b> {recipeData.serving_size}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Tags:</b> {recipeData.tags}
            </label>
          </div>
          <div>
            <label className="mr-2">
              <b>Recipe Description:</b> {recipeData.description}
            </label>
          </div>
        </div>
        <div className="col-sm-4 text-center">
          {recipeData.image_url ? (
            <UncachedImage
              className="recipe-image-75 rounded"
              src={recipeData.image_url}
              alt={recipeData.name}
            />
          ) : (
            <div>No image available</div>
          )}
        </div>
      </div>
      <div className="row mt-2">
      <h3>Recipe Notes:</h3>
        <div className="col-sm-6 text-center">


<button
  className={`btn ${publicNotesContent !== null && publicNotesContent !== "<p></p>" ? "btn-success" : "btn-secondary"} my-2 me-2`}
  onClick={handlePublicNotesOpenModal}
>
  General Recipe Notes
</button>


          <button
  className={`btn ${notesContent !== null && notesContent !== "<p></p>" ?"btn-success" : "btn-secondary"} my-2`}
  onClick={handlePrivateNotesOpenModal}
>
  Personal Recipe Notes
</button>

        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-6">
          <div>
            <div>
              <h2>Recipe Ingredients</h2>

              <div className="table-responsive" style={{ overflow: "visible" }}>
                <table className="table">
                  <tbody>
                    {recipeIngredients.map((ingredient, index) => (
                      <tr key={ingredient.id}>
                        <td
                          className="align-middle"
                          style={{ backgroundColor: ingredient.color_tag }}
                        >
                          <b>{index + 1})</b>{" "}
                          <b>
                            {(() => {
                              const result = parseFloat(ingredient.qty);
                              let displayValue;

                              if (isMetric && !Number.isInteger(result)) {
                                displayValue = (result * multiplyer).toFixed(2);
                              } else if (!isMetric) {
                                if (
                                  (result % 1).toFixed(2) >= 0.31 &&
                                  (result % 1).toFixed(2) <= 0.35
                                ) {
                                  displayValue = new Fraction(
                                    Math.round(3 * result * multiplyer)
                                  )
                                    .div(3)
                                    .toFraction(true);
                                } else if (
                                  (result % 1).toFixed(2) >= 0.65 &&
                                  (result % 1).toFixed(2) <= 0.68
                                ) {
                                  displayValue = new Fraction(
                                    Math.round(3 * result * multiplyer)
                                  )
                                    .div(3)
                                    .toFraction(true);
                                } else {
                                  displayValue = new Fraction(
                                    Math.round(8 * result * multiplyer)
                                  )
                                    .div(8)
                                    .toFraction(true);
                                }
                              } else {
                                displayValue = result * multiplyer;
                              }

                              return <b>{displayValue}</b>;
                            })()}
                          </b>{" "}
                          {ingredient.unit_name} <b>{ingredient.name}</b>{" "}
                          {ingredient.suffix}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div>
            <h3>Recipe Instructions</h3>
            <div className="table-responsive" style={{ overflow: "visible" }}>
              <table className="table">
                <tbody>
                  {recipeInstructions.map((instruction, index) => (
                    <tr key={instruction.id}>
                      <td
                        className="align-middle"
                        style={{ backgroundColor: instruction.color_tag }}
                      >
                        <b>{index + 1})</b> {instruction.instruction}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* -------------------- */}
            {/* Edit Private Notes*/}
            {/* -------------------- */}
            <Modal
              show={showPrivateNotesModal}
              onHide={handlePrivateNotesCloseModal}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Private Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tiptap
                  initialContent={notesContent}
                  onUpdateContent={updateNotesContent}
                  editingStatus={true}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handlePrivateNotesCloseModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handlePrivatelSaveNotes}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -------------------- */}
            {/* Edit Public Notes*/}
            {/* -------------------- */}
            <Modal
              show={showPublicNotesModal}
              onHide={handlePublicNotesCloseModal}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Public Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tiptap
                  initialContent={publicNotesContent}
                  onUpdateContent={updatePublicNotesContent}
                  editingStatus={userIsOwner}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handlePublicNotesCloseModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handlePublicSaveNotes}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRecipe;
