import React, { useState } from "react";

function SignUp() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!username || !email || !password || !confirmPassword) {
      alert("All fields are required");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    console.log(
      `Submitting form with username ${username}, email ${email}, and password ${password}`
    );

    // Send POST request to server with form data
fetch(`${process.env.REACT_APP_API_URL}/signup/free`, {
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify({ username, email, password }),
})
  .then((response) => {
    console.log("Server response:", response);
    if (response.ok) {
      console.log("User signed up successfully");
      window.location.href = "/signin"; // redirect to homepage or login page
    } else {
      return response.json(); // Parse the error response as JSON
    }
  })
  .then((data) => {
    // Display the error message on the page
    const errorElement = document.getElementById("error-message");
    if (errorElement) {
      errorElement.textContent = data.error;
    }
  })
  .catch((error) => console.error("Error signing up:", error.stack));
};

return (
  <div className="container mx-auto" style={{ maxWidth: "720px" }}>
    <h2>Please Sign Up:</h2>
    <form className="p-3 shadow-lg rounded" onSubmit={handleSubmit} action="/signup">
      <div className="form-group">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          className="form-control"
          id="username"
          name="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          className="form-control"
          id="confirmPassword"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          required
        />
      </div>
      <p style={{ color: "red" }} id="error-message"></p>
      <button type="submit" className="btn btn-primary mt-2">Sign up for free</button>
    </form>
  </div>
);

}

export default SignUp;
