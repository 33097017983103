import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

function ResetPasswordForm(props) {
  const { token } = useParams();
  const decodedToken = decodeURIComponent(token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token, password }),
      });

      const data = await response.json();

      if (data.success) {
        setSuccess(true);
        setMessage('Password reset successfully!');
      } else {
        setMessage('Failed to reset password');
      }
    } catch (error) {
      setMessage('An error occurred while resetting the password.');
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto" style={{ maxWidth: "720px" }}>
      <h2 className="mt-3">Reset Password</h2>
      <form className="p-3 shadow-lg rounded mt-3" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">New Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
          />
</div>
        <button type="submit" className="btn btn-primary mt-2">Reset Password</button>
      </form>
      <br />
      {message && (
        <div className={`alert ${success ? 'alert-success' : 'alert-danger'}`} role="alert">
          {message}{' '}
          {success && (
            <a href="/signin" className="alert-link">
              Sign in here
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default ResetPasswordForm;
