import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/style.scss";

function Units() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [units, setUnits] = useState([]);
  const [newUnitName, setNewUnitName] = useState("");
  const [newUnitType, setNewUnitType] = useState("metric");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [editUnitName, setEditUnitName] = useState("");
  const [editUnitType, setEditUnitType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortedColumn, setSortedColumn] = useState("unit_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedUnitToHide, setSelectedUnitToHide] = useState(null);

  const handleHideUnit = (unit) => {
    setSelectedUnitToHide(unit);
  };

  const filteredUnits = units.filter(
    (unit) =>
      unit.unit_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      unit.unit_type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pagesCount = Math.ceil(filteredUnits.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const sortedUnits = filteredUnits.sort((a, b) => {
    try {
    if (sortDirection === "asc") {
      return a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase() ? 1 : -1;
    } else {
      return a[sortedColumn].toLowerCase() < b[sortedColumn].toLowerCase() ? 1 : -1;
    }} catch {}
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleNewUnitNameChange = (event) => {
    setNewUnitName(event.target.value);
    setNewUnitType(event.target.dataset.unitType);
  };

  const fetchUnits = () => {
    fetch(`${process.env.REACT_APP_API_URL}/units`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Check if data is loaded correctly
        setUnits(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const handleAddUnit = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-unit`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        unitName: newUnitName.trim(),
        unitType: newUnitType.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchUnits();
          setNewUnitName("");
          setNewUnitType("metric");
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleSelectUnit = (unit) => {
    setSelectedUnit(unit);
    setEditUnitName(unit.unit_name);
    setEditUnitType(unit.unit_type);
  };

  const handleEditUnitNameChange = (event) => {
    setEditUnitName(event.target.value);
  };

  const handleEditUnitTypeChange = (event) => {
    setEditUnitType(event.target.value);
  };

  const handleSaveUnit = () => {
    // Send POST request to update unit
    fetch(
      `${process.env.REACT_APP_API_URL}/edit-unit?id=${selectedUnit.unit_id}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedUnit.unit_id,
          unitName: editUnitName.trim(),
          unitType: editUnitType.toLowerCase(),
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchUnits();
          hiddenFetchUnits();
          setSelectedUnit(null);
          setEditUnitName("");
          setEditUnitType("");
        } else {
          throw new Error("Error updating unit");
        }
      })
      .catch((err) => {
        console.error("Error updating unit:", err);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    hiddenFetchUnits();
    fetchUnits();
  }, []);

  const handleConfirmHideUnit = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/hide-unit?id=${selectedUnitToHide.unit_id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            id: selectedUnitToHide.unit_id,
            display: 0,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedUnitToHide(null);
      // Reload your table data here to reflect the updated display status
      fetchUnits();
      hiddenFetchUnits();
    } catch (error) {
      console.error(error);
      // Show error message to user
    }
  };

  // Hidden Units Only
  const [hiddenUnits, setHiddenUnits] = useState([]);
  const [hiddenSelectedUnit, setHiddenSelectedUnit] = useState(null);
  const [hiddenEditUnitName, setHiddenEditUnitName] = useState("");
  const [hiddenEditUnitType, setHiddenEditUnitType] = useState("");
  const [hiddenSearchQuery, setHiddenSearchQuery] = useState("");
  const [hiddenCurrentPage, setHiddenCurrentPage] = useState(0);
  const [hiddenPageSize, setHiddenPageSize] = useState(25);
  const [hiddenSortedColumn, setHiddenSortedColumn] = useState("unit_name");
  const [hiddenSortDirection, setHiddenSortDirection] = useState("asc");
  const [selectedUnitToUnHide, setSelectedUnitToUnHide] = useState(null);

  const handleUnHideUnit = (hiddenUnit) => {
    setSelectedUnitToUnHide(hiddenUnit);
  };

  const hiddenFilteredUnits = hiddenUnits.filter(
    (hiddenUnit) =>
      hiddenUnit.unit_name
        .toLowerCase()
        .includes(hiddenSearchQuery.toLowerCase()) ||
      hiddenUnit.unit_type
        .toLowerCase()
        .includes(hiddenSearchQuery.toLowerCase())
  );

  const hiddenPagesCount = Math.ceil(
    hiddenFilteredUnits.length / hiddenPageSize
  );
  const hiddenStartIndex = hiddenCurrentPage * hiddenPageSize;
  const hiddenEndIndex = hiddenStartIndex + hiddenPageSize;

  const hiddenSortedUnits = hiddenFilteredUnits.sort((a, b) => {
    try {
    if (hiddenSortDirection === "asc") {
      return a[hiddenSortedColumn].toLowerCase() > b[hiddenSortedColumn].toLowerCase() ? 1 : -1;
    } else {
      return a[hiddenSortedColumn].toLowerCase() < b[hiddenSortedColumn].toLowerCase() ? 1 : -1;
    }} catch {}
  });

  const hiddenHandleSort = (columnName) => {
    if (hiddenSortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setHiddenSortDirection(hiddenSortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setHiddenSortedColumn(columnName);
      setHiddenSortDirection("asc");
    }
  };

  const hiddenHandlePrevPage = () => {
    setHiddenCurrentPage((prev) => prev - 1);
  };

  const hiddenHandleNextPage = () => {
    setHiddenCurrentPage((prev) => prev + 1);
  };

  const hiddenFetchUnits = () => {
    fetch(`${process.env.REACT_APP_API_URL}/hidden-units`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Check if data is loaded correctly
        setHiddenUnits(data);
      })
      .catch((err) => {
        console.error("Error retrieving user hidden units:", err);
      });
  };

  const hiddenHandleSelectUnit = (hiddenUnit) => {
    setHiddenSelectedUnit(hiddenUnit);
    setHiddenEditUnitName(hiddenUnit.unit_name);
    setHiddenEditUnitType(hiddenUnit.unit_type);
  };

  const hiddenHandleEditUnitNameChange = (event) => {
    setHiddenEditUnitName(event.target.value);
  };

  const hiddenHandleEditUnitTypeChange = (event) => {
    setHiddenEditUnitType(event.target.value);
  };

  const hiddenHandleSaveUnit = () => {
    // Send POST request to update unit
    fetch(
      `${process.env.REACT_APP_API_URL}/edit-unit?id=${selectedUnit.unit_id}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: hiddenSelectedUnit.unit_id,
          unitName: hiddenEditUnitName.trim(),
          unitType: hiddenEditUnitType.toLowerCase(),
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          // Reload units data
          hiddenFetchUnits();
          setHiddenSelectedUnit(null);
          setHiddenEditUnitName("");
          setHiddenEditUnitType("");
        } else {
          throw new Error("Error updating unit");
        }
      })
      .catch((err) => {
        console.error("Error updating unit:", err);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchUnits();
    hiddenFetchUnits();
  }, []);

  const handleConfirmUnHideUnit = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/unhide-unit?id=${selectedUnitToUnHide.unit_id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            id: selectedUnitToUnHide.unit_id,
            display: 1,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedUnitToUnHide(null);
      // Reload your table data here to reflect the updated display status
      fetchUnits();
      hiddenFetchUnits();
    } catch (error) {
      console.error(error);
      // Show error message to user
    }
  };

  if (!isLoggedIn) {
    return <h3>Please <a className="btn btn-primary" href={`/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
    Sign In
  </a> to view</h3>;
  }

  return (
    <div>
      <div>
        <h2>Add Unit of Measurement</h2>
        <div className="mb-3 col-sm-4">
          <input
            type="text"
            className="form-control"
            placeholder="Enter new unit name"
            value={newUnitName}
            onChange={handleNewUnitNameChange}
            data-unit-type={newUnitType}
          />
          <select
            className="form-select mt-2"
            value={newUnitType}
            onChange={(event) => setNewUnitType(event.target.value)}
          >
            <option value="metric">Metric</option>
            <option value="traditional">Traditional</option>
            <option value="other">Other</option>
          </select>
          <button className="btn btn-secondary mt-2" onClick={handleAddUnit}>
            Add Unit
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Active Units of Measurement</h2>
        </div>

 
          <div>
            <div className="row">
              <div className="col-sm-4 ">
                <label htmlFor="page-size-select" className="mr-2">
                  Show:
                </label>{" "}
                <div className="btn-group mr-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {pageSize}
                  </button>
                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      onClick={() => setPageSize(10)}
                    >
                      10
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setPageSize(25)}
                    >
                      25
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setPageSize(50)}
                    >
                      50
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setPageSize(100)}
                    >
                      100
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search units"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: pagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          currentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(currentPage - halfMaxPagesToShow, 0),
                pagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, pagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== pagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${currentPage === i && "active"}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          currentPage === pagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={handleNextPage}
          disabled={currentPage === pagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>

            </div>
            <div className="table-responsive">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("unit_name")}
                    >
                      Unit Name{" "}
                      {sortedColumn === "unit_name" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "unit_name" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("unit_type")}
                    >
                      Unit Type{" "}
                      {sortedColumn === "unit_type" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "unit_type" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("unit_source")}
                    >
                      Unit Source{" "}
                      {sortedColumn === "unit_source" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "unit_source" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th className="text-center">Edit</th>
                    <th className="text-center">Hide</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUnits.slice(startIndex, endIndex).map((unit) => (
                    <tr key={unit.unit_id}>
                      <td>{unit.unit_name}</td>
                      <td className="text-center">{unit.unit_type}</td>
                      <td className="text-center">{unit.unit_source}</td>
                      <td className="text-center">
                        {unit.unit_source === "User" && (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => handleSelectUnit(unit)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        {unit.unit_source === "User" && (
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleHideUnit(unit)}
                          >
                            Hide
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        <Modal
          show={selectedUnit !== null}
          onHide={() => setSelectedUnit(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="edit-unit-name" className="form-label">
                  Unit Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-unit-name"
                  value={editUnitName}
                  onChange={handleEditUnitNameChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-unit-type" className="form-label">
                  Unit Type
                </label>
                <select
                  className="form-select"
                  id="edit-unit-type"
                  value={editUnitType}
                  onChange={handleEditUnitTypeChange}
                >
                  <option value="metric">Metric</option>
                  <option value="traditional">Traditional</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedUnit(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveUnit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={selectedUnitToHide !== null}
          onHide={() => setSelectedUnitToHide(null)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Hide Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to hide this unit? Hidden units will not be
            able to be seleceted for ingredients. ***PLEASE NOTE*** This will
            not update any existing ingredients. Those ingredients will still be
            linked to this Unit.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedUnitToHide(null)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmHideUnit}>
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* Hidden Units
    Hide and Unhide Option
     */}

      <div>
        <div className="mb-3 col-12">
          <h2>Hidden Units of Measurement</h2>
          <p>
            Hidden units are still attached to your existing ingredients;
            however, they will not appear in in the dropdown when customizing
            ingredients.
          </p>
        </div>

          <div>
            <div className="row">
              <div className="col-sm-4 ">
                <label htmlFor="page-size-select" className="mr-2">
                  Show:
                </label>{" "}
                <div className="btn-group mr-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {hiddenPageSize}
                  </button>
                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      onClick={() => setHiddenPageSize(10)}
                    >
                      10
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setHiddenPageSize(25)}
                    >
                      25
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setHiddenPageSize(50)}
                    >
                      50
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => setHiddenPageSize(100)}
                    >
                      100
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search units"
                  value={hiddenSearchQuery}
                  onChange={(e) => setHiddenSearchQuery(e.target.value)}
                />
              </div>
              <div className="col-sm-4">
                <nav>
                  <ul className="pagination justify-content-end">
                    <li
                      className={`page-item ${
                        hiddenCurrentPage === 0 && "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={hiddenHandlePrevPage}
                        disabled={hiddenCurrentPage === 0}
                      >
                        Previous
                      </button>
                    </li>
                    {Array.from({ length: hiddenPagesCount }, (_, i) => (
                      <li
                        key={i}
                        className={`page-item ${
                          hiddenCurrentPage === i && "active"
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setHiddenCurrentPage(i)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        hiddenCurrentPage === hiddenPagesCount - 1 && "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={hiddenHandleNextPage}
                        disabled={hiddenCurrentPage === hiddenPagesCount - 1}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => hiddenHandleSort("unit_name")}
                    >
                      Unit Name{" "}
                      {hiddenSortedColumn === "unit_name" && (
                        <i
                          className={`fas fa-chevron-${
                            hiddenSortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {hiddenSortedColumn !== "unit_name" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => hiddenHandleSort("unit_type")}
                    >
                      Unit Type{" "}
                      {hiddenSortedColumn === "unit_type" && (
                        <i
                          className={`fas fa-chevron-${
                            hiddenSortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {hiddenSortedColumn !== "unit_type" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => hiddenHandleSort("unit_source")}
                    >
                      Unit Source{" "}
                      {hiddenSortedColumn === "unit_source" && (
                        <i
                          className={`fas fa-chevron-${
                            hiddenSortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {hiddenSortedColumn !== "unit_source" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th className="text-center">Edit</th>
                    <th className="text-center">Unhide</th>
                  </tr>
                </thead>
                <tbody>
                  {hiddenFilteredUnits
                    .slice(hiddenStartIndex, hiddenEndIndex)
                    .map((hiddenUnit) => (
                      <tr key={hiddenUnit.unit_id}>
                        <td>{hiddenUnit.unit_name}</td>
                        <td className="text-center">{hiddenUnit.unit_type}</td>
                        <td className="text-center">
                          {hiddenUnit.unit_source}
                        </td>
                        <td className="text-center">
                          {hiddenUnit.unit_source === "User" && (
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => handleSelectUnit(hiddenUnit)}
                            >
                              Edit
                            </button>
                          )}
                        </td>
                        <td className="text-center">
                          {hiddenUnit.unit_source === "User" && (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleUnHideUnit(hiddenUnit)}
                            >
                              Unhide
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

        <Modal
          show={hiddenSelectedUnit !== null}
          onHide={() => setHiddenSelectedUnit(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="edit-unit-name" className="form-label">
                  Unit Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-unit-name"
                  value={editUnitName}
                  onChange={handleEditUnitNameChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-unit-type" className="form-label">
                  Unit Type
                </label>
                <select
                  className="form-select"
                  id="edit-unit-type"
                  value={editUnitType}
                  onChange={handleEditUnitTypeChange}
                >
                  <option value="metric">Metric</option>
                  <option value="traditional">Traditional</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedUnit(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveUnit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={selectedUnitToUnHide !== null}
          onHide={() => setSelectedUnitToUnHide(null)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Unhide Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>Would you like to unhide this unit?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedUnitToUnHide(null)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmUnHideUnit}>
              Unhide
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Units;
