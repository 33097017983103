import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";


function MealBrowser() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [meals, setMeals] = useState([]);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(false);
  const [isFree, setIsFree] = useState(true);

  //Table View Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  const tokenizer = (query) =>
    query
      .toLowerCase()
      .split(/[\s,]+/) // split on whitespace or comma
      .filter((token) => token.trim().length > 0);

  const filteredMealsWithoutFree = meals.filter(
    (meal) =>
      (!onlyUserRecipes || meal.user_id !== 1) &&
      tokenizer(searchQuery).every((token) => {
        const fieldsToSearch = [
          meal.meal_name.toLowerCase(),
          meal.meal_tags.toLowerCase(),
          meal.created_at ? meal.created_at.toLowerCase() : null,
        ];
        return fieldsToSearch.some((field) => field && field.includes(token));
      })
  );

  let filteredMeals = filteredMealsWithoutFree;

  if (isFree) {
    const freeRecipes = filteredMealsWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredMealsWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredMeals = [...freeRecipes, ...first10PremiumRecipes];
  }

  const pagesCount = Math.ceil(filteredMeals.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const [recipes, setRecipes] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([
    { label: "", value: null },
  ]);
  const filteredRecipesWithoutFree = recipes.filter(
    (recipe) => !onlyUserRecipes || recipe.user_id !== 1
  );

  let filteredRecipes = filteredRecipesWithoutFree;

  if (isFree) {
    const freeRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  }

  filteredRecipes.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { caseFirst: "false" })
  );

  const recipeOptions = filteredRecipes.map((recipe) => {
    // Handle NaN case for multiplier
    const multiplier = isNaN(recipe.meal_mutliplyer)
      ? 1
      : recipe.meal_mutliplyer;

    return {
      label: recipe.name,
      value: recipe.recipe_id,
      servings: recipe.servings,
      course: recipe.course,
      calories: recipe.total_calories,
      price: recipe.total_price,
      multi: multiplier,
    };
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortedUnits = filteredMeals.sort((a, b) => {
    let aValue, bValue; // create variables to hold the value for each item being compared
    if (sortedColumn === "calories") {
      aValue = parseFloat(a.total_calories / a.servings);
      bValue = parseFloat(b.total_calories / b.servings);
    } else if (sortedColumn === "cost") {
      aValue = parseFloat(a.total_price / a.servings);
      bValue = parseFloat(b.total_price / b.servings);
    } else {
      aValue = a[sortedColumn];
      bValue = b[sortedColumn];
    }
    // sort based on the calculated or raw values, depending on the column
    if (sortDirection === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setOnlyUserRecipes(user.only_user_recipes === 1);

      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggleUserRecipes = () => {
    setOnlyUserRecipes(!onlyUserRecipes);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/only_user_recipes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        only_user_recipes: onlyUserRecipes ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Function to fetch user meals list
  const fetchUserMeals = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/user_meals`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving meals from server");
        }
      })
      .then((data) => {
        console.log(data);

        const removedHiddenMeals = data.filter((meal) => meal.hidden === 0);
        setMeals(removedHiddenMeals);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Fetch user meals list when the page loads
  useEffect(() => {
    fetchUserMeals();
  }, []);

  const fetchRecipes = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/recipes`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        console.log(data);
        // Filter out recipes where data.hidden === 0
        const removedHiddenRecipes = data.filter(
          (recipe) => recipe.hidden === 0
        );
        return removedHiddenRecipes;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetchRecipes()
      .then((recipes) => {
        setRecipes(recipes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedServings, setSelectedServings] = useState([]);

  const [selectedCalories, setSelectedCalories] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedMultiplyer, setSelectedMultiplyer] = useState([1]);

  // Edit Meal
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [editMealName, setEditMealName] = useState("");
  const [editMealNotes, setEditMealNotes] = useState("");
  const [editMealTags, setEditMealTags] = useState("");


  //For Weekly Plan
  const [multiplyer, setMultiplyer] = useState(1);

  const [selectedMealToPlan, setSelectedMealToPlan] = useState(null);
  const [selectedMealToPlanName, setSelectedMealToPlanName] = useState("");
  const [selectedMealToPlanValue, setSelectedMealToPlanValue] = useState("");
  const [selectedMealToPlanServings, setSelectedMealToPlanServings] = useState("");

  const [selectedMealCourseValue, setSelectedMealCourseValue] = useState("");
  const [selectedMealCourseName, setSelectedMealCourseName] = useState("");
  
  const [selectedMealDayValue, setSelectedMealDayValue] = useState("");
  const [selectedMealDayName, setSelectedMealDayName] = useState("");


  const dayOptions = [
    { value: "None", label: "None" },
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const courseOptions = [
    { value: "Other", label: "Other" },
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
    { value: "Appetizer", label: "Appetizer" },
    { value: "Snack", label: "Snack" },
    { value: "Dessert", label: "Dessert" },
  ].map((option) => ({ value: option.value, label: option.value }));

  

  //Add to weekly plan
  const handleSelectedMealToPlan= (selectedOption) => {
    setMultiplyer(1);
    setSelectedMealToPlanName(selectedOption.meal_name);
    setSelectedMealToPlan(selectedOption.id);
    setSelectedMealToPlanValue(selectedOption);
    setSelectedMealToPlanServings(selectedOption.servings);
    console.log(selectedOption);
  };

  const handleNewMultiplyer = (mulitplyerQty) => {
    setMultiplyer(mulitplyerQty);
  };

  const handleAddMeal = () => {
    if (!selectedMealToPlanValue) {
      alert("Please select a recipe");
      return;
    }

    handleWeeklyPlanMealUpdate();
    handleShoppingListMealAdd(selectedMealToPlan);
  };


  const handleWeeklyPlanMealUpdate = async () => {
    try {
      // Fetch recipe details for the selected meal
      const recipeDetails = await fetchRecipeDetails(selectedMealToPlan);

      // Check if recipeDetails is not null and is an array
      if (recipeDetails && Array.isArray(recipeDetails)) {
        // Execute all fetch requests concurrently using Promise.all

        await Promise.all(
          recipeDetails.map(async (recipeId) => {
            // Send POST request to add each recipe to the weekly plan

            const mealQty = multiplyer * recipeId.meal_mutliplyer;

            await fetch(
              `${process.env.REACT_APP_API_URL}/api/add-weekly-plan-recipe`,
              {
                method: "POST",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  recipe_id: recipeId.id,
                  meal_qty: mealQty,
                  day: selectedMealDayValue,
                  meal: selectedMealCourseValue,
                }),
              }
            );
          })
        );

        setSelectedMealToPlan(null);


      } else {
        throw new Error("No recipe details found for the selected meal");
      }
    } catch (error) {
      console.error("Error adding new units:", error);
    }
  };

  const handleShoppingListMealAdd = async (mealId) => {
    try {
      console.log("Fetching recipe IDs...");
      const recipeIdsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/meal-recipes/${mealId}`,
        {
          credentials: "include",
        }
      );
      if (!recipeIdsResponse.ok) {
        throw new Error("Failed to fetch recipe IDs for the selected meal");
      }
      const recipeIdsData = await recipeIdsResponse.json();

      console.log("Fetched recipe IDs:", recipeIdsData);

      for (const recipeIdData of recipeIdsData) {
        const recipeId = recipeIdData.id;

        console.log("Fetching ingredients for recipe ID:", recipeId);
        const ingredientsResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${recipeId}`,
          {
            credentials: "include",
          }
        );
        if (!ingredientsResponse.ok) {
          throw new Error(
            `Failed to fetch ingredients for recipe ID ${recipeId}`
          );
        }
        const ingredientsData = await ingredientsResponse.json();

        console.log(
          "Fetched ingredients for recipe ID:",
          recipeId,
          ingredientsData
        );

        const meal_specific_multi = recipeIdData.meal_mutliplyer;

        ingredientsData.forEach(async (ingredient) => {
          const ingredientId = ingredient.old_id.split(",")[0];
          const customIngredientFlag = ingredient.old_id.includes("user")
            ? 1
            : 0;

          console.log("Adding ingredient to shopping list:", ingredient);

          await fetch(
            `${process.env.REACT_APP_API_URL}/add-shopping-item-from-weekly-plan`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ingredient_id: ingredientId,
                custom_ingredient: customIngredientFlag,
                qty:
                multiplyer *
                  meal_specific_multi *
                  ingredient.qty *
                  ingredient.conversion_factor,
                day: selectedMealDayValue,
                meal: selectedMealCourseValue,
                for_recipe: recipeId,
                original_id_string: ingredient.old_id,
              }),
            }
          );
        });
      }
    } catch (error) {
      console.error("Error handling shopping list for meal:", error);
    }
  };



  const handleMealClick = async (meal) => {
    setShowEditModal(true); // Show the edit modal
    setSelectedMeal(meal); // Set the selected meal info
    setEditMealName(meal.meal_name);
    setEditMealNotes(meal.meal_notes);
    setEditMealTags(meal.meal_tags);
    console.log("----");
    console.log(meal);

    // Fetch recipe details for the selected meal
    const details = await fetchRecipeDetails(meal.id);
   
    // Prepopulate selectedRecipes with existing recipe names if details is not null
    const prepopulatedRecipes =
      details &&
      details.map((recipe) => ({
        label: recipe.name,
        value: recipe.id,
      }));
    console.log("::::::");
    console.log(details);
    setSelectedRecipes(prepopulatedRecipes || []);

    const prepopulatedCourses =
      details && details.map((recipe) => recipe.course);
    setSelectedCourses(prepopulatedCourses || []);

    const prepopulatedServings =
      details && details.map((recipe) => recipe.servings);
    setSelectedServings(prepopulatedServings || []);

    const prepopulatedCalories =
      details && details.map((recipe) => recipe.total_calories);
    setSelectedCalories(prepopulatedCalories || []);

    const prepopulatedPrices =
      details && details.map((recipe) => recipe.total_price);
    setSelectedPrices(prepopulatedPrices || []);

    const prepopulatedMulti =
      details && details.map((recipe) => recipe.meal_mutliplyer);
    setSelectedMultiplyer(prepopulatedMulti || []);
  };

  // Function to fetch recipe details for the selected meal
  const fetchRecipeDetails = async (mealId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/meal-recipes/${mealId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      const details = await response.json();
      return details.length > 0 ? details : null; // Return null if details array is empty
    } else {
      throw new Error("Failed to fetch recipe details");
    }
  };


  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };


  useEffect(() => {
    fetchUserSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}

        <div className="row align-items-center justify-content-center">
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"Only Your Recipes"}
                checked={onlyUserRecipes}
                onChange={handleToggleUserRecipes}
              />{" "}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center"></div>
          </div>
        </div>
      </div>
      <div className="mb-2"> Explore your Meals to discover curated combinations of your favorite recipes, perfect for any occasion from everyday meals to special celebrations. To create or edit Meals, visit <a href="/my_meals">Edit My Meals</a> under "My Customizations."</div>
      <input
        type="text"
        className="form-control"
        placeholder="Search meals"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <br />

      <div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_name")}
                >
                  Meal{" "}
                  {sortedColumn === "meal_name" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_name" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_notes")}
                >
                  Notes{" "}
                  {sortedColumn === "meal_notes" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_notes" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("id")}
                >
                  Date Created{" "}
                  {sortedColumn === "id" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "id" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>

                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => handleSort("meal_tags")}
                >
                  Tags{" "}
                  {sortedColumn === "meal_tags" && (
                    <i
                      className={`fas fa-chevron-${
                        sortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "meal_tags" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMeals.slice(startIndex, endIndex).map((meal) => (
                <tr key={meal.id}>
                  <td>
                  <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectedMealToPlan(meal);
                      }}
                    >
                      + To Plan
                    </button>{" "}
                    <b
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => handleMealClick(meal)}
                    >
                      {meal.meal_name}
                    </b>
                  </td>
                  <td className="text-center">{meal.meal_notes}</td>
                  <td className="text-center">
                    {new Date(meal.created_at).toLocaleDateString()}
                  </td>
                  <td className="text-center">
                    {meal.meal_tags
                      ? meal.meal_tags.length > 50
                        ? meal.meal_tags.slice(0, 50) + "..."
                        : meal.meal_tags
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* View Meal Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="mb-2 col-3">
              <form>
                <div className="mb-2 col">
                  <label htmlFor="add-meal-name" className="form-label">
                    Meal Name:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder=""
                    value={editMealName}
                    readOnly // or disabled
                  />
                  <label htmlFor="add-meal-notes" className="form-label">
                    Meal Notes
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={editMealNotes}
                    readOnly // or disabled
                    rows="4"
                  />

                  <label htmlFor="add-meal-tags" className="form-label">
                    Meal Tags:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder=""
                    value={editMealTags}
                    readOnly // or disabled
                  />
                </div>
              </form>
            </div>

            <div className="mb-2 col-9">
              <table className="table">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Recipe</th>
                    <th scope="col">Multiplier</th>
                    <th scope="col">Course</th>
                    <th scope="col">Servings</th>
                    <th scope="col">Calories Per Serving</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedRecipes.map((selectedRecipe, index) => {
                    if (!selectedRecipe) {
                      return null;
                    }

                    let newMultiServings;
                    if (!isNaN(selectedServings[index])) {
                      newMultiServings = `${(
                        (selectedServings[index] || 0) *
                        selectedMultiplyer[index]
                      ).toFixed(0)}`;
                    } else {
                      newMultiServings = 0;
                    }

                    // Calculate calories per serving with multiplier
                    let caloriesPerServing;
                    if (
                      !isNaN(selectedCalories[index]) &&
                      !isNaN(selectedServings[index]) &&
                      selectedServings[index] !== 0
                    ) {
                      caloriesPerServing = Math.round(
                        selectedCalories[index] / selectedServings[index]
                      );
                    } else {
                      // If either selectedCalories[index] or selectedServings[index] is NaN or 0, set caloriesPerServing to 0
                      caloriesPerServing = 0;
                    }

                    let formattedPrice;
                    if (!isNaN(selectedPrices[index])) {
                      formattedPrice = `$${(
                        (selectedPrices[index] || 0) * selectedMultiplyer[index]
                      ).toFixed(2)}`;
                    } else {
                      formattedPrice = 0;
                    }

                    return (
                      <tr key={index}>
                        <td className="col-5">
                          <a
                            href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${selectedRecipe.value}`}
                            style={{
                              textDecoration: "underline",
                              color: "black",
                            }}
                          >
                            <b>{selectedRecipe.label}</b>
                          </a>
                        </td>
                        <td className="col-1 text-center">
                          {selectedMultiplyer[index]}
                        </td>
                        <td className="col-2 text-center">
                          {selectedCourses[index]}
                        </td>
                        <td className="col-1 text-center">
                          {newMultiServings}
                        </td>

                        <td className="col-2 text-center">
                          {caloriesPerServing}
                        </td>
                        <td className="col-1 text-center">{formattedPrice}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      <strong>
                        {selectedRecipes.reduce((totalCalories, _, index) => {
                          if (
                            selectedRecipes[index] &&
                            !isNaN(selectedCalories[index]) &&
                            !isNaN(selectedServings[index]) &&
                            selectedServings[index] !== 0
                          ) {
                            const caloriesPerServing = Math.round(
                              selectedCalories[index] / selectedServings[index]
                            );
                            totalCalories += caloriesPerServing; // Accumulate calories per serving
                          }
                          return totalCalories;
                        }, 0)}
                      </strong>
                    </td>
                    <td className="text-center">
                      <strong>
                        {/* Total price calculation remains the same */}$
                        {selectedPrices
                          .reduce((totalPrice, _, index) => {
                            if (selectedRecipes[index]) {
                              totalPrice +=
                                selectedPrices[index] *
                                selectedMultiplyer[index];
                            }
                            return totalPrice;
                          }, 0)
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={selectedMealToPlan !== null}
        onHide={() => setSelectedMealToPlan(null)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add to Weekly Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb-3">{selectedMealToPlanName}</h4>
          <div className="row align-items-center justify-content-center mt-2">
            <div className="col-4">
              <label htmlFor="multiplier">
                <b>Multiplier:</b>
              </label>
            </div>
            <div className="col-8">
              <input
                type="number"
                id="multiplier"
                className="form-control my-1"
                placeholder="Enter new unit name"
                min="0"
                value={multiplyer}
                onChange={(e) =>
                  handleNewMultiplyer(parseFloat(e.target.value))
                }
              />
            </div>
            <div className="col-4">
       
            </div>
            <div className="col-8 my-1">
              
            </div>
            <div className="col-4">
              <label htmlFor="day">
                <b>Day:</b>
              </label>
            </div>
            <div className="col-8 my-1">
              <Select
                options={dayOptions}
                id="day"
                value={{
                  value: selectedMealDayValue,
                  label: selectedMealDayName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedMealDayValue(selectedOption.value);
                  setSelectedMealDayName(selectedOption.label);
                }}
              />
            </div>
            <div className="col-4">
              <label htmlFor="course">
                <b>Course:</b>
              </label>
            </div>
            <div className="col-8 my-1">
              <Select
                options={courseOptions}
                id="course"
                value={{
                  value: selectedMealCourseValue,
                  label: selectedMealCourseName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedMealCourseValue(selectedOption.value);
                  setSelectedMealCourseName(selectedOption.label);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedMealToPlan(null)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddMeal}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MealBrowser;
