import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";

function WeeklyPlan() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [recipes, setRecipes] = useState([]);
  const [meals, setMeals] = useState([]);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(false);
  const [multiplyer, setMultiplyer] = useState(1);
  const [mealMultiplyer, setMealMultiplyer] = useState(1);

  const [selectedMeal, setSelectedMeal] = useState([]);
  const [selectedMealLabel, setSelectedMealLabel] = useState("");
  const [selectedMealValue, setSelectedMealValue] = useState("");
  const [selectedMealServings, setSelectedMealServings] = useState("");

  const [selectedRecipe, setSelectedRecipe] = useState([]);
  const [selectedRecipeLabel, setSelectedRecipeLabel] = useState("");
  const [selectedRecipeValue, setSelectedRecipeValue] = useState("");
  const [selectedRecipeServings, setSelectedRecipeServings] = useState("");

  const [selectedDayValue, setSelectedDayValue] = useState("");
  const [selectedDayName, setSelectedDayName] = useState("");

  const [selectedMealDayValue, setSelectedMealDayValue] = useState("");
  const [selectedMealDayName, setSelectedMealDayName] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const [editMultiplyer, setEditMultiplyer] = useState(1);

  const [editSelectedRecipeId, setEditSelectedRecipeId] = useState("");
  const [editSelectedRecipe, setEditSelectedRecipe] = useState([]);
  const [editSelectedRecipeLabel, setEditSelectedRecipeLabel] = useState("");
  const [editSelectedRecipeValue, setEditSelectedRecipeValue] = useState("");
  const [editSelectedRecipeServings, setEditSelectedRecipeServings] =
    useState("");

  const [editSelectedDayValue, setEditSelectedDayValue] = useState("");
  const [editSelectedDayName, setEditSelectedDayName] = useState("");
  const [editSelectedCourseValue, setEditSelectedCourseValue] = useState("");
  const [editSelectedCourseName, setEditSelectedCourseName] = useState("");

  const [selectedCourseValue, setSelectedCourseValue] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");

  const [selectedMealCourseValue, setSelectedMealCourseValue] = useState("");
  const [selectedMealCourseName, setSelectedMealCourseName] = useState("");

  const [weeklyPlan, setWeeklyPlan] = useState([]);
  const [recipeIngredients, setRecipeIngredients] = useState([]);

  const [sundayTotalCalories, setSundayTotalCalories] = useState("");
  const [sundayTotalCost, setSundayTotalCost] = useState("");

  const [mondayTotalCalories, setMondayTotalCalories] = useState("");
  const [mondayTotalCost, setMondayTotalCost] = useState("");

  const [tuesdayTotalCalories, setTuesdayTotalCalories] = useState("");
  const [tuesdayTotalCost, setTuesdayTotalCost] = useState("");

  const [wednesdayTotalCalories, setWednesdayTotalCalories] = useState("");
  const [wednesdayTotalCost, setWednesdayTotalCost] = useState("");

  const [thursdayTotalCalories, setThursdayTotalCalories] = useState("");
  const [thursdayTotalCost, setThursdayTotalCost] = useState("");

  const [fridayTotalCalories, setFridayTotalCalories] = useState("");
  const [fridayTotalCost, setFridayTotalCost] = useState("");

  const [saturdayTotalCalories, setSaturdayTotalCalories] = useState("");
  const [saturdayTotalCost, setSaturdayTotalCost] = useState("");

  const [noneTotalCalories, setNoneTotalCalories] = useState("");
  const [noneTotalCost, setNoneTotalCost] = useState("");

  const [isFree, setIsFree] = useState(true);

  const filteredRecipesWithoutFree = recipes.filter(
    (recipe) => !onlyUserRecipes || recipe.user_id !== 1
  );

  let filteredRecipes = filteredRecipesWithoutFree;

  if (isFree) {
    const freeRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  }

  filteredRecipes.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { caseFirst: "false" })
  );

  const recipeOptions = filteredRecipes.map((recipe) => ({
    label: recipe.name,
    value: recipe.recipe_id,
    servings: recipe.servings,
  }));

  const mealOptions = meals.map((meal) => ({
    label: meal.meal_name,
    value: meal.id,
    notes: meal.meal_notes,
    tags: meal.meal_tags,
  }));

  const handleWeeklyPlanUpdate = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/api/add-weekly-plan-recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: selectedRecipe,
        meal_qty: multiplyer,
        day: selectedDayValue,
        meal: selectedCourseValue,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  // Function to fetch recipe details for the selected meal
  const fetchRecipeDetails = async (mealId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/meal-recipes/${mealId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      const details = await response.json();

      return details.length > 0 ? details : null; // Return null if details array is empty
    } else {
      throw new Error("Failed to fetch recipe details");
    }
  };

  const handleWeeklyPlanMealUpdate = async () => {
    try {
      // Fetch recipe details for the selected meal
      const recipeDetails = await fetchRecipeDetails(selectedMeal);

      // Check if recipeDetails is not null and is an array
      if (recipeDetails && Array.isArray(recipeDetails)) {
        // Execute all fetch requests concurrently using Promise.all

        await Promise.all(
          recipeDetails.map(async (recipeId) => {
            // Send POST request to add each recipe to the weekly plan

            const mealQty = mealMultiplyer * recipeId.meal_mutliplyer;

            await fetch(
              `${process.env.REACT_APP_API_URL}/api/add-weekly-plan-recipe`,
              {
                method: "POST",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  recipe_id: recipeId.id,
                  meal_qty: mealQty,
                  day: selectedMealDayValue,
                  meal: selectedMealCourseValue,
                }),
              }
            );
          })
        );
        fetchWeeklyPlan();
        setSelectedRecipe([]);
        setSelectedRecipeLabel("");
        setSelectedRecipeValue("");
        setSelectedMeal([]);
        setSelectedMealLabel("");
        setSelectedMealValue("");
      } else {
        throw new Error("No recipe details found for the selected meal");
      }
    } catch (error) {
      console.error("Error adding new units:", error);
    }
  };

  const handleEditWeeklyPlanUpdate = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/api/add-weekly-plan-recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: editSelectedRecipe,
        meal_qty: editMultiplyer,
        day: editSelectedDayValue,
        meal: editSelectedCourseValue,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          setShowEditModal(false);
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleShoppingListAdd = (id) => {
    console.log(`id: ${id}`);
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log("^^^");
        console.log(selectedRecipe);
        const processedData = data.map((ingredient) => {
          const ingredientId = ingredient.old_id.split(",")[0];

          // Determine if ingredient is custom
          const customIngredientFlag = ingredient.old_id.includes("user")
            ? 1
            : 0;
          // Modify the variables you need here
          fetch(
            `${process.env.REACT_APP_API_URL}/add-shopping-item-from-weekly-plan`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ingredient_id: ingredientId,
                custom_ingredient: customIngredientFlag,
                qty: multiplyer * ingredient.qty * ingredient.conversion_factor,
                day: selectedDayValue,
                meal: selectedCourseValue,
                for_recipe: selectedRecipe,
                original_id_string: ingredient.old_id,
              }),
            }
          )
            .then((res) => {
              if (res.ok) {
                fetchWeeklyPlan();
                setSelectedRecipe([]);
                setSelectedRecipeLabel("");
                setSelectedRecipeValue("");
                setSelectedMeal([]);
                setSelectedMealLabel("");
                setSelectedMealValue("");
              } else {
                throw new Error("Error adding new unit");
              }
            })
            .catch((err) => {
              console.error("Error adding new unit:", err);
            });
        });
      });
  };

  const handleShoppingListMealAdd = async (mealId) => {
    try {
      console.log("Fetching recipe IDs...");
      const recipeIdsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/meal-recipes/${mealId}`,
        {
          credentials: "include",
        }
      );
      if (!recipeIdsResponse.ok) {
        throw new Error("Failed to fetch recipe IDs for the selected meal");
      }
      const recipeIdsData = await recipeIdsResponse.json();

      console.log("Fetched recipe IDs:", recipeIdsData);

      for (const recipeIdData of recipeIdsData) {
        const recipeId = recipeIdData.id;

        console.log("Fetching ingredients for recipe ID:", recipeId);
        const ingredientsResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${recipeId}`,
          {
            credentials: "include",
          }
        );
        if (!ingredientsResponse.ok) {
          throw new Error(
            `Failed to fetch ingredients for recipe ID ${recipeId}`
          );
        }
        const ingredientsData = await ingredientsResponse.json();

        console.log(
          "Fetched ingredients for recipe ID:",
          recipeId,
          ingredientsData
        );

        const meal_specific_multi = recipeIdData.meal_mutliplyer;

        ingredientsData.forEach(async (ingredient) => {
          const ingredientId = ingredient.old_id.split(",")[0];
          const customIngredientFlag = ingredient.old_id.includes("user")
            ? 1
            : 0;

          console.log("Adding ingredient to shopping list:", ingredient);

          await fetch(
            `${process.env.REACT_APP_API_URL}/add-shopping-item-from-weekly-plan`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ingredient_id: ingredientId,
                custom_ingredient: customIngredientFlag,
                qty:
                  mealMultiplyer *
                  meal_specific_multi *
                  ingredient.qty *
                  ingredient.conversion_factor,
                day: selectedMealDayValue,
                meal: selectedMealCourseValue,
                for_recipe: recipeId,
                original_id_string: ingredient.old_id,
              }),
            }
          );
        });
      }
    } catch (error) {
      console.error("Error handling shopping list for meal:", error);
    }
  };

  const handleEditShoppingListAdd = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log("^^^");
        console.log(selectedRecipe);
        const processedData = data.map((ingredient) => {
          const ingredientId = ingredient.old_id.split(",")[0];

          // Determine if ingredient is custom
          const customIngredientFlag = ingredient.old_id.includes("user")
            ? 1
            : 0;
          // Modify the variables you need here
          fetch(
            `${process.env.REACT_APP_API_URL}/add-shopping-item-from-weekly-plan`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ingredient_id: ingredientId,
                custom_ingredient: customIngredientFlag,
                qty:
                  editMultiplyer *
                  ingredient.qty *
                  ingredient.conversion_factor,
                day: editSelectedDayValue,
                meal: editSelectedCourseValue,
                for_recipe: editSelectedRecipe,
                original_id_string: ingredient.old_id,
              }),
            }
          )
            .then((res) => {
              if (res.ok) {
                fetchWeeklyPlan();
              } else {
                throw new Error("Error adding new unit");
              }
            })
            .catch((err) => {
              console.error("Error adding new unit:", err);
            });
        });
      });
  };

  const dayOptions = [
    { value: "None", label: "None" },
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const courseOptions = [
    { value: "Other", label: "Other" },
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
    { value: "Appetizer", label: "Appetizer" },
    { value: "Snack", label: "Snack" },
    { value: "Dessert", label: "Dessert" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const dayMealOptions = [
    { value: "None", label: "None" },
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const courseMealOptions = [
    { value: "Other", label: "Other" },
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
    { value: "Appetizer", label: "Appetizer" },
    { value: "Snack", label: "Snack" },
    { value: "Dessert", label: "Dessert" },
  ].map((option) => ({ value: option.value, label: option.value }));

  //Handle Form Changes
  const handleSelectedRecipe = (selectedOption) => {
    setSelectedRecipe(selectedOption.value);
    setSelectedRecipeLabel(selectedOption.label);
    setSelectedRecipeValue(selectedOption.value);
    setSelectedRecipeServings(selectedOption.servings);
    console.log(selectedOption);
  };

  const [fullMealObject, setFullMealObject] = useState("");
  //Handle Form Changes
  const handleSelectedMeal = (selectedOption) => {
    setFullMealObject(selectedOption);
    setSelectedMeal(selectedOption.value);
    setSelectedMealLabel(selectedOption.label);
    setSelectedMealValue(selectedOption.value);
    setSelectedMealServings(selectedOption.servings);
    console.log(selectedOption);
    console.log(fullMealObject);
  };

  //Handle Edit Form Changes
  const handleEditSelectedRecipe = (selectedOption) => {
    setEditSelectedRecipe(selectedOption.value);
    setEditSelectedRecipeLabel(selectedOption.label);
    setEditSelectedRecipeValue(selectedOption.value);
    setEditSelectedRecipeServings(selectedOption.servings);
    console.log(selectedOption);
  };

  // New Qty:
  const handleNewMultiplyer = (mulitplyerQty) => {
    if (!isNaN(mulitplyerQty)) {
      setMultiplyer(mulitplyerQty);
    } else {
      setMultiplyer("");
    }
  };

  // Edit Qty:
  const handleEditMultiplyer = (mulitplyerQty) => {
    if (!isNaN(mulitplyerQty)) {
      setEditMultiplyer(mulitplyerQty);
    } else {
      setEditMultiplyer("");
    }
  };

  // New Qty:
  const handleNewMealMultiplyer = (mulitplyerQty) => {
    if (!isNaN(mulitplyerQty)) {
      setMealMultiplyer(mulitplyerQty);
    } else {
      setMealMultiplyer("");
    }
  };

  const fetchWeeklyPlan = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/weekly_plan_recipes`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setWeeklyPlan(data); // Check if data is loaded correctly

        const sundayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Sunday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setSundayTotalCalories(
          sundayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const sundayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Sunday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setSundayTotalCost(
          sundayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const mondayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Monday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setMondayTotalCalories(
          mondayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const mondayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Monday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setMondayTotalCost(
          mondayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        // Calculate total calories for and priceTuesday
        const tuesdayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Tuesday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setTuesdayTotalCalories(
          tuesdayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const tuesdayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Tuesday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setTuesdayTotalCost(
          tuesdayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const wednesdayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Wednesday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setWednesdayTotalCalories(
          wednesdayTotalCalories.toLocaleString(undefined, {
            useGrouping: true,
          })
        );

        const wednesdayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Wednesday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setWednesdayTotalCost(
          wednesdayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const thursdayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Thursday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setThursdayTotalCalories(
          thursdayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const thursdayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Thursday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setThursdayTotalCost(
          thursdayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const fridayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Friday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setFridayTotalCalories(
          fridayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const fridayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Friday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setFridayTotalCost(
          fridayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const saturdayTotalCalories = data.reduce((acc, item) => {
          if (item.day === "Saturday") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setSaturdayTotalCalories(
          saturdayTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const saturdayTotalCost = data.reduce((acc, item) => {
          if (item.day === "Saturday") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setSaturdayTotalCost(
          saturdayTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        const noneTotalCalories = data.reduce((acc, item) => {
          if (item.day === "None") {
            return acc + item.updated_total_calories;
          }
          return acc;
        }, 0);
        setNoneTotalCalories(
          noneTotalCalories.toLocaleString(undefined, { useGrouping: true })
        );

        const noneTotalCost = data.reduce((acc, item) => {
          if (item.day === "None") {
            return acc + item.updated_total_price;
          }
          return acc;
        }, 0);
        setNoneTotalCost(
          noneTotalCost.toLocaleString(undefined, { useGrouping: true })
        );

        console.log(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setOnlyUserRecipes(user.only_user_recipes === 1);
      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggleUserRecipes = () => {
    setOnlyUserRecipes(!onlyUserRecipes);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/only_user_recipes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        only_user_recipes: onlyUserRecipes ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipes`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        console.log(data);
        const removedHiddenRecipes = data.filter(
          (recipe) => recipe.hidden === 0
        );
        setRecipes(removedHiddenRecipes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Fetch Meal List
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/user_meals`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving meals from server");
        }
      })
      .then((data) => {
        console.log(data);
        const removedHiddenMeals = data.filter((meal) => meal.hidden === 0);
        setMeals(removedHiddenMeals);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleAddRecipe = () => {
    if (!selectedRecipeValue) {
      alert("Please select a recipe");
      return;
    }
    handleWeeklyPlanUpdate();
    handleShoppingListAdd(selectedRecipe);
  };

  const handleAddMeal = () => {
    if (!selectedMealValue) {
      alert("Please select a meal");
      return;
    }
    setFullMealObject("");
    handleWeeklyPlanMealUpdate();
    handleShoppingListMealAdd(selectedMeal);
  };

  const handleViewRecipe = (recipeId) => {
    window.location.href = `${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipeId}`;
  };

  const handleEditRecipe = async (recipe) => {
    setShowEditModal(true);
    setEditSelectedRecipeId(recipe);
    setEditMultiplyer(recipe.meal_qty);
    setEditSelectedRecipe(recipe.recipe_id);
    setEditSelectedRecipeLabel(recipe.name);
    setEditSelectedRecipeValue(recipe.recipe_id);
    setEditSelectedDayName(recipe.day);
    setEditSelectedDayValue(recipe.day);
    setEditSelectedCourseName(recipe.meal);
    setEditSelectedCourseValue(recipe.meal);

    // Fetch servings information using the recipe ID
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/recipes/servings/${recipe.recipe_id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch servings");
      }
      const servings = await response.json(); // Extract the JSON data from the response
      // Once servings are fetched, set the state
      setEditSelectedRecipeServings(servings.servings);
    } catch (error) {
      console.error("Error fetching servings:", error);
      // Handle error as needed
    }
  };

  const handleDeleteFromDay = async (recipe) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this reicpe "${recipe.name}"? This will also remove the ingredients from your shopping list.`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/remove_recipe_from_plan/`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rowId: recipe.id,
            recipeId: recipe.recipe_id,
            recipeDay: recipe.day,
            recipeMeal: recipe.meal,
          }),
        }
      );
      fetchWeeklyPlan();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateDeleteFromDay = async (recipe) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/remove_recipe_from_plan/`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rowId: recipe.id,
            recipeId: recipe.recipe_id,
            recipeDay: recipe.day,
            recipeMeal: recipe.meal,
          }),
        }
      );
      fetchWeeklyPlan();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Edit Meal
  const [showViewMealModal, setShowViewMealModal] = useState(false);

  const [editMealName, setEditMealName] = useState("");
  const [editMealNotes, setEditMealNotes] = useState("");
  const [editMealTags, setEditMealTags] = useState("");

  const handleCloseEditModal = () => {
    setShowViewMealModal(false);
  };

  const [selectedMealRecipes, setSelectedMealRecipes] = useState([
    { label: "", value: null },
  ]);

  const [selectedViewMealCourses, setSelectedViewMealCourses] = useState([]);
  const [selectedViewMealServings, setSelectedViewMealServings] = useState([]);

  const [selectedViewMealCalories, setSelectedViewMealCalories] = useState([]);
  const [selectedViewMealPrices, setSelectedViewMealPrices] = useState([]);
  const [selectedViewMealMultiplyer, setSelectedViewMealMultiplyer] = useState([
    1,
  ]);

  const handleMealClick = async (meal) => {
    setShowViewMealModal(true); // Show the edit modal
    setEditMealName(meal.label);
    setEditMealNotes(meal.notes);
    setEditMealTags(meal.tags);
    console.log("----");
    console.log(meal);

    // Fetch recipe details for the selected meal
    const details = await fetchRecipeDetails(meal.value);

    // Prepopulate selectedRecipes with existing recipe names if details is not null
    const prepopulatedRecipes =
      details &&
      details.map((recipe) => ({
        label: recipe.name,
        value: recipe.id,
      }));
    console.log("::::::");
    console.log(details);
    setSelectedMealRecipes(prepopulatedRecipes || []);

    const prepopulatedCourses =
      details && details.map((recipe) => recipe.course);
    setSelectedViewMealCourses(prepopulatedCourses || []);

    const prepopulatedServings =
      details && details.map((recipe) => recipe.servings);
    setSelectedViewMealServings(prepopulatedServings || []);

    const prepopulatedCalories =
      details && details.map((recipe) => recipe.total_calories);
    setSelectedViewMealCalories(prepopulatedCalories || []);

    const prepopulatedPrices =
      details && details.map((recipe) => recipe.total_price);
    setSelectedViewMealPrices(prepopulatedPrices || []);

    const prepopulatedMulti =
      details && details.map((recipe) => recipe.meal_mutliplyer);
    setSelectedViewMealMultiplyer(prepopulatedMulti || []);
  };

  const handleUpdateEntry = () => {
    handleUpdateDeleteFromDay(editSelectedRecipeId);
    handleEditWeeklyPlanUpdate();
    handleEditShoppingListAdd(editSelectedRecipeId.recipe_id);
  };

  useEffect(() => {
    setSelectedDayValue("None");
    setSelectedDayName("None");
    setSelectedCourseValue("Other");
    setSelectedCourseName("Other");
    setSelectedMealDayValue("None");
    setSelectedMealDayName("None");
    setSelectedMealCourseValue("Other");
    setSelectedMealCourseName("Other");
    fetchUserSetting();
    fetchWeeklyPlan();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div
        className="container rounded py-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}
        <div className="row align-items-top ms-5 mt-2">
          <h4>Add Recipe:</h4>
        </div>
        <div className="row align-items-top justify-content-center mt-2">
          <div className="col-sm-1">
            <div className="d-flex justify-content-center">
              <input
                type="number"
                className="form-control"
                placeholder="Enter new unit name"
                min="0"
                value={multiplyer}
                onChange={(e) =>
                  handleNewMultiplyer(parseFloat(e.target.value))
                }
              />
              {""}
            </div>
            <b>Mutli</b>
          </div>
          <div className="col-sm-3">
            <div>
              <Select
                isSearchable={true}
                options={recipeOptions}
                value={{
                  label: selectedRecipeLabel,
                  value: selectedRecipeValue,
                  servings: selectedRecipeServings,
                }}
                onChange={handleSelectedRecipe}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            </div>
            <b>Servings: </b>
            {(() => {
              const servings = parseFloat(multiplyer * selectedRecipeServings);
              if (Number.isInteger(servings)) {
                return servings.toString();
              } else {
                return servings
                  .toFixed(servings % 1 === 0.1 ? 1 : 2)
                  .replace(/(\.0|0+)$/, "");
              }
            })()}
          </div>

          <div className="col-sm-2">
            <div>
              <Select
                options={dayOptions}
                value={{
                  value: selectedDayValue,
                  label: selectedDayName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedDayValue(selectedOption.value);
                  setSelectedDayName(selectedOption.label);
                }}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />{" "}
            </div>
            <b>Day</b>
          </div>
          <div className="col-sm-2">
            <div>
              <Select
                options={courseOptions}
                value={{
                  value: selectedCourseValue,
                  label: selectedCourseName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedCourseValue(selectedOption.value);
                  setSelectedCourseName(selectedOption.label);
                }}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />{" "}
            </div>
            <b>Course</b>
          </div>
          <div className="col-sm-1">
            <Button variant="primary" onClick={handleAddRecipe}>
              Add Recipe
            </Button>
          </div>
          <div className="col-sm-2 mt-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"Only Your Recipes"}
                checked={onlyUserRecipes}
                onChange={handleToggleUserRecipes}
              />{" "}
            </div>
          </div>
        </div>
        <div className="row align-items-top ms-5 mt-2">
          <h4>Add Meal:</h4>
        </div>
        <div className="row align-items-top justify-content-center mt-2">
          <div className="col-sm-1">
            <div className="d-flex justify-content-center">
              <input
                type="number"
                className="form-control"
                placeholder="Enter new unit name"
                min="0"
                value={mealMultiplyer}
                onChange={(e) =>
                  handleNewMealMultiplyer(parseFloat(e.target.value))
                }
              />
              {""}
            </div>
            <b>Mutli</b>
          </div>
          <div className="col-sm-3">
            <div>
              <Select
                isSearchable={true}
                options={mealOptions}
                value={{
                  label: selectedMealLabel,
                  value: selectedMealValue,
                }}
                onChange={handleSelectedMeal}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
              {fullMealObject && (
                <a
                  href="#"
                  onClick={() => handleMealClick(fullMealObject)}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  View Meal
                </a>
              )}
            </div>
          </div>

          <div className="col-sm-2">
            <div>
              <Select
                options={dayMealOptions}
                value={{
                  value: selectedMealDayValue,
                  label: selectedMealDayName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedMealDayValue(selectedOption.value);
                  setSelectedMealDayName(selectedOption.label);
                }}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />{" "}
            </div>
            <b>Day</b>
          </div>
          <div className="col-sm-2">
            <div>
              <Select
                options={courseMealOptions}
                value={{
                  value: selectedMealCourseValue,
                  label: selectedMealCourseName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedMealCourseValue(selectedOption.value);
                  setSelectedMealCourseName(selectedOption.label);
                }}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />{" "}
            </div>
            <b>Course</b>
          </div>
          <div className="col-sm-1">
            <Button variant="primary" onClick={handleAddMeal}>
              Add Meal
            </Button>
          </div>
          <div className="col-sm-2 mt-2"></div>
        </div>
      </div>
      *total cals listed per serving
      <p>
        **Modifying existing entries may result in re-including ingredients on
        your shopping list, even if they were previously crossed off. However,
        if no items were previously crossed off, your shopping list remains
        unaffected by the changes.{" "}
      </p>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Sunday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {sundayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(sundayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsSundayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Sunday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundayLunch">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Sunday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Sunday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Sunday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" && recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Sunday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" && recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundaySnack">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Sunday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSundayOther">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Sunday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-sundayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-sunday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-sunday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-sunday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-sundayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Sunday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Monday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {mondayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(mondayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsMondayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Monday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondayLunch">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Monday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Monday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Monday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" && recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Monday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" && recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondaySnack">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Monday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsMondayOther">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Monday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-mondayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-monday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-monday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-monday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-mondayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Monday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* TUESDAY START */}
        {/* TUESDAY START */}
        {/* TUESDAY START */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Tuesday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {tuesdayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(tuesdayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsTuesdayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdayLunch">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" && recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" &&
                            recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdaySnack">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsTuesdayOther">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Tuesday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-tuesdayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-tuesday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-tuesday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-tuesday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-tuesdayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Tuesday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* TUESDAY END */}
        {/* TUESDAY END */}
        {/* TUESDAY END */}

        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Wednesday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {wednesdayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(wednesdayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsWednesdayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdayLunch">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdaySnack">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsWednesdayOther">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Wednesday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-wednesdayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-wednesday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-wednesday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-wednesday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-wednesdayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Wednesday" &&
                            recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Thursday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {thursdayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(thursdayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsThursdayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdayLunch">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" &&
                            recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" &&
                            recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdaySnack">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsThursdayOther">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Thursday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-thursdayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-thursday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-thursday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-thursday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-thursdayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Thursday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Friday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {fridayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(fridayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsFridayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Friday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridayLunch">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Friday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Friday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Friday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" && recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Friday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" && recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridaySnack">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Friday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsFridayOther">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "Friday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-fridayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-friday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-friday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-friday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-fridayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Friday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Saturday</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {saturdayTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(saturdayTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsSaturdayBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" &&
                            recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdayLunch">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdayAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" &&
                            recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdayDinner">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" &&
                            recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdayDessert">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" &&
                            recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdaySnack">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdaySnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdaySnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsSaturdayOther">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "Saturday" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-saturdayOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-saturday-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-saturday-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-saturday-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-saturdayOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "Saturday" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">None</h3>
              <div className="row">
                <div className="col-sm-6">
                  <b>Total Cals:</b> {noneTotalCalories}
                </div>
                <div className="col-sm-6">
                  <b>Total $:</b> ${parseFloat(noneTotalCost).toFixed(2)}
                </div>
              </div>
              <div className="accordion" id="accordionPanelsNoneBreakfast">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "None" && recipe.meal === "Breakfast"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneBreakfastHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-breakfast"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-breakfast"
                      >
                        Breakfast
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-breakfast"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneBreakfastHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Breakfast"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneLunch">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "None" && recipe.meal === "Lunch"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneLunchHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-lunch"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-lunch"
                      >
                        Lunch
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-lunch"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneLunchHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Lunch"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneAppetizer">
                {weeklyPlan.filter(
                  (recipe) =>
                    recipe.day === "None" && recipe.meal === "Appetizer"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneAppetizerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-appetizer"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-Appetizer"
                      >
                        Appetizer
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-appetizer"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneAppetizerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Appetizer"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneDinner">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "None" && recipe.meal === "Dinner"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneDinnerHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-dinner"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-dinner"
                      >
                        Dinner
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-dinner"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneDinnerHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Dinner"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneDessert">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "None" && recipe.meal === "Dessert"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneDessertHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-dessert"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-dessert"
                      >
                        Dessert
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-dessert"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneDessertHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Dessert"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneSnack">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "None" && recipe.meal === "Snack"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneSnackHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-snack"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-snack"
                      >
                        Snack
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-snack"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneSnackHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Snack"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="accordion" id="accordionPanelsNoneOther">
                {weeklyPlan.filter(
                  (recipe) => recipe.day === "None" && recipe.meal === "Other"
                ).length > 0 && (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="panelsStayOpen-noneOtherHeading"
                    >
                      <button
                        className="accordion-button text-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-none-other"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-none-other"
                      >
                        Other
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-none-other"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-noneOtherHeading"
                    >
                      {weeklyPlan
                        .filter(
                          (recipe) =>
                            recipe.day === "None" && recipe.meal === "Other"
                        )
                        .map((recipe) => (
                          <div className="accordion-body" key={recipe.id}>
                            {recipe.meal_qty}x <b>{recipe.name}</b> (
                            {Number.isInteger(recipe.updated_servings)
                              ? parseFloat(recipe.updated_servings).toFixed(0)
                              : parseFloat(recipe.updated_servings).toFixed(
                                  1
                                )}{" "}
                            servings){" "}
                            <Button
                              size="sm"
                              onClick={() => handleViewRecipe(recipe.recipe_id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditRecipe(recipe)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteFromDay(recipe)}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
        size="md"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title>Edit Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="container rounded py-2 mb-4"
            style={{ backgroundColor: "#444444", color: "white" }}
          >
            {" "}
            <div className="row align-items-top justify-content-left mt-2">
              <div className="col-sm-2">
                <div className="d-flex justify-content-center">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter new unit name"
                    min="0"
                    value={editMultiplyer}
                    onChange={(e) =>
                      handleEditMultiplyer(parseFloat(e.target.value))
                    }
                  />
                  {""}
                </div>
                <b>Mutli</b>
              </div>
              <div className="col-sm-6">
                <div>
                  <Select
                    isSearchable={true}
                    options={recipeOptions}
                    value={{
                      label: editSelectedRecipeLabel,
                      value: editSelectedRecipeValue,
                      servings: editSelectedRecipeServings,
                    }}
                    onChange={handleEditSelectedRecipe}
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                </div>
                <b>Servings: </b>
                {(() => {
                  const servings = parseFloat(
                    editMultiplyer * editSelectedRecipeServings
                  );
                  if (Number.isInteger(servings)) {
                    return servings.toString();
                  } else {
                    return servings
                      .toFixed(servings % 1 === 0.1 ? 1 : 2)
                      .replace(/(\.0|0+)$/, "");
                  }
                })()}
              </div>
              <div className="col-sm-4 mt-2">
                <div className="d-flex justify-content-left">
                  <Form.Switch
                    type="switch"
                    id="custom-switch"
                    label={"Only Your Recipes"}
                    checked={onlyUserRecipes}
                    onChange={handleToggleUserRecipes}
                  />{" "}
                </div>
              </div>
              <div className="row align-items-top justify-content-left mt-4">
                <div className="col-sm-5">
                  <div>
                    <Select
                      options={dayOptions}
                      value={{
                        value: editSelectedDayValue,
                        label: editSelectedDayName,
                      }}
                      isSearchable
                      onChange={(selectedOption) => {
                        setEditSelectedDayValue(selectedOption.value);
                        setEditSelectedDayName(selectedOption.label);
                      }}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />{" "}
                  </div>
                  <b>Day</b>
                </div>
                <div className="col-sm-7">
                  <div>
                    <Select
                      options={courseOptions}
                      value={{
                        value: editSelectedCourseValue,
                        label: editSelectedCourseName,
                      }}
                      isSearchable
                      onChange={(selectedOption) => {
                        setEditSelectedCourseValue(selectedOption.value);
                        setEditSelectedCourseName(selectedOption.label);
                      }}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />{" "}
                  </div>
                  <b>Course</b>
                </div>
              </div>
              <div className="col-sm-1 mt-4">
                <Button variant="primary" onClick={handleUpdateEntry}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* ======== */}
      {/* View Meal Modal */}
      <Modal show={showViewMealModal} onHide={handleCloseEditModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>View Selected Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="mb-2 col-3">
              <form>
                <div className="mb-2 col">
                  <label htmlFor="add-meal-name" className="form-label">
                    Meal Name:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder=""
                    value={editMealName}
                    readOnly // or disabled
                  />
                  <label htmlFor="add-meal-notes" className="form-label">
                    Meal Notes
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={editMealNotes}
                    readOnly // or disabled
                    rows="4"
                  />

                  <label htmlFor="add-meal-tags" className="form-label">
                    Meal Tags:
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder=""
                    value={editMealTags}
                    readOnly // or disabled
                  />
                </div>
              </form>
            </div>

            <div className="mb-2 col-9">
              <table className="table">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Recipe</th>
                    <th scope="col">Multiplier</th>
                    <th scope="col">Course</th>
                    <th scope="col">Servings</th>
                    <th scope="col">Calories Per Serving</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedMealRecipes.map((selectedRecipe, index) => {
                    if (!selectedRecipe) {
                      return null;
                    }

                    let newMultiServings;
                    if (!isNaN(selectedViewMealServings[index])) {
                      newMultiServings = `${(
                        (selectedViewMealServings[index] || 0) *
                        selectedViewMealMultiplyer[index]
                      ).toFixed(0)}`;
                    } else {
                      newMultiServings = 0;
                    }

                    // Calculate calories per serving with multiplier
                    let caloriesPerServing;
                    if (
                      !isNaN(selectedViewMealCalories[index]) &&
                      !isNaN(selectedViewMealServings[index]) &&
                      selectedViewMealServings[index] !== 0
                    ) {
                      caloriesPerServing = Math.round(
                        selectedViewMealCalories[index] /
                          selectedViewMealServings[index]
                      );
                    } else {
                      // If either selectedCalories[index] or selectedServings[index] is NaN or 0, set caloriesPerServing to 0
                      caloriesPerServing = 0;
                    }

                    let formattedPrice;
                    if (!isNaN(selectedViewMealPrices[index])) {
                      formattedPrice = `$${(
                        (selectedViewMealPrices[index] || 0) *
                        selectedViewMealMultiplyer[index]
                      ).toFixed(2)}`;
                    } else {
                      formattedPrice = 0;
                    }

                    return (
                      <tr key={index}>
                        <td className="col-5">
                          <b>{selectedRecipe.label}</b>
                        </td>
                        <td className="col-1 text-center">
                          {selectedViewMealMultiplyer[index]}
                        </td>
                        <td className="col-2 text-center">
                          {selectedViewMealCourses[index]}
                        </td>
                        <td className="col-1 text-center">
                          {newMultiServings}
                        </td>

                        <td className="col-2 text-center">
                          {caloriesPerServing}
                        </td>
                        <td className="col-1 text-center">{formattedPrice}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      <strong>
                        {selectedMealRecipes.reduce(
                          (totalCalories, _, index) => {
                            if (
                              selectedMealRecipes[index] &&
                              !isNaN(selectedViewMealCalories[index]) &&
                              !isNaN(selectedViewMealServings[index]) &&
                              selectedViewMealServings[index] !== 0
                            ) {
                              const caloriesPerServing = Math.round(
                                selectedViewMealCalories[index] /
                                  selectedViewMealServings[index]
                              );
                              totalCalories += caloriesPerServing; // Accumulate calories per serving
                            }
                            return totalCalories;
                          },
                          0
                        )}
                      </strong>
                    </td>
                    <td className="text-center">
                      <strong>
                        {/* Total price calculation remains the same */}$
                        {selectedViewMealPrices
                          .reduce((totalPrice, _, index) => {
                            if (selectedMealRecipes[index]) {
                              totalPrice +=
                                selectedViewMealPrices[index] *
                                selectedViewMealMultiplyer[index];
                            }
                            return totalPrice;
                          }, 0)
                          .toFixed(2)}
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default WeeklyPlan;
