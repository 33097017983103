import React from "react";

function GettingStarted() {
  return (
    <div>
      <h1>Welcome to Plate Passion!</h1>
      <p>
        If you need help with anything, please email{" "}
        <b>platepassioninfo@gmail.com</b>
      </p>
      <p>
        To get started, you'll need to create an account. Don't worry, it's
        quick and easy!
      </p>

      <p>
        Simply sign up for a free account! Once signed up, you can also choose
        from our premium plans: Bronze, Silver, or Gold.
      </p>

      <h2>Recipes</h2>

      <p>
        Once you're logged in, head over to the Recipes page to manage all your
        culinary creations!
      </p>

      <ul>
        <li>Add new recipes</li>
        <li>
          View your recipes in a list or as visually appealing recipe cards
        </li>
        <li>Edit existing recipes</li>
        <li>Search through all your recipes</li>
      </ul>

      <p>
        We've included a couple of example recipes to get you started. You can
        also toggle the "Only Your Recipes" option to see only the recipes
        you've created.
      </p>

      <h2>Editing Recipes</h2>

      <p>
        Want to customize your recipes to perfection? Plate Passion allows you
        to edit every aspect of your recipes!
      </p>

      <ul>
        <li>
          Personalize the recipe title, course, servings, and add descriptive
          tags for easier searching
        </li>
        <li>Upload a picture of your creation</li>
        <li>
          Select ingredients from our extensive database or add your own custom
          ingredients
        </li>
        <li>Add step-by-step instructions for preparing your recipe</li>
        <li>Toggle between metric and traditional measurement systems</li>
        <li>Define your preferred measurement system for each ingredient</li>
        <li>
          Utilize the tooltips for helpful hints by hovering over the question
          mark icons
        </li>
        <li>
          Enhance readability by color-coding ingredients and instructions
        </li>
      </ul>

      <h2>Weekly Plan</h2>

      <p>
        Take the stress out of meal planning with Plate Passion's Weekly Plan
        feature!
      </p>

      <ul>
        <li>
          Select recipes from your collection for each day and meal of the week
        </li>
        <li>Adjust serving sizes with the multiplier setting</li>
        <li>Easily navigate between recipes to view instructions</li>
        <li>Edit or move existing entries as needed</li>
        <li>
          When you add a recipe to your Weekly Plan, its ingredients are
          automatically added to your Shopping List
        </li>
        <li>
          Deleting an entry from your plan also removes its ingredients from
          your Shopping List
        </li>
      </ul>

      <h2>Shopping List</h2>

      <p>
        Never forget an ingredient again with Plate Passion's dynamic Shopping
        List!
      </p>

      <ul>
        <li>Automatically generated as you add recipes to your Weekly Plan</li>
        <li>
          Items from your Weekly Plan merge with existing added ingredients and
          non-cooking items
        </li>
        <li>Manually Add items for one-time needs</li>
        <li>
          Favorite items can be easily added to your list for quick access
        </li>
        <li>Check off items as you shop</li>
        <li>View recently crossed-off items for reference</li>
        <li>
          Save aisle locations with the "Edit Aisle" feature for efficient
          future shopping
        </li>
        <li>
          Toggle Edit Mode to adjust unit of measurement for items on your list
        </li>
      </ul>

      <h2>My Customizations</h2>

      <h3>Ingredients</h3>

      <p>Customize your ingredient database to suit your cooking needs!</p>

      <ul>
        <li>Add your own ingredients to the database</li>
        <li>
          Hide ingredients to prevent them from appearing in recipes or on your
          shopping list
        </li>
        <li>
          Default unit of measurement determines how the ingredient is initially
          displayed
        </li>
        <li>
          Add macro nutrients, price per unit, and aisle number (optional)
        </li>
        <li>Edit ingredients at any time</li>
        <li>
          Edit unit conversions for ingredients after adding them to recipes
        </li>
      </ul>

      <h3>Non-Cooking Items</h3>

      <p>
        Add non-cooking items to your database for comprehensive shopping lists!
      </p>

      <ul>
        <li>Add items like paper towels, napkins, foil, and more</li>
        <li>Set unit size, price, and aisle for each item</li>
      </ul>

      <h3>Shopping Aisles</h3>

      <p>Organize your shopping experience with customized aisles!</p>

      <ul>
        <li>Add aisles based on your store layout</li>
        <li>
          Order aisles to match the layout of your store for efficient shopping
        </li>
        <li>
          Move aisles up and down as needed to reflect your store's layout
        </li>
        <li>Once set, aisles likely won't need frequent editing</li>
      </ul>

      <h3>Unit of Measurement</h3>

      <p>Customize units of measurement to match your cooking preferences!</p>

      <ul>
        <li>Edit existing measurements or add your own custom units</li>
        <li>
          Standard measurements like cups, tablespoons, grams, pounds, and
          ounces are pre-included
        </li>
        <li>Add unique measurements like "8oz Container" or "Cracker"</li>
        <li>Hide units you're not using to streamline your options</li>
        <li>Hide only user-created units; standard units cannot be hidden</li>
        <li>Cannot delete units in use by ingredients</li>
      </ul>

      <h2>Membership Options</h2>

      <p>Unlock premium features with a Plate Passion membership!</p>

      <ul>
        <li>
          Choose from different membership tiers for access to exclusive
          features
        </li>
        <li>Unlimited recipe creation to fuel your culinary creativity</li>
        <li>
          Meet{" "}
          <a
            href={`${process.env.REACT_APP_CLIENT_URL}/about_remy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Remy
          </a>
          , our helpful chatbot, who can assist with recipe creation, execution,
          and improvement
        </li>
        <li>Enable automatic image generation for your recipes</li>
      </ul>

      <h2>Settings</h2>

      <p>Customize your Plate Passion experience!</p>

      <ul>
        <li>Silver and Gold members can make their recipes public</li>
        <li>Set a URL for others to view your recipe book</li>
        <li>
          Viewers are required to have a free account to access your public
          recipes
        </li>
      </ul>
    </div>
  );
}

export { GettingStarted };
