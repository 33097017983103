import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/style.scss";

function AdminUnits() {
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setIsAdmin(user.membership_level === "admin");
    } catch (error) {
      // Handle error
    }
  };

  const [units, setUnits] = useState([]);
  const [newUnitName, setNewUnitName] = useState("");
  const [newUnitType, setNewUnitType] = useState("metric");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [editUnitName, setEditUnitName] = useState("");
  const [editUnitType, setEditUnitType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortedColumn, setSortedColumn] = useState("unit_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedUnitToHide, setSelectedUnitToHide] = useState(null);

  const handleDeleteUnit = async (unit) => {
    const unit_split_id = unit.unit_id.substring(1);
    const confirmed = window.confirm(
      `Are you sure you want to delete the ingredient "${unit.unit_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/delete-default-unit/${unit_split_id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      fetchUnits();
    } catch (error) {
      console.error(error);
    }
  };


  const filteredUnits = units.filter(
    (unit) =>
      unit.unit_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      unit.unit_type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pagesCount = Math.ceil(filteredUnits.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const sortedUnits = filteredUnits.sort((a, b) => {
    if (sortDirection === "asc") {
      return a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase() ? 1 : -1;
    } else {
      return a[sortedColumn].toLowerCase() < b[sortedColumn].toLowerCase() ? 1 : -1;
    }
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleNewUnitNameChange = (event) => {
    setNewUnitName(event.target.value);
    setNewUnitType(event.target.dataset.unitType);
  };

  const fetchUnits = () => {
    fetch(`${process.env.REACT_APP_API_URL}/units`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Check if data is loaded correctly
        setUnits(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const handleAddUnit = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-default-unit`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        unitName: newUnitName.trim(),
        unitType: newUnitType.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchUnits();
          setNewUnitName("");
          setNewUnitType("metric");
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleSelectUnit = (unit) => {
    setSelectedUnit(unit);
    setEditUnitName(unit.unit_name);
    setEditUnitType(unit.unit_type);
  };

  const handleEditUnitNameChange = (event) => {
    setEditUnitName(event.target.value);
  };

  const handleEditUnitTypeChange = (event) => {
    setEditUnitType(event.target.value);
  };

  const handleSaveUnit = () => {
    // Send POST request to update unit
    const unit_split_id = selectedUnit.unit_id.substring(1);
    fetch(
      `${process.env.REACT_APP_API_URL}/edit-default-unit`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: unit_split_id,
          unitName: editUnitName.trim(),
          unitType: editUnitType.toLowerCase(),
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchUnits();
          setSelectedUnit(null);
          setEditUnitName("");
          setEditUnitType("");
        } else {
          throw new Error("Error updating unit");
        }
      })
      .catch((err) => {
        console.error("Error updating unit:", err);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchUserSetting();
    fetchUnits();
  }, []);

  const handleConfirmHideUnit = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/hide-unit?id=${selectedUnitToHide.unit_id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            id: selectedUnitToHide.unit_id,
            display: 0,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSelectedUnitToHide(null);
      // Reload your table data here to reflect the updated display status
      fetchUnits();
    } catch (error) {
      console.error(error);
      // Show error message to user
    }
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchUnits();
  }, []);

  if (!isAdmin) {
    return <p>Admin Only</p>;
  }

  return (
    <div>
      <div>
        <h2>Add Unit of Measurement</h2>
        <div className="mb-3 col-sm-4">
          <input
            type="text"
            className="form-control"
            placeholder="Enter new unit name"
            value={newUnitName}
            onChange={handleNewUnitNameChange}
            data-unit-type={newUnitType}
          />
          <select
            className="form-select mt-2"
            value={newUnitType}
            onChange={(event) => setNewUnitType(event.target.value)}
          >
            <option value="metric">Metric</option>
            <option value="traditional">Traditional</option>
            <option value="other">Other</option>
          </select>
          <button className="btn btn-secondary mt-2" onClick={handleAddUnit}>
            Add Unit
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Active Units of Measurement</h2>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4 ">
              <label htmlFor="page-size-select" className="mr-2">
                Show:
              </label>{" "}
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {pageSize}
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(25)}
                  >
                    25
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(50)}
                  >
                    50
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search units"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: pagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          currentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(currentPage - halfMaxPagesToShow, 0),
                pagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, pagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== pagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${currentPage === i && "active"}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          currentPage === pagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={handleNextPage}
          disabled={currentPage === pagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>

          </div>
          <div className="table-responsive">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("unit_name")}
                  >
                    Unit Name{" "}
                    {sortedColumn === "unit_name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "unit_name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("unit_type")}
                  >
                    Unit Type{" "}
                    {sortedColumn === "unit_type" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "unit_type" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("unit_source")}
                  >
                    Unit Source{" "}
                    {sortedColumn === "unit_source" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "unit_source" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredUnits.slice(startIndex, endIndex).map((unit) => (
                  <tr key={unit.unit_id}>
                    <td>
                      ({unit.unit_id}) {unit.unit_name}
                    </td>
                    <td className="text-center">{unit.unit_type}</td>
                    <td className="text-center">{unit.unit_source}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleSelectUnit(unit)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteUnit(unit)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          show={selectedUnit !== null}
          onHide={() => setSelectedUnit(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="edit-unit-name" className="form-label">
                  Unit Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-unit-name"
                  value={editUnitName}
                  onChange={handleEditUnitNameChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="edit-unit-type" className="form-label">
                  Unit Type
                </label>
                <select
                  className="form-select"
                  id="edit-unit-type"
                  value={editUnitType}
                  onChange={handleEditUnitTypeChange}
                >
                  <option value="metric">Metric</option>
                  <option value="traditional">Traditional</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedUnit(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveUnit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default AdminUnits;
