import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function AdminIngredients() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [inverseConversion, setInverseConversion] = useState(false);

  const handleToggleConversion = () => {
    setInverseConversion(!inverseConversion);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setIsAdmin(user.membership_level === "admin");
    } catch (error) {
      // Handle error
    }
  };

  // variable for unit options
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitOption, setSelectedUnitOption] = useState(null);

  // variables for adding new ingredient:
  const [showNewModal, setShowNewModal] = useState(false);
  const [newIngredientName, setNewIngredientName] = useState("");
  const [newUnitOfMeasurement, setNewUnitOfMeasurement] = useState("");
  const [newProtein, setNewProtein] = useState("");
  const [newCarbs, setNewCarbs] = useState("");
  const [newFat, setNewFat] = useState("");
  const [newFiber, setNewFiber] = useState("");
  const [newSugar, setNewSugar] = useState("");
  const [newCustomUnitFlag, setNewCustomUnitFlag] = useState(0);

  // variables for editing ingredient
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [editIngredientName, setEditIngredientName] = useState("");
  const [editUnitOfMeasurement, setEditUnitOfMeasurement] = useState("");
  const [editUnitOfMeasurementId, setEditUnitOfMeasurementId] = useState("");

  const [editProtein, setEditProtein] = useState("");
  const [editCarbs, setEditCarbs] = useState("");
  const [editFat, setEditFat] = useState("");
  const [editFiber, setEditFiber] = useState("");
  const [editSugar, setEditSugar] = useState("");
  const [editStoreAmount, setEditStoreAmount] = useState("");

  const [editCustomUnitFlag, setEditCustomUnitFlag] = useState(0);
  const [ingredientConversionList, setIngredientConversionList] = useState([]);
  const [addConversionInput, setAddConversionInput] = useState("");

  // variables for table settings (search, sort, paging)
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortedColumn, setSortedColumn] = useState("ingredient_name");
  const [sortDirection, setSortDirection] = useState("asc");

  // Variable
  const [selectedIngredientToHide, setSelectedIngredientToHide] =
    useState(null);
  // Variable
  const [selectedIngredientToUnhide, setSelectedIngredientToUnhide] =
    useState(null);

  // Variables for Editing Conversions
  const [selectedConversionToEdit, setSelectedConversionToEdit] =
    useState(null);
  const [selectedConversionToEditId, setSelectedConversionToEditId] =
    useState(null);
  const [editConversionUnitOfMeasurement, setEditConversionUnitOfMeasurement] =
    useState("");
  const [
    editConversionUnitOfMeasurementId,
    setEditConversionUnitOfMeasurementId,
  ] = useState("");
  const [editConversionCustomUnitFlag, setEditConversionCustomUnitFlag] =
    useState(0);
  const [editConversionFactor, setEditConversionFactor] = useState("");

  const [ingredients, setIngredients] = useState([]);

  const handleDeleteIngredient = async (ingredient) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the ingredient "${ingredient.ingredient_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/delete-default-ingredient/${ingredient.ingredient_id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      fetchIngredients();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditConversion = (conversion) => {
    setSelectedConversionToEdit(conversion);
    setSelectedConversionToEditId(conversion.id);
    setEditConversionUnitOfMeasurement(conversion.unit_name);
    setEditConversionUnitOfMeasurementId(conversion.unit_id);
    setEditConversionCustomUnitFlag(conversion.custom_unit);
    setEditConversionFactor(conversion.conversion_factor);
    console.log(conversion);
  };

  // fetch available unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/default-only-units`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setUnitOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  useEffect(() => {
    fetchUserSetting();
    // Fetch user units when component is mounted
    fetchIngredients();
  }, []);

  // Add New Unit Modal Show/Hide
  const handleAddClickModal = () => {
    setShowNewModal(true);
  };

  const handleAddCloseModal = () => {
    setShowNewModal(false);
  };

  // Active Table Setup
  //
  // Set up filter
  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.ingredient_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Set up pagnation
  const pagesCount = Math.ceil(filteredIngredients.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  // set up sorting
  const sortedUnits = filteredIngredients.sort((a, b) => {
    try {
      if (sortDirection === "asc") {
        return a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      } else {
        return a[sortedColumn].toLowerCase() < b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      }
    } catch {}
  });

  // handle sorting
  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  // handle page paging
  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  // handle page paging
  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  // Fetch Ingredients for Chart
  const fetchIngredients = () => {
    fetch(`${process.env.REACT_APP_API_URL}/default-only-ingredients`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIngredients(data);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  // Add New Unit Handle Form Changes
  const handleNewIngredientNameChange = (event) => {
    setNewIngredientName(event.target.value);
  };

  // New Unit Macros:
  const handleNewProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setNewProtein(newProteinValue);
    } else {
      setNewProtein(""); // Set a default value
    }
  };

  const handleNewCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setNewCarbs(newCarbsValue);
    } else {
      setNewCarbs(""); // Set a default value
    }
  };

  const handleNewFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setNewFat(newFatValue);
    } else {
      setNewFat(""); // Set a default value
    }
  };

  const handleNewFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setNewFiber(newFiberValue);
    } else {
      setNewFiber(""); // Set a default value
    }
  };

  const handleNewSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setNewSugar(newSugarValue);
    } else {
      setNewSugar(""); // Set a default value
    }
  };

  const handleAddIngredient = () => {
    // Check that required fields have a value
    if (!newIngredientName) {
      alert("Please enter an ingredient name.");
      return;
    }
    if (!newUnitOfMeasurement) {
      alert("Please select a unit of measurement for the ingredient.");
      return;
    }
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-default-ingredient`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientName: newIngredientName.trim(),
        unitOfMeasurement: newUnitOfMeasurement,
        protein: newProtein,
        carbs: newCarbs,
        fat: newFat,
        fiber: newFiber,
        sugar: newSugar,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchIngredients();
          setNewIngredientName("");
          setNewUnitOfMeasurement("");
          setNewProtein("");
          setNewCarbs("");
          setNewFat("");
          setNewFiber("");
          setNewSugar("");
          setNewCustomUnitFlag(0);
          handleAddCloseModal();
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  //
  // Editing Units
  //

  const handleAddConversionInput = (newConversionValue) => {
    if (!isNaN(newConversionValue)) {
      setAddConversionInput(newConversionValue);
    } else {
      setAddConversionInput(""); // Set a default value
    }
  };
  
  const handleEditConversionInput = (newConversionValue) => {
    if (!isNaN(newConversionValue)) {
      setEditConversionFactor(newConversionValue);
    } else {
      setEditConversionFactor(""); // Set a default value
    }
  };
  

  // Funciton to Fetch Unit Converions of the Selected Ingredient
  const fetchIngredientConversions = async (id) => {
    // Determine custom_ingredient flag
    const customIngredientFlag = 0;
    console.log(id);
    const ingredientId = id;

    // Make API call to retrieve unit conversions
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/ingredient-conversions/${ingredientId}?custom_ingredient=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(response.statusText);
      return [];
    }
  };

  const handleSelectIngredient = async (ingredient) => {
    setSelectedIngredient(ingredient);
    setEditIngredientName(ingredient.ingredient_name);
    setEditUnitOfMeasurement(ingredient.unit_name);
    setEditUnitOfMeasurementId(ingredient.default_unit_id);

    setEditProtein(ingredient.protein);
    setEditCarbs(ingredient.carbs);
    setEditFat(ingredient.fat);
    setEditFiber(ingredient.fiber);
    setEditSugar(ingredient.sugar);
    setEditCustomUnitFlag(ingredient.custom_unit);

    const data = await fetchIngredientConversions(ingredient.ingredient_id);
    setIngredientConversionList(data);
  };

  // Add Edit Unit Handle Form Changes
  const handleEditIngredientNameChange = (event) => {
    setEditIngredientName(event.target.value);
  };

  // Edit Unit Macros:
  const handleEditProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setEditProtein(newProteinValue);
    } else {
      setEditProtein(""); // Set a default value
    }
  };

  const handleEditCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setEditCarbs(newCarbsValue);
    } else {
      setEditCarbs(""); // Set a default value
    }
  };

  const handleEditFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setEditFat(newFatValue);
    } else {
      setEditFat(""); // Set a default value
    }
  };

  const handleEditFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setEditFiber(newFiberValue);
    } else {
      setEditFiber(""); // Set a default value
    }
  };

  const handleEditSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setEditSugar(newSugarValue);
    } else {
      setEditSugar(""); // Set a default value
    }
  };

  const handleSaveIngredient = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/update-default-ingredient`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedIngredient.ingredient_id,
        ingredientName: editIngredientName.trim(),
        unitOfMeasurement: editUnitOfMeasurementId,
        protein: editProtein,
        carbs: editCarbs,
        fat: editFat,
        fiber: editFiber,
        sugar: editSugar,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data

          setSelectedIngredient(null);
          setEditIngredientName("");
          setEditUnitOfMeasurement("");
          setEditUnitOfMeasurementId("");
          setEditProtein("");
          setEditCarbs("");
          setEditFat("");
          setEditFiber("");
          setEditSugar("");
          fetchIngredients();
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleAddUnitConversion = () => {
    if (!addConversionInput) {
      alert("Please add a conversion qty.");
      return;
    }

    const conversionFactor = inverseConversion
      ? 1 / addConversionInput
      : addConversionInput;
    const customIngredientFlag = 0;
    const ingredientId = selectedIngredient.ingredient_id;

    // Check for existing unit conversion
    fetch(
      `${process.env.REACT_APP_API_URL}/api/check-unit-conversion?ingredientId=${ingredientId}&newUnitOfMeasurement=${newUnitOfMeasurement}&newCustomUnitFlag=${newCustomUnitFlag}&customIngredientFlag=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          // No existing conversion found, continue with adding a new conversion
          return fetch(
            `${process.env.REACT_APP_API_URL}/api/add-unit-conversion`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customIngredientFlag: customIngredientFlag,
                ingredientId: ingredientId,
                newUnitOfMeasurement: newUnitOfMeasurement,
                newCustomUnitFlag: newCustomUnitFlag,
                addConversionInput: conversionFactor,
              }),
            }
          );
        } else {
          // Existing conversion found, display error message
          const error =
            "Duplicate Entry - You already have an existing conversion for this unit";
          throw new Error(error);
        }
      })
      .then((response) => response.json())
      .then((data) => {
        //Fetch the updated ingredient conversions after successful addition
        fetchIngredientConversions(selectedIngredient.ingredient_id)
          .then((data) => setIngredientConversionList(data))
          .catch((error) => console.error(error));
        //Clear the addConversionInput field
        setAddConversionInput("");
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  };

  // Update Unit Conversion
  const handleUpdateUnitConversion = async () => {
    
    try {
      const conversionFactor = inverseConversion
      ? 1 / editConversionFactor
      : editConversionFactor;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/${selectedConversionToEditId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            unit_id: editConversionUnitOfMeasurementId,
            custom_unit: editConversionCustomUnitFlag,
            conversion_factor: conversionFactor,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Update the state with the new values
      setSelectedConversionToEdit({
        id: selectedConversionToEditId,
        unit_id: editConversionUnitOfMeasurementId,
        custom_unit: editConversionCustomUnitFlag,
        conversion_factor: editConversionFactor,
      });

      // Clear the form fields
      fetchIngredientConversions(selectedIngredient.ingredient_id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
      setSelectedConversionToEdit(null);
      setEditConversionUnitOfMeasurement("");
      setEditConversionUnitOfMeasurementId("");
      setEditConversionCustomUnitFlag(0);
      setEditConversionFactor("");
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Delete Conversion
  const handleDeleteConversion = async (conversion) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the conversion "${conversion.unit_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/delete/${conversion.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Remove the deleted conversion from the ingredientConversionList state
      fetchIngredientConversions(selectedIngredient.ingredient_id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchIngredients();
  }, []);

  if (!isAdmin) {
    return <p>Admin Only</p>;
  }

  return (
    <div>
      <div>
        <h2>Add Default Ingredient</h2>
        <div className="mb-3 col-sm-4">
          <button
            className="btn btn-secondary mt-2"
            onClick={handleAddClickModal}
          >
            Add Ingredient
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Default Ingredients</h2>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4 ">
              <label htmlFor="page-size-select" className="mr-2">
                Show:
              </label>{" "}
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {pageSize}
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(25)}
                  >
                    25
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(50)}
                  >
                    50
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search units"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: pagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          currentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(currentPage - halfMaxPagesToShow, 0),
                pagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, pagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== pagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${currentPage === i && "active"}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          currentPage === pagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={handleNextPage}
          disabled={currentPage === pagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>

          </div>
          <div className="table-responsive">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("ingredient_name")}
                  >
                    Ingredient{" "}
                    {sortedColumn === "ingredient_name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "ingredient_name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("unit_id")}
                  >
                    Base Unit{" "}
                    {sortedColumn === "unit_id" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "unit_id" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("calories")}
                  >
                    Calories{" "}
                    {sortedColumn === "calories" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "calories" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("protein")}
                  >
                    Protein{" "}
                    {sortedColumn === "protein" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "protein" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("carbs")}
                  >
                    Carbs{" "}
                    {sortedColumn === "carbs" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "carbs" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("fat")}
                  >
                    Fat{" "}
                    {sortedColumn === "fat" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "fat" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("fiber")}
                  >
                    Fiber{" "}
                    {sortedColumn === "fiber" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "fiber" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("sugar")}
                  >
                    Sugar{" "}
                    {sortedColumn === "sugar" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "sugar" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>

                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredIngredients
                  .slice(startIndex, endIndex)
                  .map((ingredient) => (
                    <tr key={ingredient.ingredient_id}>
                      <td>
                        ({ingredient.ingredient_id}){" "}
                        {ingredient.ingredient_name}
                      </td>
                      <td className="text-center">{ingredient.unit_name}</td>
                      <td className="text-center">
                        {Number.isInteger(ingredient.calories)
                          ? parseFloat(ingredient.calories).toFixed(0)
                          : parseFloat(ingredient.calories).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.protein)
                          ? parseFloat(ingredient.protein).toFixed(0)
                          : parseFloat(ingredient.protein).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.carbs)
                          ? parseFloat(ingredient.carbs).toFixed(0)
                          : parseFloat(ingredient.carbs).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.fat)
                          ? parseFloat(ingredient.fat).toFixed(0)
                          : parseFloat(ingredient.fat).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.fiber)
                          ? parseFloat(ingredient.fiber).toFixed(0)
                          : parseFloat(ingredient.fiber).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.sugar)
                          ? parseFloat(ingredient.sugar).toFixed(0)
                          : parseFloat(ingredient.sugar).toFixed(1)}
                      </td>

                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleSelectIngredient(ingredient)}
                        >
                          Edit
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteIngredient(ingredient)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* -------------------- */}
        {/* Add Ingredient Modal */}
        {/* -------------------- */}
        <Modal show={showNewModal} onHide={handleAddCloseModal} size="lg">
          <ModalHeader closeButton>
            <ModalTitle>Add Ingredient</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="mb-2 col-sm">
                <label htmlFor="add-ingredient-name" className="form-label">
                  Ingredient Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new ingredient name"
                  value={newIngredientName}
                  onChange={handleNewIngredientNameChange}
                />
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <label htmlFor="add-base-unit" className="form-label">
                    Base Unit of Measurement
                  </label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (unit) => unit.unit_id === newUnitOfMeasurement
                    )}
                    onChange={(selectedOption) => {
                      setNewUnitOfMeasurement(selectedOption.unit_id);
                      setNewCustomUnitFlag(1);
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-protein" className="form-label">
                    Protein (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-protein"
                    min="0"
                    value={newProtein}
                    onChange={(e) =>
                      handleNewProteinChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-carbs" className="form-label">
                    Carbs (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-carbs"
                    min="0"
                    value={newCarbs}
                    onChange={(e) =>
                      handleNewCarbsChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-fat" className="form-label">
                    Fat (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-fat"
                    min="0"
                    value={newFat}
                    onChange={(e) =>
                      handleNewFatChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-fiber" className="form-label">
                    Fiber (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-fiber"
                    min="0"
                    value={newFiber}
                    onChange={(e) =>
                      handleNewFiberChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-sugar" className="form-label">
                    Sugar (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-sugar"
                    min="0"
                    value={newSugar}
                    onChange={(e) =>
                      handleNewSugarChange(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddIngredient}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* --------------------- */}
        {/* Edit Ingredient Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedIngredient !== null}
          onHide={() => setSelectedIngredient(null)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Ingredient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-2 col-sm">
                <label htmlFor="edit-ingredient-name" className="form-label">
                  Ingredient Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={editIngredientName}
                  onChange={handleEditIngredientNameChange}
                />
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <label htmlFor="edit-base-unit" className="form-label">
                    Base Unit of Measurement
                  </label>
                  <Select
                    options={unitOptions}
                    value={{
                      unit_id: editUnitOfMeasurementId,
                      unit_name: editUnitOfMeasurement,
                    }}
                    onChange={(selectedOption) => {
                      setEditUnitOfMeasurement(selectedOption.unit_name);
                      setEditCustomUnitFlag(0);

                      setEditUnitOfMeasurementId(selectedOption.unit_id);
                      setEditUnitOfMeasurement(selectedOption.unit_name);
                      setEditCustomUnitFlag(1);
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-protein" className="form-label">
                    Protein (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-protein"
                    min="0"
                    value={editProtein !== null ? editProtein : ""}
                    onChange={(e) =>
                      handleEditProteinChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-carbs" className="form-label">
                    Carbs (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-carbs"
                    min="0"
                    value={editCarbs !== null ? editCarbs : ""}
                    onChange={(e) =>
                      handleEditCarbsChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-fat" className="form-label">
                    Fat (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-fat"
                    min="0"
                    value={editFat !== null ? editFat : ""}
                    onChange={(e) =>
                      handleEditFatChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-fiber" className="form-label">
                    Fiber (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-fiber"
                    min="0"
                    value={editFiber !== null ? editFiber : ""}
                    onChange={(e) =>
                      handleEditFiberChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-sugar" className="form-label">
                    Sugar (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-sugar"
                    min="0"
                    value={editSugar !== null ? editSugar : ""}
                    onChange={(e) =>
                      handleEditSugarChange(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>
            </form>
            <Button
              variant="secondary"
              onClick={() => setSelectedIngredient(null)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveIngredient}>
              Save Changes
            </Button>
            <hr />
            <h4>Edit Unit Conversions</h4>

            <div className="row">
              <div
                className="col"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Switch
                  type="switch"
                  id="custom-switch"
                  label={"Inverse Conversion"}
                  checked={inverseConversion}
                  onChange={handleToggleConversion}
                />{" "}
                <OverlayTrigger
                  delay={{ hide: 150, show: 150 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Toggle this to change the conversion statement below to
                      make inputs easier. Eg, swithes "How many Serving(s) in 1x
                      Cup(s)" to "How many Cup(s) in 1x Serving(s)".
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                </OverlayTrigger>{" "}
              </div>
              <div className="row">
                <div className="mb-3 col-sm-4">
                  <label htmlFor="add-base-unit" className="form-label">
                    Add unit to convert to:
                  </label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (unit) => unit.unit_id === newUnitOfMeasurement
                    )}
                    onChange={(selectedOption) => {
                      setNewCustomUnitFlag(0);
                      setSelectedUnitOption(selectedOption.unit_name);
                      setNewUnitOfMeasurement(selectedOption.unit_id);
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                  />
                </div>

                <div className="mb-3 col-sm-5">
                  {inverseConversion ? (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is true */}
                      How many <b>{selectedUnitOption}</b> in 1x{" "}
                      <b>{editUnitOfMeasurement}</b>
                    </label>
                  ) : (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is false */}
                      How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                      <b>{selectedUnitOption}</b>
                    </label>
                  )}
                  <input
                    type="number"
                    className="form-control"
                    id="add-conversion-input"
                    min="0"
                    value={addConversionInput}
                    onChange={(e) =>
                      handleAddConversionInput(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>
              <div className="mb-3 col-sm-2 d-flex align-items-end">
                <Button variant="primary" onClick={handleAddUnitConversion}>
                  Add
                </Button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Unit Conversion</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {ingredientConversionList.map((conversion) => (
                    <tr key={conversion.id}>
                      <td>{`${conversion.unit_name} (${conversion.conversion_factor})`}</td>
                      <td>
                        {" "}
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleEditConversion(conversion)}
                        >
                          Edit
                        </Button>
                      </td>
                      <td>
                        {" "}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteConversion(conversion)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
        {/* --------------------- */}
        {/* Edit Conversion Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedConversionToEdit !== null}
          onHide={() => setSelectedConversionToEdit(null)}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Conversion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
                <div
                  className="col"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Form.Switch
                    type="switch"
                    id="custom-switch"
                    label={"Inverse Conversion"}
                    checked={inverseConversion}
                    onChange={handleToggleConversion}
                  />{" "}
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Toggle this to change the conversion statement below to
                        make inputs easier. Eg, swithes "How many Serving(s) in
                        1x Cup(s)" to "How many Cup(s) in 1x Serving(s)".
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>{" "}
                  </div>
              </div>
            <div className="row">
              <div className="mb-3 col-sm-4">
              <label htmlFor="add-base-unit" className="form-label">
                    Edit unit to convert to:
                  </label>

                <Select
                  options={unitOptions}
                  value={{
                    unit_id: editConversionUnitOfMeasurementId,
                    unit_name: editConversionUnitOfMeasurement,
                  }}
                  onChange={(selectedOption) => {
                    setEditConversionUnitOfMeasurementId(
                      selectedOption.unit_id
                    );

                    setEditConversionUnitOfMeasurement(
                      selectedOption.unit_name
                    );
                    setEditConversionCustomUnitFlag(0);

                    setEditConversionUnitOfMeasurementId(
                      selectedOption.unit_id
                    );
                    setEditConversionUnitOfMeasurement(
                      selectedOption.unit_name
                    );
                    setEditConversionCustomUnitFlag(1);
                  }}
                  getOptionLabel={(option) => option.unit_name}
                  getOptionValue={(option) => option.unit_id}
                  isSearchable={true}
                />
              </div>

              <div className="mb-3 col-sm-5">
                                  
              {inverseConversion ? (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is true */}
                      How many <b>{editConversionUnitOfMeasurement}</b> in 1x{" "}
                      <b>{editUnitOfMeasurement}</b>
                    </label>
                  ) : (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is false */}
                      How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                      <b>{editConversionUnitOfMeasurement}</b>
                    </label>
                  )}
                <input
                  type="number"
                  className="form-control"
                  id="add-conversion-input"
                  min="0"
                  value={editConversionFactor}
                  onChange={(e) =>
                    handleEditConversionInput(parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="mb-3 col-sm-2 d-flex align-items-end">
                <Button variant="primary" onClick={handleUpdateUnitConversion}>
                  Update
                </Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedIngredientToUnhide(null)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default AdminIngredients;
