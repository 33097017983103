// src/Tiptap.jsx

import React, { useCallback, useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Button, ButtonGroup, Form } from "react-bootstrap"; // Assuming you're using react-bootstrap in your project
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import FontFamily from "@tiptap/extension-font-family";
import { Color } from "@tiptap/extension-color";
import TextAlign from "@tiptap/extension-text-align";
import "../styles/Tiptap.css";
import "../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faItalic,
  faUnderline,
  faListUl,
  faListOl,
  faHeading,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faLink,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";

const Tiptap = ({ initialContent, onUpdateContent, editingStatus }) => {
  const [editable, setEditable] = useState(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Color,
      FontFamily,
      Underline,
      Link.configure({
        openOnClick: true,
        autolink: true,
      }),
    ],
    content: initialContent,
    editable,
  });

  useEffect(() => {
    if (!editor) return;

    const updateHandler = () => {
      const html = editor.getHTML();
      onUpdateContent(html); // Correctly uses the passed down function to update parent state
    };

    editor.on("update", updateHandler);

    editor.setEditable(editable);

    return () => {
      editor.off("update", updateHandler);
    };
  }, [editor, editable]);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  if (!editor) {
    return null;
  }

  return (
    <div>
      <>
      {editingStatus && (
            <div>
  <div>
  <Form.Switch
      type="switch"
      id="editable-switch"
      label="Edit"
      checked={editable}
      onChange={(event) => setEditable(event.target.checked)}
    />
  </div>
  {editable && (
        <ButtonGroup className="mb-2">
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            variant={editor.isActive("bold") ? "primary" : "light"}
          >
            <FontAwesomeIcon icon={faBold} />{" "}
            {/* Replace "Bold" text with icon */}
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            variant={editor.isActive("italic") ? "primary" : "light"}
          >
            <FontAwesomeIcon icon={faItalic} />{" "}
            {/* Replace "Italic" text with icon */}
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            variant={editor.isActive("underline") ? "primary" : "light"}
          >
            <FontAwesomeIcon icon={faUnderline} />{" "}
            {/* Replace "Underline" text with icon */}
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            variant={editor.isActive("bulletList") ? "primary" : "light"}
          >
            <FontAwesomeIcon icon={faListUl} />{" "}
            {/* Replace "Bullets" text with icon */}
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            variant={editor.isActive("orderedList") ? "primary" : "light"}
          >
            <FontAwesomeIcon icon={faListOl} />{" "}
            {/* Replace "Ordered List" text with icon */}
          </Button>
          <Button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            variant={
              editor.isActive("heading", { level: 1 }) ? "primary" : "light"
            }
          >
            <b>H1</b>
          </Button>
          <Button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            variant={
              editor.isActive("heading", { level: 2 }) ? "primary" : "light"
            }
          >
            <b>H2</b>
          </Button>
          <Button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            variant={
              editor.isActive("heading", { level: 3 }) ? "primary" : "light"
            }
          >
            <b>H3</b>
          </Button>
          <Button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
            variant={
              editor.isActive("heading", { level: 4 }) ? "primary" : "light"
            }
          >
            <b>H4</b>
          </Button>

          <button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            className={
              editor.isActive({ textAlign: "left" }) ? "is-active" : ""
            }
          >
            <FontAwesomeIcon icon={faAlignLeft} /> {/* left */}
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            className={
              editor.isActive({ textAlign: "center" }) ? "is-active" : ""
            }
          >
            <FontAwesomeIcon icon={faAlignCenter} /> {/* center */}
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            className={
              editor.isActive({ textAlign: "right" }) ? "is-active" : ""
            }
          >
            <FontAwesomeIcon icon={faAlignRight} /> {/* right */}
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("justify").run()}
            className={
              editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
            }
          >
            <FontAwesomeIcon icon={faAlignJustify} /> {/* justify */}
          </button>

          <button
            onClick={setLink}
            className={`btn ${
              editor.isActive("link") ? "btn-primary" : "btn-light"
            }`}
          >
            <FontAwesomeIcon icon={faLink} /> {/* setLink */}
          </button>

          <button
            onClick={() => editor.chain().focus().unsetLink().run()}
            disabled={!editor.isActive("link")}
            className="btn btn-light" // Apply Bootstrap classes
          >
            <FontAwesomeIcon icon={faUnlink} /> {/* unsetLink */}
          </button>
        </ButtonGroup>
         )}
        </div>
)}
        <EditorContent editor={editor} />
      </>
    </div>
  );
};

export default Tiptap;
