import React, { useState, useEffect, useRef } from "react";
import "../styles/style.scss";
import "../styles/ChatBot.css";
import ReactMarkdown from "react-markdown";

function FullChatBot(props) {
  const [expanded, setExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [chatInput, setChatInput] = useState("");

  const recipeIngredients = props.recipeIngredients;
  const recipeData = props.recipeData;
  const recipeInstructions = props.recipeInstructions;

  const ingredientList = recipeIngredients.map(
    (ingredient) =>
      `${ingredient.qty} ${ingredient.unit_name} ${ingredient.name} ${ingredient.suffix}`
  );
  const instructionList = recipeInstructions.map(
    (instruction, index) => `${index + 1} - ${instruction.instruction}`
  );

  const [conversation, setConversation] = useState([]);
  const chatBodyRef = useRef(null); // Ref to the chat body container

  const [subTokens, setSubTokens] = useState(0);
  const [extraTokens, setExtraTokens] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState("");

  const handleInputChange = (event) => {
    setChatInput(event.target.value);
    adjustTextarea(event.target);
  };

  const adjustTextarea = (element) => {
    element.style.height = "auto";
    element.style.height = `${Math.min(element.scrollHeight, 3 * 20)}px`;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && !isLoading) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setConversation((prevMessages) => [
      ...prevMessages,
      { role: "user", content: chatInput },
    ]);
    setIsLoading(true);
  };

  // Function to fetch user tokens
  const fetchUserTokens = () => {
    fetch(`${process.env.REACT_APP_API_URL}/get-user-tokens`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user tokens");
        }
        return response.json();
      })
      .then((data) => {
        setSubTokens(data[0].subscription_tokens);
        setExtraTokens(data[0].extra_tokens);
      })
      .catch((error) => {
        console.error("Error fetching user tokens:", error.message);
      });
  };

  useEffect(() => {
    // Fetch user tokens when the component mounts
    fetchUserTokens();

    // Cleanup function
    return () => {
      // Cleanup code (if needed)
    };
  }, []);

  useEffect(() => {
    try {
      const lastMessage = conversation[conversation.length - 1];
      if (lastMessage && lastMessage.role === "user") {
        // run your desired logic here
        console.log("Running");
        handleSubmit2();
      }
    } catch (error) {
      console.error(error);
    }
  }, [conversation]);

  // Functions to handle chatbot interactions
  async function handleSubmit2() {
    const recipeData = props.recipeData;
    const message = chatInput;

    // Add user message to messages array
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, isUser: true },
    ]);

    // Send user input to backend server
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/chatbot`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          recipeId: recipeData.id, // pass the recipeId
          message: message,
          conversation: conversation,
          subTokens: subTokens,
          extraTokens: extraTokens,
        }),
      }
    );

    // Convert response to text
    const data = await response.json();

    if (response.ok) {
      // If response is successful, update messages and conversation
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.botResponse, isUser: false },
      ]);
      setConversation((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: data.botResponse },
      ]);
      setIsLoading(false);
      fetchUserTokens(); // Fetch updated token counts
      setChatInput(""); // Clear input field
    } else {
      // If response is not successful, display error message to user
      setChatInput(""); // Clear input field
      setErrorMessage(data.error);
      setIsLoading(false);
      fetchUserTokens(); // Fetch updated token counts
    }
  }

  function toggleFullScreen() {
    setExpanded((prevExpanded) => !prevExpanded);
  }

  function toggleHide() {
    setHidden((prevHidden) => !prevHidden);
    setExpanded(false);
  }

  useEffect(() => {
    const loadConversation = async () => {
      try {
        // Fetch history from the backend
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat_history`,
          {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              recipeId: recipeData.id,
            }),
          }
        );

        if (response.ok) {
          const previousConversation = await response.json(); // conversation from backend
          if (previousConversation) {
            setConversation(
              previousConversation.filter((msg) => msg.role !== "system")
            );
            const messageHistory = previousConversation
              .filter((msg) => msg.role !== "system")
              .map((convMsg) => ({
                text: convMsg.content,
                isUser: convMsg.role === "user",
              }));
            setMessages(messageHistory);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    // only run the effect if recipeData.id is truthy (i.e., not null, not undefined)
    if (recipeData.id) {
      loadConversation();
    }
  }, [recipeData.id]); // dependency array includes recipeData.id

  const updateSystemMessage = () => {
    const updatedSystemMessage = `You are Remy. My personal chef assistant. We are currently looking at this recipe: Recipe Name: "${recipeData.name}". Recipe Course: "${recipeData.course}". Ingredients: "${ingredientList}". The instructions are "${instructionList}". Respond with Markdown. Be Friendly! Don't be surpised if the recipe changes because users have the ability to edit things within these recipes. You do not need to "reread" the recipe to the user because they can already see the name, course, ingredients, and instructions. Repeating them exactly as they is not helpful. Do NOT let users ask you any other questions unrelated to cooking.`;

    const newConversation = [...conversation];

    if (newConversation.length && newConversation[0].role === "system") {
      newConversation[0].content = updatedSystemMessage;
    } else {
      newConversation.unshift({
        role: "system",
        content: updatedSystemMessage,
      });
    }

    setConversation(newConversation);
  };

  useEffect(() => {
    updateSystemMessage();
  }, [recipeData, recipeIngredients, recipeInstructions]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  useEffect(() => {
    if (errorMessage) {
      // If errorMessage exists, show the notification
      setShowNotification(true);
      setNotificationContent(errorMessage);

      // After 5 seconds, hide the notification
      const timeout = setTimeout(() => {
        setShowNotification(false);
        setErrorMessage(null); // Clear errorMessage after hiding the notification
      }, 5000);

      // Clear timeout to prevent memory leak
      return () => clearTimeout(timeout);
    }
  }, [errorMessage]);

  return (
    <div
      style={{
        position: "absolute",
        right: "10px",
        top: "-45px",
      }}
    >
      {hidden ? (
        <button className="btn btn-primary" onClick={toggleHide}>
          Unhide
        </button>
      ) : (
        <div>
          <div className={`full-chatbot ${expanded ? "expanded" : ""}`}>
            <div className="chat-header d-flex align-items-center justify-content-between">
              <div className="right-content">
                <div className="row gx-3 align-items-center">
                  <div className="col text-center">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={toggleFullScreen}
                    >
                      Toggle
                      <br />
                      Size
                    </button>
                  </div>
                  <div className="col text-center">
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      <span className="fw-bold" style={{ display: "block" }}>
                        Subscription
                        <br />
                        Tokens:
                      </span>
                      <span
                        id="subscriptionTokens"
                        style={{ fontSize: "12px" }}
                      >
                        {subTokens}
                      </span>
                    </p>
                  </div>
                  <div className="col text-center">
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      <span className="fw-bold" style={{ display: "block" }}>
                        Extra
                        <br />
                        Tokens:
                      </span>
                      <span id="extraTokens" style={{ fontSize: "12px" }}>
                        {extraTokens}
                      </span>
                    </p>
                  </div>
                  <div className="col text-center">
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      <span className="fw-bold" style={{ display: "block" }}>
                        <a
                          href={`${process.env.REACT_APP_CLIENT_URL}/about_remy`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          About
                          <br />
                          Remy
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Add ref to the chat body container */}
            <div className="chat-body" ref={chatBodyRef}>
              {/* Render messages */}

              <div className="bot-message1">
                <span>
                  Hi! I'm Remy, your personal chef assistant! How can I help?
                </span>
              </div>
              <>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={message.isUser ? "user-message" : "bot-message"}
                  >
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                  </div>
                ))}
              </>
              {isLoading && (
                <div className="chat-bubble mt-2 ms-2">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              )}
            </div>
            {showNotification && (
              <div className="notification">
                <p>{notificationContent}</p>
              </div>
            )}
            <div className="chat-footer">
              <div className="chat-container">
                <textarea
                  className="form-control-chatbot"
                  placeholder="Type something"
                  value={chatInput}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  disabled={isLoading}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Submit
                </button>
              </div>

              {/* <div className="row">
    <div className="col">
      <textarea
        type="text"
        className="form-control-chatbot"
        placeholder="Type something"
        value={chatInput}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !isLoading) {
            // Check if isLoading is false
            event.preventDefault(); // Prevent the default form submission
            handleSubmit(); // Calls the submit function
          }
        }}
        disabled={isLoading} // Disable the input field when isLoading is true
      />
    </div>
    <div className="col-auto">
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={handleSubmit}
        disabled={isLoading} // Disable the button when isLoading is true
      >
        Submit
      </button>
    </div>
  </div>

   */}
            </div>
          </div>
          {/* <button className="btn btn-primary" onClick={toggleHide}>
            Hide
          </button> */}
        </div>
      )}
    </div>
  );
}

export default FullChatBot;
