import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import "../styles/style.scss";
import { UncachedImage } from "react-uncached-image";
import Select from "react-select";

function RecipeBrowser() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [recipes, setRecipes] = useState([]);
  const [isTable, setIsTable] = useState(false);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(false);
  const [isFree, setIsFree] = useState(true);

  //Table View Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  //For Weekly Plan
  const [multiplyer, setMultiplyer] = useState(1);

  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [selectedRecipeName, setSelectedRecipeName] = useState("");
  const [selectedRecipeValue, setSelectedRecipeValue] = useState("");
  const [selectedRecipeServings, setSelectedRecipeServings] = useState("");

  const [selectedDayValue, setSelectedDayValue] = useState("");
  const [selectedDayName, setSelectedDayName] = useState("");

  const [selectedCourseValue, setSelectedCourseValue] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");

  const tokenizer = (query) =>
    query
      .toLowerCase()
      .split(/[\s,]+/) // split on whitespace or comma
      .filter((token) => token.trim().length > 0);

  const filteredRecipesWithoutFree = recipes.filter(
    (recipe) =>
      (!onlyUserRecipes || recipe.user_id !== 1) &&
      tokenizer(searchQuery).every((token) => {
        const fieldsToSearch = [
          recipe.name.toLowerCase(),
          recipe.tags.toLowerCase(),
          recipe.course.toLowerCase(),
          recipe.created_at ? recipe.created_at.toLowerCase() : null,
        ];
        return fieldsToSearch.some((field) => field && field.includes(token));
      })
  );

  let filteredRecipes = filteredRecipesWithoutFree;

  if (isFree) {
    const freeRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  }

  const pagesCount = Math.ceil(filteredRecipes.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortedUnits = filteredRecipes.sort((a, b) => {
    let aValue, bValue; // create variables to hold the value for each item being compared
    if (sortedColumn === "calories") {
      aValue = parseFloat(a.total_calories / a.servings);
      bValue = parseFloat(b.total_calories / b.servings);
    } else if (sortedColumn === "cost") {
      aValue = parseFloat(a.total_price / a.servings);
      bValue = parseFloat(b.total_price / b.servings);
    } else {
      aValue = a[sortedColumn];
      bValue = b[sortedColumn];
    }
    // sort based on the calculated or raw values, depending on the column
    if (sortDirection === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setOnlyUserRecipes(user.only_user_recipes === 1);
      setIsTable(user.view_as_table === 1);
      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggleLayout = () => {
    setIsTable(!isTable);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/table_view`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        view_as_table: isTable ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const handleToggleUserRecipes = () => {
    setOnlyUserRecipes(!onlyUserRecipes);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/only_user_recipes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        only_user_recipes: onlyUserRecipes ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipes`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        console.log(data);
        // Filter out recipes where data.hidden === 0
        const removedHiddenRecipes = data.filter(recipe => recipe.hidden === 0);
        setRecipes(removedHiddenRecipes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  

  function handleAddRecipe() {
    const title = "New Recipe Title";
    if (
      isFree &&
      filteredRecipes.filter((recipe) => recipe.user_id !== 1).length >= 10
    ) {
      alert(
        "You've reached your 10 recipe limit. Please sign up for a subscription for unlimited recipes."
      );
    } else {
      // Make a POST request to the API endpoint to add a new recipe
      fetch(`${process.env.REACT_APP_API_URL}/recipes/add`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const newRecipeId = data.id;

          // Redirect the user to the EditRecipe component with the ID of the new recipe
          window.location.href = `${process.env.REACT_APP_CLIENT_URL}/edit-recipe/${newRecipeId}`;
        })
        .catch((error) => console.error("Error adding new recipe:", error));
    }
  }

  //Add to weekly plan
  const handleSelectedRecipe = (selectedOption) => {
    setMultiplyer(1);
    setSelectedRecipeName(selectedOption.name);
    setSelectedRecipe(selectedOption.recipe_id);
    setSelectedRecipeValue(selectedOption.recipe_id);
    setSelectedRecipeServings(selectedOption.servings);
    console.log(selectedOption);
  };

  // New Qty:
  const handleNewMultiplyer = (mulitplyerQty) => {
    setMultiplyer(mulitplyerQty);
  };

  const dayOptions = [
    { value: "None", label: "None" },
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const courseOptions = [
    { value: "Other", label: "Other" },
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
    { value: "Appetizer", label: "Appetizer" },
    { value: "Snack", label: "Snack" },
    { value: "Dessert", label: "Dessert" },
  ].map((option) => ({ value: option.value, label: option.value }));

  const handleAddMeal = () => {
    if (!selectedRecipeValue) {
      alert("Please select a recipe");
      return;
    }

    handleWeeklyPlanUpdate();
    handleShoppingListAdd(selectedRecipe);
  };

  const handleWeeklyPlanUpdate = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/api/add-weekly-plan-recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: selectedRecipe,
        meal_qty: multiplyer,
        day: selectedDayValue,
        meal: selectedCourseValue,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleShoppingListAdd = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipe/ingredients/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log(selectedRecipe);
        const processedData = data.map((ingredient) => {
          const ingredientId = ingredient.old_id.split(",")[0];

          // Determine if ingredient is custom
          const customIngredientFlag = ingredient.old_id.includes("user")
            ? 1
            : 0;
          // Modify the variables you need here
          fetch(
            `${process.env.REACT_APP_API_URL}/add-shopping-item-from-weekly-plan`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ingredient_id: ingredientId,
                custom_ingredient: customIngredientFlag,
                qty: multiplyer * ingredient.qty * ingredient.conversion_factor,
                day: selectedDayValue,
                meal: selectedCourseValue,
                for_recipe: selectedRecipe,
                original_id_string: ingredient.old_id,
              }),
            }
          )
            .then((res) => {
              if (res.ok) {
                setSelectedRecipe(null);
                setSelectedRecipeValue("");
              } else {
                throw new Error("Error adding new unit");
              }
            })
            .catch((err) => {
              console.error("Error adding new unit:", err);
            });
        });
      });
  };

  useEffect(() => {
    fetchUserSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"List View"}
                checked={isTable}
                onChange={handleToggleLayout}
              />{" "}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"Only Your Recipes"}
                checked={onlyUserRecipes}
                onChange={handleToggleUserRecipes}
              />{" "}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary" onClick={handleAddRecipe}>
                Add Recipe
              </button>
            </div>
          </div>
        </div>
      </div>

      <input
        type="text"
        className="form-control"
        placeholder="Search recipes"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <br />

      {!isTable && (
        <div className="container-fluid">
          <div className="d-flex justify-content-center overflow-auto flex-wrap">
            {" "}
            <div className="d-flex align-items-center me-2">
              <b>Sort:</b>
            </div>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Recipe"
              onClick={() => handleSort("name")}
            >
              Recipe{" "}
              {sortedColumn === "name" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Course"
              onClick={() => handleSort("course")}
            >
              Course{" "}
              {sortedColumn === "course" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Servings"
              onClick={() => handleSort("servings")}
            >
              Servings{" "}
              {sortedColumn === "servings" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Calories Per Serving"
              onClick={() => handleSort("calories")}
            >
              Calories Per Serving{" "}
              {sortedColumn === "calories" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Cost Per Serving"
              onClick={() => handleSort("cost")}
            >
              Cost Per Serving{" "}
              {sortedColumn === "cost" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by date created"
              onClick={() => handleSort("recipe_id")}
            >
              Date Created{" "}
              {sortedColumn === "recipe_id" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Tags"
              onClick={() => handleSort("tags")}
            >
              Tags{" "}
              {sortedColumn === "tags" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
          </div>
          <br />
          <div className="row">
            {filteredRecipes.slice(startIndex, endIndex).map((recipe) => (
              <div
                className="col-sm-6 col-md-4 col-lg-3"
                key={recipe.recipe_id}
              >
                <div
                  className="card mb-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`;
                  }}
                >
                  <UncachedImage
                    className="card-img-top"
                    src={recipe.image_url}
                    alt={recipe.name}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {" "}
                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <b>{recipe.name} </b>
                      </a>
                    </h5>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectedRecipe(recipe);
                      }}
                    >
                      + To Plan
                    </button>
                    <div className="row">
                      <div className="col-12">
                        <b>Course:</b> {recipe.course}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <b>Servings:</b> {recipe.servings}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <b>Calories per serving:</b>{" "}
                        {parseFloat(
                          recipe.total_calories / recipe.servings
                        ).toFixed(0)}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <b>Cost per serving:</b> $
                          {parseFloat(
                            recipe.total_price / recipe.servings
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <b>Date Created:</b>{" "}
                          {new Date(recipe.created_at).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <b>Tags:</b>{" "}
                          {recipe.tags
                            ? recipe.tags.length > 50
                              ? recipe.tags.slice(0, 50) + "..."
                              : recipe.tags
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isTable && (
        <div>
          <div className="table-responsive">
            {" "}
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("name")}
                  >
                    Recipe{" "}
                    {sortedColumn === "name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("course")}
                  >
                    Course{" "}
                    {sortedColumn === "course" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "course" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("servings")}
                  >
                    Servings{" "}
                    {sortedColumn === "servings" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "servings" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("calories")}
                  >
                    Calories Per Serving{" "}
                    {sortedColumn === "calories" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "calories" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("cost")}
                  >
                    Cost Per Serving{" "}
                    {sortedColumn === "cost" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "cost" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>

                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("recipe_id")}
                  >
                    Date Created{" "}
                    {sortedColumn === "recipe_id" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "recipe_id" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>

                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("tags")}
                  >
                    Tags{" "}
                    {sortedColumn === "tags" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "tags" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredRecipes.slice(startIndex, endIndex).map((recipe) => (
                  <tr key={recipe.recipe_id}>
                    <td>
                      <button
                        className="btn btn-sm btn-outline-primary mb-0"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSelectedRecipe(recipe);
                        }}
                      >
                        + To Plan
                      </button>{" "}
                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <b>{recipe.name} </b>
                      </a>
                    </td>
                    <td className="text-center">{recipe.course}</td>
                    <td className="text-center">{recipe.servings}</td>
                    <td className="text-center">
                      {parseFloat(
                        recipe.total_calories / recipe.servings
                      ).toFixed(0)}
                    </td>
                    <td className="text-center">
                      $
                      {parseFloat(recipe.total_price / recipe.servings).toFixed(
                        2
                      )}
                    </td>
                    <td className="text-center">
                      {new Date(recipe.created_at).toLocaleDateString()}
                    </td>
                    <td className="text-center">
                      {recipe.tags
                        ? recipe.tags.length > 50
                          ? recipe.tags.slice(0, 50) + "..."
                          : recipe.tags
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Modal
        show={selectedRecipe !== null}
        onHide={() => setSelectedRecipe(null)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add to Weekly Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb-3">{selectedRecipeName}</h4>
          <div className="row align-items-center justify-content-center mt-2">
            <div className="col-4">
              <label htmlFor="multiplier">
                <b>Multiplier:</b>
              </label>
            </div>
            <div className="col-8">
              <input
                type="number"
                id="multiplier"
                className="form-control my-1"
                placeholder="Enter new unit name"
                min="0"
                value={multiplyer}
                onChange={(e) =>
                  handleNewMultiplyer(parseFloat(e.target.value))
                }
              />
            </div>
            <div className="col-4">
              <label htmlFor="servings">
                <b>Servings:</b>
              </label>
            </div>
            <div className="col-8 my-1">
              {(() => {
                const servings = parseFloat(
                  multiplyer * selectedRecipeServings
                );
                if (Number.isInteger(servings)) {
                  return servings.toString();
                } else {
                  return servings
                    .toFixed(servings % 1 === 0.1 ? 1 : 2)
                    .replace(/(\.0|0+)$/, "");
                }
              })()}
            </div>
            <div className="col-4">
              <label htmlFor="day">
                <b>Day:</b>
              </label>
            </div>
            <div className="col-8 my-1">
              <Select
                options={dayOptions}
                id="day"
                value={{
                  value: selectedDayValue,
                  label: selectedDayName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedDayValue(selectedOption.value);
                  setSelectedDayName(selectedOption.label);
                }}
              />
            </div>
            <div className="col-4">
              <label htmlFor="course">
                <b>Course:</b>
              </label>
            </div>
            <div className="col-8 my-1">
              <Select
                options={courseOptions}
                id="course"
                value={{
                  value: selectedCourseValue,
                  label: selectedCourseName,
                }}
                isSearchable
                onChange={(selectedOption) => {
                  setSelectedCourseValue(selectedOption.value);
                  setSelectedCourseName(selectedOption.label);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedRecipe(null)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddMeal}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RecipeBrowser;
