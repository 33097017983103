import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "../styles/style.scss";

function ShoppingList() {
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [aisles, setAisles] = useState([]);
  const [selectedAisle, setSelectedAisle] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState([]);
  const [selectedIngredientUnit, setSelectedIngredientUnit] = useState("");
  const [ingredientQty, setIngredientQty] = useState(1);
  const [isMetric, setIsMetric] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Favorited
  const [favIngredientOptions, setFavIngredientOptions] = useState([]);
  const [favIngredientQty, setFavIngredientQty] = useState(1);
  const [favSelectedIngredient, setFavSelectedIngredient] = useState([]);
  const [favSelectedIngredientUnit, setFavSelectedIngredientUnit] =
    useState("");

  // Add/Remove Modal Variables
  const [modalSelectedIngredient, setModalSelectedIngredient] = useState([]);

  // Edit Favorites
  const [editFav, setEditFav] = useState(null);

  const fetchUserMetricSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }

      setIsMetric(user.default_unit_of_measurement === "metric");
    } catch (error) {
      // Handle error
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleFavEditMode = () => {
    setEditFav(!editFav);
  };

  const handleToggle = () => {
    setIsMetric(!isMetric);

    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        default_unit_of_measurement: isMetric ? "traditional" : "metric",
      }),
    }).then((response) => {
      fetchShoppingList();
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);
  const [itemQty, setItemQty] = useState(1);
  const [itemName, setItemName] = useState("");

  const [shoppingListItems, setShoppingListItems] = useState([]);
  const [deletedShoppingListItems, setDeletedShoppingListItems] = useState([]);

  const [selectedItemEditAisle, setSelectedItemEditAisle] = useState(null);
  const [selectedItemEditItemName, setSelectedItemEditItemName] = useState("");

  const [selectedEditAisle, setSelectedEditAisle] = useState([]);

  // ingredient Qty:
  const handleIngredientQtyChange = (ingredientQtyValue) => {
    if (!isNaN(ingredientQtyValue)) {
      setIngredientQty(ingredientQtyValue);
    } else {
      setIngredientQty("");
    }
  };

  // fav ingredient Qty:
  const handleFavIngredientQtyChange = (favIngredientQtyValue) => {
    if (!isNaN(favIngredientQtyValue)) {
      setFavIngredientQty(favIngredientQtyValue);
    } else {
      setFavIngredientQty("");
    }
  };

  //Handle Form Changes
  const handleItemQtyChange = (itemQtyValue) => {
    if (!isNaN(itemQtyValue)) {
      setItemQty(itemQtyValue);
    } else {
      setItemQty("");
    }
  };

  //Handle Form Changes
  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };

  //Edit AIsle
  const handleEditInstruction = (item) => {
    setSelectedItemEditAisle(item);
    setSelectedItemEditItemName(item.item_name);
    setSelectedEditAisle(item);
    console.log(item);
  };

  const fetchIngredients = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/ingredients_and_non_cooking_items`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIngredientOptions(data);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  const fetchFavIngredients = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/fav_ingredients_and_non_cooking_items`,
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFavIngredientOptions(data);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  const fetchAisles = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_aisle`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setAisles(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const fetchShoppingList = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_list2`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("---");
        console.log(data); // Check if data is loaded correctly
        console.log("---");
        setShoppingListItems(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const fetchDeletedShoppingList = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/deleted_shopping_list`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Check if data is loaded correctly
        setDeletedShoppingListItems(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const handleAddIngredient = () => {
    // Split ingredientId to get the ID
    const ingredient_id = selectedIngredient.id.split(",")[0];
    // Determine if ingredient is custom
    const ingredient_true = selectedIngredient.id.includes("non-cooking")
      ? null
      : 1;
    const customIngredientFlag = selectedIngredient.id.includes("user") ? 1 : 0;
    const nonIngredientFlag = selectedIngredient.id.includes("non-cooking")
      ? 1
      : null;

    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-shopping-item`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientId: ingredient_id,
        ingredient_true: ingredient_true,
        custom_ingredient: customIngredientFlag,
        nonIngredientFlag: nonIngredientFlag,
        qty: ingredientQty * selectedIngredient.conversion_factor,
        original_id: selectedIngredient.id,
      }),
    })
      .then((res) => {
        fetchShoppingList();
        setItemQty(1);
        setItemName("");
        setSelectedAisle([]);
        setIngredientQty(1);
        setSelectedIngredient([]);
        setSelectedIngredientUnit("");
        if (res.ok) {
          // Reload units data
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleFavAddIngredient = () => {
    // Split ingredientId to get the ID
    const ingredient_id = favSelectedIngredient.id.split(",")[0];
    // Determine if ingredient is custom
    const ingredient_true = favSelectedIngredient.id.includes("non-cooking")
      ? null
      : 1;
    const customIngredientFlag = favSelectedIngredient.id.includes("user")
      ? 1
      : 0;
    const nonIngredientFlag = favSelectedIngredient.id.includes("non-cooking")
      ? 1
      : null;

    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-shopping-item`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientId: ingredient_id,
        ingredient_true: ingredient_true,
        custom_ingredient: customIngredientFlag,
        nonIngredientFlag: nonIngredientFlag,
        qty: favIngredientQty * favSelectedIngredient.conversion_factor,
        original_id: favSelectedIngredient.id,
      }),
    })
      .then((res) => {
        fetchShoppingList();
        setItemQty(1);
        setItemName("");
        setSelectedAisle([]);
        setIngredientQty(1);
        setSelectedIngredient([]);
        setSelectedIngredientUnit("");
        setFavIngredientQty(1);
        setFavSelectedIngredient([]);
        setFavSelectedIngredientUnit("");
        if (res.ok) {
          // Reload units data
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleAddManualItem = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-manual-shopping-item`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        manual_name: itemName,
        manual_aisle_id: selectedAisle.id,
        manual_qty: itemQty,
      }),
    })
      .then((res) => {
        fetchShoppingList();
        setItemQty(1);
        setItemName("");
        setSelectedAisle([]);
        setIngredientQty(1);
        setSelectedIngredient([]);
        setSelectedIngredientUnit("");
        if (res.ok) {
          // Reload units data
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleDeleteItem = (item) => {
    const requestBody = {
      aisle_id: item.aisle_id ?? null,
      aisle_name: item.aisle_name ?? null,
      day: item.day ?? null,
      for_recipe: item.for_recipe ?? null,
      item_name: item.item_name ?? null,
      meal: item.meal ?? null,
      original_id_string: item.original_id_string ?? null,
      qty: item.qty ?? null,
      sort_order: item.sort_order ?? null,
      unit_name: item.unit_name ?? null,
    };

    fetch(`${process.env.REACT_APP_API_URL}/move-item`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (res.ok) {
          console.log("success");
          fetchShoppingList();
          fetchDeletedShoppingList();
        } else {
          throw new Error("Error moving aisle");
        }
      })
      .catch((err) => {
        console.error("Error deleting aisle:", err);
      });
  };

  //remove item from favorites
  const handleRemoveFavorite = async (item) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove "${item.ingredient_name}" from your favorites list?`
    );
    if (!confirmed) return;

    const isUserIngredient = item.id.includes("user") ? 1 : 0;
    const isNonCooking = item.id.includes("non") ? 1 : 0;

    fetch(`${process.env.REACT_APP_API_URL}/shopping_remove_favorite`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        itemId: item.id,
        isUserIngredient: isUserIngredient,
        isNonCooking: isNonCooking,
      }),
    })
      .then((res) => {
        if (res.ok) {
          console.log("success");
          fetchFavIngredients();
        } else {
          throw new Error("Error moving aisle");
        }
      })
      .catch((err) => {
        console.error("Error deleting aisle:", err);
      });
  };

  //Add item to a User's FavoritesList
  const handleAddFavoriteToList = async () => {
    const isUserIngredient = modalSelectedIngredient.id.includes("user")
      ? 1
      : 0;
    const isNonCooking = modalSelectedIngredient.id.includes("non") ? 1 : 0;

    fetch(`${process.env.REACT_APP_API_URL}/shopping_add_favorite`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        itemId: modalSelectedIngredient.id,
        isUserIngredient: isUserIngredient,
        isNonCooking: isNonCooking,
      }),
    })
      .then((res) => {
        if (res.ok) {
          console.log("success");
          fetchFavIngredients();
        } else {
          throw new Error("Error moving aisle");
        }
      })
      .catch((err) => {
        console.error("Error deleting aisle:", err);
      });
  };

  const handleClearDeletedItems = (item) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/clear-crossed-off-items`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          console.log("success");
          fetchDeletedShoppingList();
        } else {
          throw new Error("Error deleting");
        }
      })
      .catch((err) => {
        console.error("Error deleting:", err);
      });
  };

  const handleUpdateAisleInformation = () => {
    console.log(selectedItemEditAisle.original_id_string);
    const original_id = selectedItemEditAisle.original_id_string;

    const ingredient_id =
      selectedItemEditAisle.original_id_string.split(",")[0];
    console.log(ingredient_id);
    console.log(selectedEditAisle);

    if (original_id.includes("user")) {
      fetch(`${process.env.REACT_APP_API_URL}/api/update_user_aisle`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: ingredient_id,
          aisle_id: selectedEditAisle.id,
          aisle_name: selectedEditAisle.aisle_name,
          original_id: original_id,
        }),
      })
        .then((res) => {
          if (res.ok) {
            console.log("success");
            fetchShoppingList();
            fetchDeletedShoppingList();
            setSelectedItemEditAisle(null);
          } else {
            throw new Error("Error moving aisle");
          }
        })
        .catch((err) => {
          console.error("Error deleting aisle:", err);
        });
    } else if (original_id.includes("non-cooking")) {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/update_user_aisle_non_cooking`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: ingredient_id,
            aisle_id: selectedEditAisle.id,
            aisle_name: selectedEditAisle.aisle_name,
            original_id: original_id,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            console.log("success");
            fetchShoppingList();
            fetchDeletedShoppingList();
            setSelectedItemEditAisle(null);
          } else {
            throw new Error("Error moving aisle");
          }
        })
        .catch((err) => {
          console.error("Error deleting aisle:", err);
        });
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/user-ingredient-customization?ingredient_id=${ingredient_id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error querying user ingredient customization");
          }
        })
        .then((data) => {
          console.log(data);
          try {
            if (data.length === 0) {
              // No match, create new entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/api/insert_update_non_user_aisle`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: ingredient_id,
                    aisle_id: selectedEditAisle.id,
                    aisle_name: selectedEditAisle.aisle_name,
                    original_id: original_id,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    console.log("success");
                    fetchShoppingList();
                    fetchDeletedShoppingList();
                    setSelectedItemEditAisle(null);
                  } else {
                    throw new Error("Error moving aisle");
                  }
                })
                .catch((err) => {
                  console.error("Error deleting aisle:", err);
                });
            } else {
              // Match found, update existing entry in user_ingredient_customizations table

              fetch(
                `${process.env.REACT_APP_API_URL}/api/update_non_user_aisle`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: ingredient_id,
                    aisle_id: selectedEditAisle.id,
                    aisle_name: selectedEditAisle.aisle_name,
                    original_id: original_id,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    console.log("success");
                    fetchShoppingList();
                    fetchDeletedShoppingList();
                    setSelectedItemEditAisle(null);
                  } else {
                    throw new Error("Error moving aisle");
                  }
                })
                .catch((err) => {
                  console.error("Error deleting aisle:", err);
                });
            }
          } catch (err) {
            console.error("Error parsing JSON response:", err);
          }
        })
        .catch((err) => {
          console.error("Error querying user ingredient customization:", err);
        });
    }
  };

  const handleSetUnitOptionDefault = (original_id, conversionId) => {
    console.log(original_id);

    fetch(`${process.env.REACT_APP_API_URL}/api/setShoppingUnit`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        original_id: original_id,
        conversionId: 0,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success");
        fetchIngredients();
        fetchFavIngredients();
        fetchShoppingList();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSetUnitOption = (original_id, conversionId) => {
    console.log(original_id);
    console.log(conversionId);

    fetch(`${process.env.REACT_APP_API_URL}/api/setShoppingUnit`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        original_id: original_id,
        conversionId: conversionId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success");
        fetchIngredients();
        fetchFavIngredients();
        fetchShoppingList();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchShoppingList();
    fetchDeletedShoppingList();
    fetchIngredients();
    fetchFavIngredients();
    fetchAisles();
    fetchUserMetricSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}
        <div className="row">
          <div className="col-sm-5">
            <div
              className="container rounded pb-4"
              style={{ backgroundColor: "#5c5c5c" }}
            >
              <div className="text-center">
                <div className="py-1">
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        This list is from your unhidden "Ingredients" and
                        "Non-Cooking Items".
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>

                  <b>Search an existing item:</b>
                </div>{" "}
              </div>
              <div className="row align-items-end">
                <div className="col-sm-3">
                  <label className="mr-1">
                    <b>Qty:</b>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Qty"
                    min="0"
                    value={ingredientQty}
                    onChange={(e) =>
                      handleIngredientQtyChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <label className="mr-1">
                    <b>Item:</b>{" "}
                    {selectedIngredientUnit !== "" && (
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;<b>Unit:</b>{" "}
                        {selectedIngredientUnit}
                      </span>
                    )}
                  </label>
                  <Select
                    key={selectedIngredient}
                    options={ingredientOptions}
                    value={{
                      id: selectedIngredient.id,
                      ingredient_name: selectedIngredient.ingredient_name,
                    }}
                    onChange={(selectedOption) => {
                      setSelectedIngredient(selectedOption);
                      setSelectedIngredientUnit(selectedOption.unit_id);
                    }}
                    getOptionLabel={(option) => option.ingredient_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <Button variant="primary" onClick={handleAddIngredient}>
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-7">
            <div
              className="container rounded pb-4"
              style={{ backgroundColor: "#5c5c5c" }}
            >
              <div className="text-center">
                <div className="py-1">
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Manual items are not saved for future use and cannot be
                        edited. They are intended for a "one-time" use.
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>

                  <b>Manual add an item:</b>
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col-sm-2">
                  <div className="col-sm-2"></div>
                  <label className="mr-1">
                    <b>Qty:</b>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Qty"
                    min="0"
                    value={itemQty}
                    onChange={(e) =>
                      handleItemQtyChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="col-sm-4">
                  <label className="mr-1">
                    <b>Item:</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={itemName}
                    onChange={handleItemNameChange}
                  />
                </div>
                <div className="col-sm-3">
                  <label className="mr-1">
                    <b>Aisle:</b>
                  </label>
                  <Select
                    key={selectedAisle}
                    value={{
                      aisle_name: selectedAisle.aisle_name,
                      id: selectedAisle.id,
                    }}
                    options={aisles}
                    onChange={(selectedOption) => {
                      setSelectedAisle(selectedOption);
                    }}
                    getOptionLabel={(option) => option.aisle_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                </div>

                <div className="col-sm-3">
                  <Button variant="primary" onClick={handleAddManualItem}>
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <div
              className="container rounded my-2 pb-4"
              style={{ backgroundColor: "#5c5c5c" }}
            >
              <div className="text-center">
                <div className="py-1">
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        This list is from your unhidden favorited "Ingredients"
                        and "Non-Cooking Items".
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>

                  <b>Search from favorites:</b>
                </div>{" "}
              </div>
              <div className="row align-items-end">
                <div className="col-sm-3">
                  <label className="mr-1">
                    <b>Qty:</b>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Qty"
                    min="0"
                    value={favIngredientQty}
                    onChange={(e) =>
                      handleFavIngredientQtyChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <label className="mr-1">
                    <b>Item:</b>{" "}
                    {favSelectedIngredientUnit !== "" && (
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;<b>Unit:</b>{" "}
                        {favSelectedIngredientUnit}
                      </span>
                    )}
                  </label>
                  <Select
                    key={favSelectedIngredient}
                    options={favIngredientOptions}
                    value={{
                      id: favSelectedIngredient.id,
                      ingredient_name: favSelectedIngredient.ingredient_name,
                    }}
                    onChange={(selectedOption) => {
                      setFavSelectedIngredient(selectedOption);
                      setFavSelectedIngredientUnit(selectedOption.unit_id);
                    }}
                    getOptionLabel={(option) => option.ingredient_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <Button variant="primary" onClick={handleFavAddIngredient}>
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div
              className="container rounded my-2 pb-4"
              style={{ backgroundColor: "#5c5c5c" }}
            >
              <div className="text-center">
                <div className="py-1">
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Add and remove items from your favorite's list.
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>

                  <b>Manage Favorites:</b>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-12 text-center">
                  <Button variant="primary" onClick={handleFavEditMode}>
                    Manage Favorites
                  </Button>
                </div>
              </div>
            </div>
            
            </div></div>
          <div className="row">
            <div className="col-12">
            <div
              className="container rounded my-0 py-2"
              style={{ backgroundColor: "#5c5c5c" }}
            >
              <h5>Options:</h5>
              <div className="row">
                <div className="col-sm-2">
                  <Form.Switch
                    type="switch"
                    id="custom-switch"
                    label={"Use Metric"}
                    checked={isMetric}
                    onChange={handleToggle}
                  />
                </div>
                <div className="col-sm-2 d-flex align-items-center">
                  <Form.Switch
                    type="switch"
                    id="custom-switch"
                    label={"Edit Mode"}
                    checked={editMode}
                    onChange={handleEditMode}
                  />
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Allows you to set and save the 'unit of measurement'
                        displayed for each item. List is based on your
                        'Conversions' for each ingredient. (Not available for
                        'non-cooking' or 'manual' items. )
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

      <div>
        <h3>Shopping List</h3>
        <p>
          Click "Edit Aisle" for non-manually added items to update and save the
          aisle information.
        </p>
        <div className="table-responsive" style={{ overflow: "visible" }}>
          <table className="table table-striped">
            <tbody>
              {shoppingListItems.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="my-button"
                      onClick={() => handleDeleteItem(item)}
                    >
                      X
                    </Button>{" "}
                    <b>
                      {Number.isInteger(item.qty)
                        ? parseFloat(item.qty)
                        : parseFloat(item.qty).toFixed(1)}
                    </b>{" "}
                    {item.unit_name} {" - "}
                    <b>{item.item_name}</b>
                    {" - "}({item.aisle_name}){" "}
                    {item.original_id_string !== null && (
                      <Button
                        variant="primary"
                        size="sm"
                        className="my-button"
                        onClick={() => handleEditInstruction(item)}
                      >
                        Edit Aisle
                      </Button>
                    )}
                    {editMode &&
                      item.conversion_options &&
                      item.conversion_options.length > 0 && (
                        <div className="btn-group mx-2">
                          <button
                            type="button"
                            className={`btn btn-sm ${
                              parseInt(item.store_unit_overide) !== 0 &&
                              item.store_unit_overide
                                ? "btn-success"
                                : "btn-secondary"
                            } dropdown-toggle-2 set-unit-button-style`}
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Set Unit
                          </button>
                          <div className="dropdown-menu">
                            <button
                              type="button"
                              className="dropdown-item"
                              onClick={() =>
                                handleSetUnitOptionDefault(
                                  item.original_id_string
                                )
                              }
                            >
                              <span>Default</span>
                            </button>
                            {item.conversion_options.map((option, index) => (
                              <button
                                type="button"
                                className="dropdown-item"
                                key={index}
                                onClick={() =>
                                  handleSetUnitOption(
                                    item.original_id_string,
                                    option.id
                                  )
                                }
                              >
                                <span>{option.unit_name}</span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <h3>Crossed Off Items:</h3>
        <div>
          <Button
            variant="primary"
            className="my-button"
            onClick={handleClearDeletedItems}
          >
            Clear Crossed Off Items
          </Button>
        </div>{" "}
        <br />
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              {deletedShoppingListItems.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-decoration-line-through">
                    <b>{parseFloat(item.qty).toFixed(2)}</b> {item.unit_name}{" "}
                    {" - "}
                    <b>{item.item_name}</b>
                    {" - "}({item.aisle_name})
                    {item.original_id_string !== null && (
                      <span className="text-decoration-non">
                        {" "}
                        <Button
                          variant="primary"
                          size="sm"
                          className="my-button"
                          onClick={() => handleEditInstruction(item)}
                        >
                          Edit Aisle
                        </Button>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        show={selectedItemEditAisle !== null}
        onHide={() => setSelectedItemEditAisle(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Aisle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Edit Aisle for <b>{selectedItemEditItemName}</b>
          <Select
            key={selectedEditAisle}
            value={{
              aisle_name: selectedEditAisle.aisle_name,
              id: selectedEditAisle.aisle_id,
            }}
            options={aisles}
            onChange={(selectedOption) => {
              setSelectedEditAisle(selectedOption);
            }}
            getOptionLabel={(option) => option.aisle_name}
            getOptionValue={(option) => option.id}
            isSearchable={false}
            styles={{
              option: (provided) => ({
                ...provided,
                color: "black",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black",
              }),
              control: (provided, state) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSelectedItemEditAisle(null)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateAisleInformation()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* //Favorited Modal */}
      <Modal show={editFav !== null} onHide={() => setEditFav(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Favorites</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-end">
            <div className="col-sm-6">
              <label className="mr-1">
                <b>Item: {modalSelectedIngredient.id}</b>
              </label>
              <Select
                key={modalSelectedIngredient}
                options={ingredientOptions}
                value={{
                  id: modalSelectedIngredient.id,
                  ingredient_name: modalSelectedIngredient.ingredient_name,
                }}
                onChange={(selectedOption) => {
                  setModalSelectedIngredient(selectedOption);
                }}
                getOptionLabel={(option) => option.ingredient_name}
                getOptionValue={(option) => option.id}
                isSearchable={true}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            </div>
            <div className="col-sm-3">
              <Button variant="primary" onClick={handleAddFavoriteToList}>
                Add Item
              </Button>
            </div>
          </div>
          <b>Current Favorites:</b>
          <table className="table table-striped">
            <tbody>
              {favIngredientOptions.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.ingredient_name}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="my-button"
                      onClick={() => handleRemoveFavorite(item)}
                    >
                      Remove Favorite
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditFav(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShoppingList;
