import "../styles/style.scss";
import React, { useState, useEffect } from "react";

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasBronze, setHasBronze] = useState(false);
  const [hasSilver, setHasSilver] = useState(false);
  const [hasGold, setHasGold] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    // First fetch request
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // handle response data
        if (data.isLoggedIn) {
          console.log("Is LoggedIn Running");
          setIsLoggedIn(true);
        }
        console.log("Is LoggedIn Running");
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
      });

    // Second fetch request
    fetch(`${process.env.REACT_APP_API_URL}/api/getUser`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { user } = data;
        // handle response data
        if (!user) {
          // Handle error
        }
        console.log("GetUser Running");
        setIsAdmin(user.membership_level === "admin");
        setUser(user);
      })
      .catch((error) => {
        console.error("Error getting user details:", error);
      });

    // Third fetch request
    fetch(`${process.env.REACT_APP_API_URL}/bronze`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        // handle response data
        if (response.ok) {
          setHasBronze(true);
          console.log("Bronze Running");
        } else if (response.status === 403) {
          setHasBronze(false);
        } else {
          console.log("Error");
          setHasBronze(false);
        }
      })
      .catch((error) => {
        console.error("Error checking if user has bronze:", error);
        setHasBronze(false);
      });
  }, []);

  function handleLogout() {
    fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setIsLoggedIn(false);
        } else {
          throw new Error("Logout failed");
        }
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }

  return (
    <nav className="navbar navbar-dark navbar-expand-lg">
      <div className="container-fluid">
        <a href="/">
          <img className="brand-image" src="/logo_v1.png" alt="Plate Passion" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
            <li className="nav-item">
              <a className="nav-link" href="/recipes">
                Recipes
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/meals">
                Meals
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/weekly-plan">
                Weekly Plan
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/shopping">
                Shopping List
              </a>
            </li>
            <>
              {isLoggedIn && (
                <li className="nav-item dropdown">
                  <button
                    className="dropdown-toggle btn btn-secondary btn-sm"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Customizations
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="/recipe_view_settings">
                      Recipe Visibility
                    </a>
                    <a className="dropdown-item" href="/my_meals">
                      Edit My Meals
                    </a>
                    <a className="dropdown-item" href="/user_ingredients">
                      Ingredients
                    </a>
                    <a className="dropdown-item" href="/non-cooking-items">
                      Non-Cooking Items
                    </a>
                    <a className="dropdown-item" href="/shopping_aisles">
                      Shopping Aisles
                    </a>
                    <a className="dropdown-item" href="/user_units">
                      Units of Measurement
                    </a>
                  </div>
                </li>
              )}
            </>

            <>
              {isAdmin && (
                <li className="nav-item dropdown mx-1">
                  <button
                    className="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Admin Customizations:
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="/admin-ingredients">
                      Default Ingredients
                    </a>
                    <a className="dropdown-item" href="/admin-units">
                      Default Units
                    </a>
                  </div>
                </li>
              )}
            </>
          </ul>
          <ul className="navbar-nav me-2 mb-2 mb-lg-0 d-flex align-items-center">
            <>
              {!hasBronze && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/membership_options"
                    target="_blank"
                  >
                    Membership Options
                  </a>
                </li>
              )}
              {hasBronze && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://billing.stripe.com/p/login/aEU5mwagVasYd0IfYY"
                    target="_blank"
                  >
                    Manage Membership
                  </a>
                </li>
              )}
            </>
            <>
              {isLoggedIn && (
                <>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={`${process.env.REACT_APP_CLIENT_URL}/user/${user.id}`}
                    >
                      Settings
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/" onClick={handleLogout}>
                      Logout ({user.username})
                    </a>
                  </li>
                </>
              )}

              {!isLoggedIn && (
                <li className="nav-item">
                  <a className="nav-link" href="/signup">
                    Sign Up
                  </a>
                </li>
              )}
              {!isLoggedIn && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href={`/signin?returnUrl=${encodeURIComponent(
                      window.location.pathname
                    )}`}
                  >
                    Sign In
                  </a>
                </li>
              )}
            </>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
