import React, { useState, useEffect } from "react";
import Stripe from "stripe";
import { Success, Cancel } from "./PaymentComponents";
import "../styles/style.scss";

function MembershipOptionsTest() {
  const [prices, setPrices] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [yearlyOption, setYearlyOption] = useState(0);

  const handleOptionChange = (event) => {
    const value = event.target.id === "btnradio2" ? 1 : 0;
    setYearlyOption(value);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  useEffect(() => {
    if (yearlyOption === 0) {
      const stripe = new Stripe(process.env.REACT_APP_STRIPE_TEST_API_SECRET_KEY);

      const productIds = [
        `${process.env.REACT_APP_TEST_MONTHLY_BRONZE}`,
        `${process.env.REACT_APP_TEST_MONTHLY_SILVER}`,
        `${process.env.REACT_APP_TEST_MONTHLY_GOLD}`,
      ]; // add your product ids here
      const productRequests = productIds.map((productId) =>
        stripe.products.retrieve(productId)
      );
      Promise.all(productRequests)
        .then((productData) => {
          const pricesWithData = productData.map((product) => {
            const priceId = product.default_price;
            return {
              id: priceId,
              product: {
                id: product.id,
                name: product.name,
                desc: product.description,
                images: product.images,
              },
            };
          });
          return Promise.all(
            pricesWithData.map((price) => stripe.prices.retrieve(price.id))
          ).then((priceData) => {
            return priceData.map((price, index) => ({
              ...pricesWithData[index],
              price: `${price.unit_amount}`,
            }));
          });
        })
        .then((pricesData) => setPrices(pricesData))
        .catch((error) =>
          console.error("Error fetching product data from Stripe:", error)
        );
    } else {
      const stripe = new Stripe(process.env.REACT_APP_STRIPE_TEST_API_SECRET_KEY);

      const productIds = [
        `${process.env.REACT_APP_TEST_YEARLY_BRONZE}`,
        `${process.env.REACT_APP_TEST_YEARLY_SILVER}`,
        `${process.env.REACT_APP_TEST_YEARLY_GOLD}`,
      ]; // add your product ids here
      const productRequests = productIds.map((productId) =>
        stripe.products.retrieve(productId)
      );
      Promise.all(productRequests)
        .then((productData) => {
          const pricesWithData = productData.map((product) => {
            const priceId = product.default_price;
            return {
              id: priceId,
              product: {
                id: product.id,
                name: product.name,
                desc: product.description,
                images: product.images,
              },
            };
          });
          return Promise.all(
            pricesWithData.map((price) => stripe.prices.retrieve(price.id))
          ).then((priceData) => {
            return priceData.map((price, index) => ({
              ...pricesWithData[index],
              price: `${price.unit_amount}`,
            }));
          });
        })
        .then((pricesData) => setPrices(pricesData))
        .catch((error) =>
          console.error("Error fetching product data from Stripe:", error)
        );
    }
  }, [yearlyOption]);

  const handleSubscribe = (priceId) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/create-checkout-session-test`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: priceId,
        successUrl: `${process.env.REACT_APP_CLIENT_URL}/success`,
        cancelUrl: `${process.env.REACT_APP_CLIENT_URL}/cancel`,
      }),
    })
      .then((response) => response.json())
      .then((session) => {
        window.location.href = session.url;
      })
      .catch((error) => {
        console.error("Error creating Stripe Checkout session:", error);
      });
  };

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div className="container">
      <h2 className="text-center my-2">Membership Options</h2>
      <div
        className="btn-group mb-2"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          className="btn-check"
          name="btnradio"
          id="btnradio1"
          autoComplete="off"
          defaultChecked
          onChange={handleOptionChange}
        />
        <label className="btn btn-outline-primary" htmlFor="btnradio1">
          Monthly Plans
        </label>

        <input
          type="radio"
          className="btn-check"
          name="btnradio"
          id="btnradio2"
          autoComplete="off"
          onChange={handleOptionChange}
        />
        <label className="btn btn-outline-primary" htmlFor="btnradio2">
          Yearly Plans
        </label>
      </div>

      <div className="row row-cols-1 row-cols-md-3">
        {prices.map((price) => (
          <div className="col mb-4" key={price.id}>
            <div className="card">
              <img
                src={price.product.images[0]}
                className="card-img-top-center"
                alt={price.product.name}
                style={{ maxWidth: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title">{price.product.name}</h5>
                <p className="card-text">{price.product.desc}</p>
                <ul>
                  {price.product.name === "Bronze" && (
                    <>
                      <li>Unlimited recipes</li>
                    </>
                  )}
                  {price.product.name === "Silver" && (
                    <>
                      <li>All features of Bronze</li>
                      <li>
                        Access to Remy, an AI chatbot designed to help you with
                        your cooking and recipe creation (includes 100,000
                        tokens per month)
                      </li>
                      <li>
                        Make your recipes public so friends and family can
                        browse your cookbook with your own URL.
                      </li>
                    </>
                  )}
                  {price.product.name === "Gold" && (
                    <>
                      <li>All features of Silver and Bronze</li>
                      <li>
                        Create AI images for your recipes with the click of a
                        button (includes 50 generations per month)
                      </li>
                    </>
                  )}
                </ul>
                <p className="card-text" style={{ fontSize: "24px" }}>
                  {yearlyOption === 1
                    ? `$${price.price / 100} / year (20% savings)`
                    : `$${price.price / 100} / month`}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleSubscribe(price.id)}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {paymentStatus === "success" && <Success />}
      {paymentStatus === "cancel" && <Cancel />}
    </div>
  );
}

export default MembershipOptionsTest;
