import React from "react";

function Success() {

  return (
    <div>
      <h2>Payment successful!</h2>
      <p>Thank you for your payment.</p>
    </div>
  );
}

function Cancel() {
  return (
    <div>
      <h2>Payment canceled.</h2>
      <p>Your payment was not processed. Please try again.</p>
    </div>
  );
}

export { Success, Cancel };