import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";

function AboutRemy() {
  return (
    <>
      <div><h1>About Remy</h1>
  <p>Welcome to Remy, your friendly chatbot and personal assistant for all things cooking!</p>
  <p>Remy is here to enhance your culinary journey with Plate Passion. Whether you're a seasoned chef or just starting out in the kitchen, Remy is your go-to companion for recipe ideas, cooking tips, and culinary guidance.</p>
  <p>Ask Remy anything about recipes, from inspiration to execution. Need ideas for dinner tonight? Want to elevate your favorite dish? Or perhaps you're curious about different cooking techniques? Remy has you covered.</p>
  <p>While Remy strives for accuracy, it's always wise to double-check important information, especially when it comes to food safety. Your well-being is our top priority, so feel free to verify any advice regarding food handling and storage.</p>
  <p>One of Remy's remarkable features is his ability to sync with the recipe you're currently viewing. No need to repeat yourself—Remy already knows what's cooking! He'll provide tailored assistance based on the recipe you're exploring.</p>
  <p>Plus, Remy keeps track of your conversation history, neatly organized in his virtual chat. Whether you're revisiting a past recipe or picking up where you left off, Remy ensures a seamless cooking experience.</p>
  <p>Join the Plate Passion community and let Remy be your culinary companion. From recipe recommendations to cooking insights, Remy is here to make your kitchen adventures more enjoyable and rewarding.</p>
</div>
<div>
  <h2>About Tokens and Pricing</h2>
  <p>At Plate Passion, we want to ensure that every interaction with Remy, your trusted culinary assistant, is seamless and efficient. To achieve this, we've implemented a token-based system to manage the costs associated with using Remy's services.</p>

  <h3>What are Tokens?</h3>
  <p>Tokens can be thought of as pieces of a words. Before Remy provides a response, your input is broken down into tokens. These tokens represent pieces of words, accounting for factors such as word length and language intricacies.</p>
  
  <ul>
  <li>
    <strong>Example:</strong> In English, one token is approximately equivalent to four characters or three-quarters of a word. For instance, the saying, "A watched pot never boils" contains 5 tokens.
    </li>
</ul>
    <p>To further explore tokenization and calculate the number of tokens in your text, you can use OpenAI's interactive Tokenizer tool <a href="https://platform.openai.com/tokenizer" target="_blank" rel="noopener noreferrer">here</a>.</p>



  <h3>How Tokens Work with Remy</h3>
  <p>Silver and Gold members of Plate Passion receive a monthly allocation of 100,000 tokens each to utilize for interacting with Remy. Whether you're seeking recipe recommendations, cooking tips, or general culinary advice, these tokens cover both the input you provide and the output Remy generates.</p>
  
  <ul>
    <li><strong>Usage:</strong> Each interaction with Remy, whether it's asking for a recipe idea or seeking advice on cooking techniques, consumes a certain number of tokens. The exact number of tokens used depends on factors such as the complexity of the request and the length of the response.</li>
  </ul>


</div>

<h3>Reducing Token Usage</h3>
<p>Optimizing your interactions with Remy can help you chat more without depleting your token allocation. Here are some tips to reduce token usage:</p>

<ul>
  <li><strong>Clear Chat:</strong> If you no longer need the chat history for reference, consider clearing it. Since Remy reviews your chat history each time you make a request, clearing unnecessary conversations will help reduce token consumption. Just ensure you no longer need the history!</li>
  <li><strong>Concise Text Input:</strong> Ensure your text input is clear and concise. Trim any redundancies and be specific about what you need from Remy. You can even ask Remy to be as concise as possible if needed. Remember, there's no need to be overly polite in your requests—eliminate unnecessary words like "please," "sorry," or "thank you" to save tokens.</li>

</ul>
<h3>Need More Information?</h3>
  <p>If you're curious about how tokens are calculated or want to explore tokenization further, feel free to dive into our provided resources or reach out to our support team for assistance.</p>
  
  <p>With Remy and Plate Passion, embark on a culinary journey powered by innovation and convenience.</p>

    </>
  );
}

export default AboutRemy;
