import React from "react";

function ChangeLog() {
  return (
    <div>
      <h2>Change Log</h2>
      <h3>
        <u>April 20, 2024</u>
      </h3>
      <h5>Recipes</h5>
      <ul>
        <li>
          Users can now keep personal notes on each for ALL recipes. These notes are private only to the user that created them.
        </li>
        <li>
          Recipe owners can also add notes to recipes that are available to anyone viewing the recipe.
        </li>
      </ul>
      <h5>Ingredients</h5>
      <ul>
        <li>
          Fixed bug where the page #s would cover the screen if too many pages.
        </li>

      </ul>
      <h3>
        <u>April 14, 2024</u>
      </h3>
      <h5>Weekly Plan</h5>
      <ul>
        <li>
          (Beta) Users can now edit existing entries in the Weekly Plan to
          change qty, days, course, etc.
        </li>
      </ul>
      <h5>Recipes (Shared)</h5>
      <ul>
        <li>
          Shared recipes now show the name of the user that contributed it. You
          can click the name to see all their shared recipes. (Recipes must be
          public).
        </li>
      </ul>
      <h3>
        <u>April 5, 2024</u>
      </h3>
      <h5>Remy Chatbot</h5>
      <ul>
        <li>Now saves chat history for each recipe.</li>
        <li>Interface updates.</li>
      </ul>
      <h3>
        <u>Mar 25, 2024</u>
      </h3>
      <h5>Recipes</h5>
      <ul>
        <li>Added date created and date updated for each recipe.</li>
        <li>Remy has been upgraded to be even more helpful.</li>
        <li>
          Generated recipe images have been improved to be higher quality and
          more closely resemble the dish.
        </li>
      </ul>

      <h5>Other</h5>
      <ul>
        <li>Minor bug fixes</li>
      </ul>

      <h3></h3>
      <h3>
        <u>Oct 31, 2023</u>
      </h3>
      <h5>Shopping List</h5>
      <ul>
        <li>
          On the Shopping List Page, you can now add items to you favorites.
          Once added as a favorite, you'll be able to more easily select them
          and add them to your shopping list.
        </li>
      </ul>
      <h3>
        <u>June 6, 2023</u>
      </h3>
      <h5>Public Recipes</h5>
      <ul>
        <li>
          Silver subscribers can now make their cookbook public with a custom
          URL to all of their recipes.
        </li>
      </ul>
      <h3>
        <u>May 31, 2023</u>
      </h3>
      <h5>Shopping List</h5>
      <ul>
        <li>Fixed bug that was not allowing you to set a unit.</li>
        <li>Fixed multiplyer boxes, now allowing decimals/partials.</li>
      </ul>
      <h5>Weekly Plan</h5>
      <ul>
        <li>Fixed multiplyer boxes, now allowing decimals/parials.</li>
      </ul>
      <h5>Recipes</h5>
      <ul>
        <li>
          Now, you can open recipes in a new tab by right clicking the name of
          the recipes (previously did not give option to open in new tab).
        </li>
      </ul>

      <h3>
        <u>May 28, 2023</u>
      </h3>
      <h5>Edit Ingredients (Conversions)</h5>
      <ul>
        <li>
          Now, when creating/editing unit conversions, you can use the "inverse
          conversion" toggle to make entering conversions easier. Eg, will
          change the conversion statement from{" "}
          <b>
            <i>"How many Servings in 1 Cup"</i>
          </b>{" "}
          to{" "}
          <i>
            <b>"How many Cups in 1 Serving"</b>
          </i>
          .
        </li>
      </ul>
      <h5>Sign Up</h5>
      <ul>
        <li>Reformatted Sign Up Page</li>
        <li>
          Added error message if trying to sign up with a username or email that
          already exists.
        </li>
      </ul>
      <h5>Shopping List Page</h5>
      <ul>
        <li>
          Shopping list page completely redone with new features:
          <ul>
            <li>Added ability to view as Metric/Traditional measurements.</li>
            <li>
              Added "Edit Mode", which allows you to 'Set Unit' for an item.
              'Set Unit' will always display that item in the selected 'unit of
              measurement'. Eg. Always display <b>"Chicken"</b> in{" "}
              <b>"lb(s)"</b> on the shopping list.
            </li>
          </ul>
        </li>
      </ul>
      <h5>View Recipe Page</h5>
      <ul>
        <li>Fixed bug with the multiplyer.</li>
      </ul>
      <h5>Edit Recipe Page</h5>
      <ul>
        <li>
          Now, if you are using the 'Set Unit' feature on an ingredient, the
          button will turn green to show that ingredient is being manually set.
        </li>
      </ul>
      <h3>
        <u>May 24, 2023</u>
      </h3>
      <h5>Edit Recipe Page</h5>
      <ul>
        <li>
          Added feature to set what unit is displayed for an ingredent within a
          recipe.
        </li>
        <li>
          Added feature to create a new ingredient while editing a recipe.
        </li>
        <li>Added feature to edit an ingredients info by clicking the name.</li>
      </ul>
      <h5>Weekly Plan Page</h5>
      <ul>
        <li>
          Fixed bug that was incorrectly calculating calories when the
          multiplyer was used.
        </li>
      </ul>
      <h5>My Customization {`>`} User Ingredients Page</h5>
      <ul>
        <li>
          After creating a new ingredient, now that ingredient immediately pops
          up so you can edit conversions.
        </li>
      </ul>
      <h5>Sign In</h5>
      <ul>
        <li>
          Signing in should now redirect the you to the previous page you were
          on.
        </li>
      </ul>
      <h5>Membership Options</h5>
      <ul>
        <li>Added yearly membership options.</li>
      </ul>
    </div>
  );
}

export { ChangeLog };
