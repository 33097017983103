// Frontend component
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function Verify() {
  const { token } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handleVerify = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/verify`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: token }),
        });

        if (response.ok) {
          setMessage('Thank you for verifying your email!');
        } else {
          setMessage('Failed to verify, please try again.');
        }
      } catch (error) {
        setMessage('Error occurred while verifying email.');
        console.error(error);
      }
    };

    handleVerify();
  }, [token]);

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="p-5 shadow-lg rounded">
        <h2 className="text-lg md:text-xl lg:text-2xl text-center">{message}</h2>
        <p className="text-center mt-4">
          <Link to="/" className="text-blue-500 hover:underline">Go back to home page</Link>
        </p>
      </div>
    </div>
  );
}

export default Verify;
