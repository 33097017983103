import React, { useState, useEffect } from "react";

function SilverFeature() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasSilver, setHasSilver] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      method: 'GET',
      credentials: 'include',
    })
    .then(response => {
      if (response.ok) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoggedIn(false);
    });
    
    fetch(`${process.env.REACT_APP_API_URL}/silver`, {
      method: 'GET',
      credentials: 'include',
    })
    .then(response => {
      if (response.ok) {
        setHasSilver(true);
      } else if (response.status === 403) {
        console.log('Not authorized');
        setHasSilver(false);
      } else {
        console.log('Error');
        setHasSilver(false);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setHasSilver(false);
    });
  }, []);

  return (
    <>
      {isLoggedIn && hasSilver && (
        <div>
          Here is your silver feature content!
        </div>
      )}

      {(!isLoggedIn || !hasSilver) && (
        <div>
          You are not authorized to view this content. Please log in and/or upgrade to the silver membership level.
        </div>
      )}
    </>
  );
}

export default SilverFeature