import React, { useEffect, useState } from "react";
import { Form, Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter, } from "react-bootstrap";
import "../styles/style.scss";
import { useParams } from 'react-router-dom';
import { UncachedImage } from "react-uncached-image";
import Select from "react-select";

function UserRecipeBrowser() {
  const { url } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isPublic, setIsPublic] = useState(false);
  const [recipeUserDisplayName, setRecipeUserDisplayName] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);


  const [recipes, setRecipes] = useState([]);
  const [isTable, setIsTable] = useState(false);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(false);
  const [isFree, setIsFree] = useState(true);

  //Table View Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");


  const tokenizer = (query) =>
  query
    .toLowerCase()
    .split(/[\s,]+/) // split on whitespace or comma
    .filter((token) => token.trim().length > 0);
    
  const filteredRecipesWithoutFree = recipes
  .filter(
    (recipe) =>
      (!onlyUserRecipes || recipe.user_id !== 1) &&
      tokenizer(searchQuery).every((token) =>
        [
          recipe.name.toLowerCase(),
          recipe.tags.toLowerCase(),
          recipe.course.toLowerCase(),
        ].some((field) => field.includes(token))
      )
  )

  let filteredRecipes = filteredRecipesWithoutFree;

  // if (isFree) {
  //   const freeRecipes = filteredRecipesWithoutFree.filter(
  //     (recipe) => recipe.user_id === 1
  //   );
  //   const premiumRecipes = filteredRecipesWithoutFree.filter(
  //     (recipe) => recipe.user_id !== 1
  //   );
  //   const first10PremiumRecipes = premiumRecipes.slice(0, 10);
  //   filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  // }

  const pagesCount = Math.ceil(filteredRecipes.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortedUnits = filteredRecipes.sort((a, b) => {
    let aValue, bValue; // create variables to hold the value for each item being compared
    if (sortedColumn === "calories") {
      aValue = parseFloat(a.total_calories / a.servings);
      bValue = parseFloat(b.total_calories / b.servings);
    } else if (sortedColumn === "cost") {
      aValue = parseFloat(a.total_price / a.servings);
      bValue = parseFloat(b.total_price / b.servings);
    } else {
      aValue = a[sortedColumn];
      bValue = b[sortedColumn];
    }
    // sort based on the calculated or raw values, depending on the column
    if (sortDirection === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setOnlyUserRecipes(user.only_user_recipes === 1);
      setIsTable(user.view_as_table === 1);
      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };

  const handleToggleLayout = () => {
    setIsTable(!isTable);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/table_view`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        view_as_table: isTable ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/public_user_recipes/${url}`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        const removedHiddenRecipes = data.filter(recipe => recipe.hidden === 0 && recipe.public === 1);

        setRecipes(removedHiddenRecipes);

      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

    // Fetch Recipes List
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/api/public_user_recipes_display_name/${url}`, {
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error retrieving recipes from server");
          }
        })
        .then((data) => {
          console.log(data.display_name);
          setRecipeUserDisplayName(data.display_name);
        })
        .catch((err) => {
          console.error(err);
        });
    }, []);
  
 
  useEffect(() => {
    fetchUserSetting();
  }, []);

  // if (!isLoggedIn) {
  //   return <h3>Please <a className="btn btn-primary" href={`/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
  //   Sign In
  // </a> to view</h3>;
  // }


  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        {" "}
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-2">
            <div className="d-flex justify-content-center">
              <Form.Switch
                type="switch"
                id="custom-switch"
                label={"List View"}
                checked={isTable}
                onChange={handleToggleLayout}
              />{" "}
            </div>
          </div>
        </div>
        
      </div>
      {recipeUserDisplayName && (
        <div className="mb-2"><h3>{recipeUserDisplayName}'s Recipes:</h3></div>
      )}
      <input
        type="text"
        className="form-control"
        placeholder="Search recipes"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <br />

      {!isTable && (
        <div className="container-fluid">
          <div className="d-flex justify-content-center overflow-auto flex-wrap">
            {" "}
            <div className="d-flex align-items-center me-2">
              <b>Sort:</b>
            </div>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Recipe"
              onClick={() => handleSort("name")}
            >
              Recipe{" "}
              {sortedColumn === "name" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Course"
              onClick={() => handleSort("course")}
            >
              Course{" "}
              {sortedColumn === "course" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Servings"
              onClick={() => handleSort("servings")}
            >
              Servings{" "}
              {sortedColumn === "servings" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Calories Per Serving"
              onClick={() => handleSort("calories")}
            >
              Calories Per Serving{" "}
              {sortedColumn === "calories" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Cost Per Serving"
              onClick={() => handleSort("cost")}
            >
              Cost Per Serving{" "}
              {sortedColumn === "cost" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
            <button
              className="btn btn-primary me-2 d-block d-sm-inline"
              title="Click to sort by Tags"
              onClick={() => handleSort("tags")}
            >
              Tags{" "}
              {sortedColumn === "tags" && (
                <i
                  className={`fas fa-chevron-${
                    sortDirection === "asc" ? "up" : "down"
                  }`}
                />
              )}
            </button>
          </div>
          <br />
          <div className="row">
            {filteredRecipes.slice(startIndex, endIndex).map((recipe) => (
              <div
                className="col-sm-6 col-md-4 col-lg-3"
                key={recipe.recipe_id}
              >
                <div
                  className="card mb-1" style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`;
                  }}
                >
                  <UncachedImage
                    className="card-img-top"
                    src={recipe.image_url}
                    alt={recipe.name}
                  />
                  <div className="card-body">
                    <h5 className="card-title">                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <b>{recipe.name}{" "}</b>                     
                      </a></h5>

                    <div className="row">
                      <div className="col-12">
                        <b>Course:</b> {recipe.course}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <b>Servings:</b> {recipe.servings}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <b>Calories per serving:</b>{" "}
                        {parseFloat(
                          recipe.total_calories / recipe.servings
                        ).toFixed(0)}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <b>Cost per serving:</b> $
                          {parseFloat(
                            recipe.total_price / recipe.servings
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <b>Tags:</b>{" "}
                          {recipe.tags
                            ? recipe.tags.length > 50
                              ? recipe.tags.slice(0, 50) + "..."
                              : recipe.tags
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isTable && (
        <div>
          <div className="table-responsive">
            {" "}
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("name")}
                  >
                    Recipe{" "}
                    {sortedColumn === "name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("course")}
                  >
                    Course{" "}
                    {sortedColumn === "course" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "course" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("servings")}
                  >
                    Servings{" "}
                    {sortedColumn === "servings" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "servings" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("calories")}
                  >
                    Calories Per Serving{" "}
                    {sortedColumn === "calories" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "calories" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("cost")}
                  >
                    Cost Per Serving{" "}
                    {sortedColumn === "cost" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "cost" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("tags")}
                  >
                    Tags{" "}
                    {sortedColumn === "tags" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "tags" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredRecipes.slice(startIndex, endIndex).map((recipe) => (
                  <tr key={recipe.recipe_id}>
                    <td>

                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <b>{recipe.name}{" "}</b>                     
                      </a>
                    </td>
                    <td className="text-center">{recipe.course}</td>
                    <td className="text-center">{recipe.servings}</td>
                    <td className="text-center">
                      {parseFloat(
                        recipe.total_calories / recipe.servings
                      ).toFixed(0)}
                    </td>
                    <td className="text-center">
                      $
                      {parseFloat(recipe.total_price / recipe.servings).toFixed(
                        2
                      )}
                    </td>
                    <td className="text-center">
                      {recipe.tags
                        ? recipe.tags.length > 50
                          ? recipe.tags.slice(0, 50) + "..."
                          : recipe.tags
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>

        </div>
      )}

    </div>
  );
}

export default UserRecipeBrowser;
