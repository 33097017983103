import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import "../styles/style.scss";
import { UncachedImage } from "react-uncached-image";
import Select from "react-select";

function RecipeHiddenSettings() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [recipes, setRecipes] = useState([]);
  const [isTable, setIsTable] = useState(true);
  const [onlyUserRecipes, setOnlyUserRecipes] = useState(true);
  const [isFree, setIsFree] = useState(true);

  //Table View Variables
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [sortedColumn, setSortedColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  const tokenizer = (query) =>
    query
      .toLowerCase()
      .split(/[\s,]+/) // split on whitespace or comma
      .filter((token) => token.trim().length > 0);

  const filteredRecipesWithoutFree = recipes.filter(
    (recipe) =>
      (!onlyUserRecipes || recipe.user_id !== 1) &&
      tokenizer(searchQuery).every((token) => {
        const fieldsToSearch = [
          recipe.name.toLowerCase(),
          recipe.tags.toLowerCase(),
          recipe.course.toLowerCase(),
          recipe.created_at ? recipe.created_at.toLowerCase() : null,
          recipe.hidden === 0 ? "show" : "hide",
          recipe.public === 0 ? "private" : "public",
        ];
        return fieldsToSearch.some((field) => field && field.includes(token));
      })
  );

  let filteredRecipes = filteredRecipesWithoutFree;

  if (isFree) {
    const freeRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id === 1
    );
    const premiumRecipes = filteredRecipesWithoutFree.filter(
      (recipe) => recipe.user_id !== 1
    );
    const first10PremiumRecipes = premiumRecipes.slice(0, 10);
    filteredRecipes = [...freeRecipes, ...first10PremiumRecipes];
  }

  const pagesCount = Math.ceil(filteredRecipes.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  const sortedUnits = filteredRecipes.sort((a, b) => {
    let aValue, bValue; // create variables to hold the value for each item being compared
    if (sortedColumn === "calories") {
      aValue = parseFloat(a.total_calories / a.servings);
      bValue = parseFloat(b.total_calories / b.servings);
    } else if (sortedColumn === "cost") {
      aValue = parseFloat(a.total_price / a.servings);
      bValue = parseFloat(b.total_price / b.servings);
    } else {
      aValue = a[sortedColumn];
      bValue = b[sortedColumn];
    }
    // sort based on the calculated or raw values, depending on the column
    if (sortDirection === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const fetchUserSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      console.log(user);
      setIsFree(user.membership_level === "free");
    } catch (error) {
      // Handle error
    }
  };

  const [showHideTrigger, setShowHideTrigger] = useState(false);

  const handleShowHide = (selectedOption) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/showhide_recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: selectedOption.recipe_id,
        isHidden: selectedOption.hidden,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      } else {
        // Update the trigger state to force useEffect to run again
        setShowHideTrigger((prev) => !prev);
      }
    });
  };

  const [publicTrigger, setPublicTrigger] = useState(false);

  const handlePublic = (selectedOption) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/publicprivate_recipe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipe_id: selectedOption.recipe_id,
        isPublic: selectedOption.public,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      } else {
        // Update the trigger state to force useEffect to run again
        setPublicTrigger((prev) => !prev);
      }
    });
  };

  // Fetch Recipes List
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/recipes`, {
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error retrieving recipes from server");
        }
      })
      .then((data) => {
        console.log(data);
        setRecipes(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [showHideTrigger, publicTrigger]); // Add showHideTrigger as a dependency

  useEffect(() => {
    fetchUserSetting();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <h3>Recipe Visibility Settings</h3>
      <p>
        <b>Hidden:</b> Recipes set to hidden won't appear in your recipes or
        weekly plan. If you're a Silver/Gold member sharing your recipes, hidden
        recipes won't be visible to the public either. You can always unhide
        your recipes here.
      </p>
      <p>
        <b>Public:</b> By default, all recipes are set to public when created,
        regardless of membership. However, only Silver/Gold members can share
        their recipes with the public. To enable sharing, Silver/Gold members
        must set up their sharing preferences in the "Settings" page. This
        setting allows Silver/Gold members to selectively unshare individual
        recipes, even if they generally share all their recipes.
      </p>
      <div className="container">
        <input
          type="text"
          className="form-control"
          placeholder="Search recipes"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <br />

        {isTable && (
          <div>
            <div className="table-responsive">
              {" "}
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("name")}
                    >
                      Recipe{" "}
                      {sortedColumn === "name" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "name" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("course")}
                    >
                      Course{" "}
                      {sortedColumn === "course" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "course" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("servings")}
                    >
                      Servings{" "}
                      {sortedColumn === "servings" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "servings" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("calories")}
                    >
                      Calories Per Serving{" "}
                      {sortedColumn === "calories" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "calories" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("cost")}
                    >
                      Cost Per Serving{" "}
                      {sortedColumn === "cost" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "cost" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>

                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("recipe_id")}
                    >
                      Date Created{" "}
                      {sortedColumn === "recipe_id" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "recipe_id" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>

                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("tags")}
                    >
                      Tags{" "}
                      {sortedColumn === "tags" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "tags" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("hidden")}
                    >
                      Visibility{" "}
                      {sortedColumn === "hidden" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "hidden" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                    <th
                      className="text-center clickable"
                      title="Click to sort"
                      onClick={() => handleSort("public")}
                    >
                      Public/Private{" "}
                      {sortedColumn === "public" && (
                        <i
                          className={`fas fa-chevron-${
                            sortDirection === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                      {sortedColumn !== "public" && (
                        <i className="fas fa-chevron-down fa-opac" />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecipes.slice(startIndex, endIndex).map((recipe) => (
                    <tr key={recipe.recipe_id}>
                      <td>
                        {recipe.hidden === 0 ? (
                          <button
                            className="btn btn-sm btn-outline-success mb-0"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleShowHide(recipe);
                            }}
                          >
                            Shown
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-outline-danger mb-0"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleShowHide(recipe);
                            }}
                          >
                            Hidden
                          </button>
                        )}{" "}
                        {recipe.public === 0 ? (
                          <button
                            className="btn btn-sm btn-outline-danger mb-0"
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePublic(recipe);
                            }}
                          >
                            Private
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-outline-success mb-0"
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePublic(recipe);
                            }}
                          >
                            Public
                          </button>
                        )}
                        <a
                          href={`${process.env.REACT_APP_CLIENT_URL}/view-recipe/${recipe.recipe_id}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <b> {recipe.name} </b>
                        </a>
                      </td>
                      <td className="text-center">{recipe.course}</td>
                      <td className="text-center">{recipe.servings}</td>
                      <td className="text-center">
                        {parseFloat(
                          recipe.total_calories / recipe.servings
                        ).toFixed(0)}
                      </td>
                      <td className="text-center">
                        $
                        {parseFloat(
                          recipe.total_price / recipe.servings
                        ).toFixed(2)}
                      </td>
                      <td className="text-center">
                        {new Date(recipe.created_at).toLocaleDateString()}
                      </td>
                      <td className="text-center">
                        {recipe.tags
                          ? recipe.tags.length > 50
                            ? recipe.tags.slice(0, 50) + "..."
                            : recipe.tags
                          : ""}
                      </td>
                      <td className="text-center">
                        {recipe.hidden === 0 ? "Show" : "Hide"}
                      </td>
                      <td className="text-center">
                        {recipe.public === 0 ? "Private" : "Public"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecipeHiddenSettings;
