import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import RecipeBrowser from "./components/RecipeBrowser";
import MembershipOptions from "./components/MembershipOptions";
import MembershipOptionsTest from "./components/MembershipOptionsTest";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Navbar from "./components/Navbar";
import SilverFeature from "./components/SilverFeature";
import Ingredients from "./components/UserIngredients";
import Units from "./components/UserUnits";
import ShoppingAisles from "./components/ShoppingAisles";
import EditRecipe from "./components/EditRecipe";
import ViewRecipe from "./components/ViewRecipe";
import WeeklyPlan from "./components/WeeklyPlan";
import ShoppingList from "./components/ShoppingList";
import { Success } from "./components/PaymentComponents";
import { ChangeLog } from "./components/ChangeLog";
import { GettingStarted } from "./components/GettingStarted";
import Spinner from "react-spinner-material";
import AdminIngredients from "./components/AdminIngredients";
import AdminUnits from "./components/AdminUnits";
import NonCookingItems from "./components/NonCookingItems";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import Verify from "./components/Verify";
import UserRecipeBrowser from "./components/UserRecipeBrowser";
import { UserSettings } from "./components/UserSettings";
import AboutRemy from "./components/AboutRemy";
import RecipeHiddenSettings from "./components/RecipeHiddenSettings";
import MyMeals from "./components/MyMeals";
import MealBrowser from "./components/MealBrowser";
import "../src/styles/style.scss";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="App">
      <div className="background-one"></div>
      <div className="container">
        <Navbar />
        <div className="container">
          <div className="container bg-light rounded px-5 py-3">
            {isLoading ? (
              <div className="spinner-container">
                <Spinner
                  radius={100}
                  color={"#333"}
                  stroke={2}
                  visible={true}
                />
              </div>
            ) : (
              <BrowserRouter>
                <Routes key={window.location.pathname}>
                <Route path="/" element={<GettingStarted />} />
                <Route path="/changelog" element={<ChangeLog />} />
                  <Route path="/success" element={<Success />} />
                  <Route path="/recipes" element={<RecipeBrowser />} />
                  <Route path="/recipe_view_settings" element={<RecipeHiddenSettings/>} />
                  <Route
                    path="/membership_options"
                    element={<MembershipOptions />}
                  />
                    <Route
                    path="/membership_options/test"
                    element={<MembershipOptionsTest />}
                  />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/user_ingredients" element={<Ingredients />} />
                  <Route path="/user_units" element={<Units />} />
                  <Route path="/shopping_aisles" element={<ShoppingAisles />} />
                  <Route path="/edit-recipe/:id" element={<EditRecipe />} />
                  <Route path="/view-recipe/:id" element={<ViewRecipe />} />
                  <Route path="/silverfeature" element={<SilverFeature />} />
                  <Route path="/weekly-plan" element={<WeeklyPlan />} />
                  <Route path="/shopping" element={<ShoppingList />} />
                  <Route path="/non-cooking-items" element={<NonCookingItems />} />
                  <Route path="/admin-ingredients" element={<AdminIngredients />} />
                  <Route path="/admin-units" element={<AdminUnits />} />
                  <Route path="/forgot-password" element={<ForgotPasswordForm />} />
                  <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
                  <Route path="/verify/:token" element={<Verify />} />
                  <Route path="/recipes/:url" element={<UserRecipeBrowser />} />
                  <Route path="/user/:id" element={<UserSettings />} />
                  <Route path="/about_remy" element={<AboutRemy />} />
                  <Route path="/my_meals" element={<MyMeals />} />
                  <Route path="/meals" element={<MealBrowser />} />
                </Routes>
              </BrowserRouter>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
