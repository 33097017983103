import React, { useState } from 'react';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage({ text: data.message, success: true });
      } else {
        setMessage({ text: data.errorMessage, success: false });
      }
    } catch (error) {
      setMessage({ text: 'An error occurred. Please try again later.', success: false });
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto" style={{ maxWidth: "720px" }}>
      <h2 className="mt-3">Forgot Password</h2>
      <form className="p-3 shadow-lg rounded mt-3" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-2">Reset Password</button>
      </form>
      {message && (
        <div className={`alert ${message.success ? 'alert-success' : 'alert-danger'}`} role="alert">
          {message.text}
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordForm;
