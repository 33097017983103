import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "../styles/style.scss";

function UserSettings() {
  const { id } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [isMetric, setIsMetric] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [urlName, setUrlName] = useState("");

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSaveChanges = () => {
    setEditMode(!editMode);
  
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/public-display`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        displayName: displayName,
        urlName: urlName,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            // Handle error
            const errorMessage = data.errorMessage;
            alert(errorMessage);
            // Prevent changes by reverting to the previous state
            setEditMode(!editMode);
            setUrlName("")

          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle network or server errors
        alert("An error occurred. Please try again later.");
        // Prevent changes by reverting to the previous state
        setEditMode(!editMode);
      });
  };

  const handleToggle = () => {
    setIsMetric(!isMetric);

    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        default_unit_of_measurement: isMetric ? "traditional" : "metric",
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const handlePublicToggle = () => {
    if (!hasSilver) {
      alert("A Silver subscription is required for this feature");
      return;
    }
    
    setIsPublic(!isPublic);
    // Make a POST request to update user's default_unit_of_measurement value
    fetch(`${process.env.REACT_APP_API_URL}/api/updateUser/public`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        public: isPublic ? 0 : 1,
      }),
    }).then((response) => {
      if (!response.ok) {
        // Handle error
      }
    });
  };

  const fetchUserMetricSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/getUser`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { user } = await response.json();

      if (!user) {
        // Handle error
      }
      setCurrentUser(user.id);
      setIsMetric(user.default_unit_of_measurement === "metric");
      setIsPublic(user.recipes_public);
      setDisplayName(user.display_name);
      setUrlName(user.public_url_name);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, [id]);

  const [hasGold, setHasGold] = useState(false);
  const [hasSilver, setHasSilver] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/gold`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasGold(true);
          setHasSilver(true);
          console.log("Gold");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasGold(false);
        } else {
          console.log("Error");
          setHasGold(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasGold(false);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/silver`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setHasSilver(true);
          console.log("Silver");
        } else if (response.status === 403) {
          console.log("Not authorized");
          setHasSilver(false);
        } else {
          console.log("Error");
          setHasSilver(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasSilver(false);
      });
  }, [id]);

  //Handle Form Changes
  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };

  //Handle Form Changes
  const handleUrlNameChange = (event) => {
    const value = event.target.value;
    
    // Remove spaces and slashes from the input value
    const sanitizedValue = value.replace(/[^\w-]/g, '');
  
    setUrlName(sanitizedValue);
  };

  useEffect(() => {
    // Fetch ingredients
    fetchUserMetricSetting();
  }, []);

  return (
    <div>
      <div
        className="container rounded p-2 mb-4"
        style={{ backgroundColor: "#444444", color: "white" }}
      >
        <div className="col-sm-2">
          <div className="d-flex justify-content-center">
            <Form.Switch
              type="switch"
              id="custom-switch"
              label="Use Metric"
              checked={isMetric}
              onChange={handleToggle}
            />
          </div>
        </div>
      </div>

      <div className="col-sm-2">
      <OverlayTrigger
          delay={{ hide: 150, show: 150 }}
          overlay={(props) => (
            <Tooltip {...props}>
              Use to make the below public URL active for anyone to browse your recipes.
            </Tooltip>
          )}
          placement="bottom"
        >
          <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
        </OverlayTrigger>
        <div className="d-flex justify-content-left mb-2">
          <Form.Switch
            type="switch"
            id="custom-switch-public"
            label="Make Recipe's Public"
            checked={isPublic}
            onChange={handlePublicToggle}
          />                  
        </div>
        
      </div>

      <div className="col-sm-2">
        <div className="d-flex justify-content-left mb-2">
          {editMode ? (
            <Button variant="outline-primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleEditToggle}>
              Enter Edit Mode
            </Button>
          )}
        </div>
      </div>

      {editMode ? (
        <div>
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <label className="mr-2">
                  <OverlayTrigger
          delay={{ hide: 150, show: 150 }}
          overlay={(props) => (
            <Tooltip {...props}>
              The name that is displayed on your public page.
            </Tooltip>
          )}
          placement="bottom"
        >
          <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
        </OverlayTrigger>
                    <b>Display Name:</b>
                  </label>
                  <div className="d-inline-flex">
                    <input
                      type="text"
                      className="form-control"
                      value={displayName}
                      onChange={handleDisplayNameChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-sm-2">
            <OverlayTrigger
          delay={{ hide: 150, show: 150 }}
          overlay={(props) => (
            <Tooltip {...props}>
              Your own public page for your recipes.
            </Tooltip>
          )}
          placement="bottom"
        >
          <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
        </OverlayTrigger>
              <a className="dropdown-item" href="/meals"><b>Public URL:</b> </a>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="mr-2">platepassion.com/recipes/</label>
                <div className="d-inline-flex">
                  <input
                    type="text"
                    className="form-control"
                    value={urlName}
                    onChange={handleUrlNameChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <label className="mr-2">
                  <OverlayTrigger
          delay={{ hide: 150, show: 150 }}
          overlay={(props) => (
            <Tooltip {...props}>
              The name that is displayed on your public page.
            </Tooltip>
          )}
          placement="bottom"
        >
          <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
        </OverlayTrigger>
                    <b>Display Name:</b>
                  </label>
                  <div className="row">
                    
              <div className="col-sm-6">
                
<p>{displayName}</p>
                  </div> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-sm-2">
            <OverlayTrigger
          delay={{ hide: 150, show: 150 }}
          overlay={(props) => (
            <Tooltip {...props}>
              Your own public page for your recipes.
            </Tooltip>
          )}
          placement="bottom"
        >
          <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
        </OverlayTrigger>
              <b>Public URL:</b>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="mr-2">platepassion.com/recipes/{urlName}</label>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { UserSettings };
