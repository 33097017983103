import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "../styles/style.scss";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function Ingredients() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [inverseConversion, setInverseConversion] = useState(false);

  const handleToggleConversion = () => {
    setInverseConversion(!inverseConversion);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  // variable for unit options
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitOption, setSelectedUnitOption] = useState(null);

  // var for shopping options:
  const [shoppingOptions, setShoppingOptions] = useState([]);

  // variables for adding new ingredient:
  const [showNewModal, setShowNewModal] = useState(false);
  const [newIngredientName, setNewIngredientName] = useState("");
  const [newUnitOfMeasurement, setNewUnitOfMeasurement] = useState("");
  const [newUnitOfMeasurementLabel, SetNewUnitOfMeasurementLabel] =
    useState("");
  const [newShoppingAisle, setNewShoppingAisle] = useState("");
  const [newProtein, setNewProtein] = useState("");
  const [newCarbs, setNewCarbs] = useState("");
  const [newFat, setNewFat] = useState("");
  const [newFiber, setNewFiber] = useState("");
  const [newSugar, setNewSugar] = useState("");
  const [newStoreAmount, setNewStoreAmount] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newCalculatedPrice, setNewCalculatedPrice] = useState(0);
  const [newCustomUnitFlag, setNewCustomUnitFlag] = useState(0);

  // variables for editing ingredient
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [editIngredientName, setEditIngredientName] = useState("");
  const [editUnitOfMeasurement, setEditUnitOfMeasurement] = useState("");
  const [editUnitOfMeasurementId, setEditUnitOfMeasurementId] = useState("");
  const [editShoppingAisle, setEditShoppingAisle] = useState("");
  const [editShoppingAisleId, setEditShoppingAisleId] = useState("");
  const [editProtein, setEditProtein] = useState("");
  const [editCarbs, setEditCarbs] = useState("");
  const [editFat, setEditFat] = useState("");
  const [editFiber, setEditFiber] = useState("");
  const [editSugar, setEditSugar] = useState("");
  const [editStoreAmount, setEditStoreAmount] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editCalculatedPrice, setEditCalculatedPrice] = useState(0);
  const [editCustomUnitFlag, setEditCustomUnitFlag] = useState(0);
  const [editCustomIngredientFlag, setEditCustomIngredientFlag] = useState(0);
  const [ingredientConversionList, setIngredientConversionList] = useState([]);
  const [addConversionInput, setAddConversionInput] = useState("");

  // variables for table settings (search, sort, paging)
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortedColumn, setSortedColumn] = useState("ingredient_name");
  const [sortDirection, setSortDirection] = useState("asc");

  // Variable
  const [selectedIngredientToHide, setSelectedIngredientToHide] =
    useState(null);
  // Variable
  const [selectedIngredientToUnhide, setSelectedIngredientToUnhide] =
    useState(null);

  // Variables for Editing Conversions
  const [selectedConversionToEdit, setSelectedConversionToEdit] =
    useState(null);
  const [selectedConversionToEditId, setSelectedConversionToEditId] =
    useState(null);
  const [editConversionUnitOfMeasurement, setEditConversionUnitOfMeasurement] =
    useState("");
  const [
    editConversionUnitOfMeasurementId,
    setEditConversionUnitOfMeasurementId,
  ] = useState("");
  const [editConversionCustomUnitFlag, setEditConversionCustomUnitFlag] =
    useState(0);
  const [editConversionFactor, setEditConversionFactor] = useState("");

  const [ingredients, setIngredients] = useState([]);

  const handleHideIngredient = (ingredient) => {
    setSelectedIngredientToHide(ingredient);
  };

  const handleUnhideIngredient = (ingredient) => {
    setSelectedIngredientToUnhide(ingredient);
  };

  const handleEditConversion = (conversion) => {
    setSelectedConversionToEdit(conversion);
    setSelectedConversionToEditId(conversion.id);
    setEditConversionUnitOfMeasurement(conversion.unit_name);
    setEditConversionUnitOfMeasurementId(conversion.unit_id);
    setEditConversionCustomUnitFlag(conversion.custom_unit);
    setEditConversionFactor(conversion.conversion_factor);
    console.log(conversion);
  };

  // fetch available unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/units`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setUnitOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  // fetch shopping list unit options:
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_aisle`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setShoppingOptions(data))
      .catch((error) => console.error("Error retrieving units:", error));
  }, []);

  // const handleShoppingChange = (event) => {
  //   setNewShoppingAisle(event.target.value);
  // };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchIngredients();
  }, []);

  // Add New Unit Modal Show/Hide
  const handleAddClickModal = () => {
    setShowNewModal(true);
  };

  const handleAddCloseModal = () => {
    setShowNewModal(false);
  };

  // Active Table Setup
  //
  // Set up filter
  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.ingredient_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Set up pagnation
  const pagesCount = Math.ceil(filteredIngredients.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  
  // handle page paging
  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  // handle page paging
  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };


  // set up sorting
  const sortedUnits = filteredIngredients.sort((a, b) => {
    try {
      if (sortDirection === "asc") {
        return a[sortedColumn].toLowerCase() > b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      } else {
        return a[sortedColumn].toLowerCase() < b[sortedColumn].toLowerCase()
          ? 1
          : -1;
      }
    } catch {}
  });

  // handle sorting
  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  // Fetch Ingredients for Chart
  const fetchIngredients = () => {
    fetch(`${process.env.REACT_APP_API_URL}/ingredients`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIngredients(data);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  // Add New Unit Handle Form Changes
  const handleNewIngredientNameChange = (event) => {
    setNewIngredientName(event.target.value);
  };

  const handleNewStoreBaseAmountChange = (event) => {
    const value = event.target.value;
    setNewStoreAmount(value);
    setNewCalculatedPrice(newPrice / value);
  };

  const handleNewStorePriceAmountChange = (event) => {
    const value = event.target.value;
    setNewPrice(value);
    setNewCalculatedPrice(value / newStoreAmount);
  };

  // New Unit Macros:
  const handleNewProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setNewProtein(newProteinValue);
    } else {
      setNewProtein(""); // Set a default value
    }
  };

  const handleNewCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setNewCarbs(newCarbsValue);
    } else {
      setNewCarbs(""); // Set a default value
    }
  };

  const handleNewFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setNewFat(newFatValue);
    } else {
      setNewFat(""); // Set a default value
    }
  };

  const handleNewFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setNewFiber(newFiberValue);
    } else {
      setNewFiber(""); // Set a default value
    }
  };

  const handleNewSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setNewSugar(newSugarValue);
    } else {
      setNewSugar(""); // Set a default value
    }
  };
  const handleAddIngredient = () => {
    // Check that required fields have a value
    if (!newIngredientName) {
      alert("Please enter an ingredient name.");
      return;
    }
    if (!newUnitOfMeasurement) {
      alert("Please select a unit of measurement for the ingredient.");
      return;
    }
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-ingredient`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientName: newIngredientName.trim(),
        unitOfMeasurement: newUnitOfMeasurement,
        customUnitFlag: newCustomUnitFlag,
        shoppingAisleId: newShoppingAisle,
        protein: newProtein,
        carbs: newCarbs,
        fat: newFat,
        fiber: newFiber,
        sugar: newSugar,
        price: newCalculatedPrice,
        storePrice: newPrice,
        storeAmount: newStoreAmount,
      }),
    })
      .then((res) => {
        if (res.ok) {
          // Parse the JSON response
          return res.json();
        } else {
          throw new Error("Error adding new ingredient");
        }
      })
      .then((resultIngredient) => {
        // Set variables based on the ingredient data
        // Assuming ingredient contains properties such as id, name, unitOfMeasurement, etc.

        // Set your variables with the retrieved values
        // Example:
        console.log(resultIngredient[0]);
        setSelectedIngredient(resultIngredient[0]);
        setEditIngredientName(resultIngredient[0].ingredient_name);
        setEditUnitOfMeasurement(resultIngredient[0].unit_id);
        setEditUnitOfMeasurementId(resultIngredient[0].default_unit_id);
        setEditShoppingAisle(resultIngredient[0].shopping_aisle);
        setEditShoppingAisleId(resultIngredient[0].custom_shopping_aisle_id);
        setEditProtein(resultIngredient[0].protein);
        setEditCarbs(resultIngredient[0].carbs);
        setEditFat(resultIngredient[0].fat);
        setEditFiber(resultIngredient[0].fiber);
        setEditSugar(resultIngredient[0].sugar);
        setEditStoreAmount(resultIngredient[0].store_amount);
        setEditPrice(resultIngredient[0].store_price);
        setEditCalculatedPrice(resultIngredient[0].price);
        setEditCustomUnitFlag(resultIngredient[0].custom_unit);

        // Reload units data
        fetchIngredients();
        setNewIngredientName("");
        setNewUnitOfMeasurement("");
        SetNewUnitOfMeasurementLabel("");
        setNewShoppingAisle("");
        setNewProtein("");
        setNewCarbs("");
        setNewFat("");
        setNewFiber("");
        setNewSugar("");
        setNewStoreAmount("");
        setNewPrice("");
        setNewCalculatedPrice(0);
        setNewCustomUnitFlag(0);
        handleAddCloseModal();
      })
      .catch((err) => {
        console.error("Error adding new ingredient:", err);
      });
  };

  //
  // Editing Units
  //

  const handleAddConversionInput = (newConversionValue) => {
    if (!isNaN(newConversionValue)) {
      setAddConversionInput(newConversionValue);
    } else {
      setAddConversionInput(""); // Set a default value
    }
  };
  
  const handleEditConversionInput = (newConversionValue) => {
    if (!isNaN(newConversionValue)) {
      setEditConversionFactor(newConversionValue);
    } else {
      setEditConversionFactor(""); // Set a default value
    }
  };
  
  // Funciton to Fetch Unit Converions of the Selected Ingredient
  const fetchIngredientConversions = async (id) => {
    // Determine custom_ingredient flag
    const customIngredientFlag = id.includes("user") ? 1 : 0;

    const ingredientId = id.split(",")[0];

    // Make API call to retrieve unit conversions
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/ingredient-conversions/${ingredientId}?custom_ingredient=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(response.statusText);
      return [];
    }
  };

  const handleSelectIngredient = async (ingredient) => {
    console.log(ingredient);
    setSelectedIngredient(ingredient);
    setEditIngredientName(ingredient.ingredient_name);
    setEditUnitOfMeasurement(ingredient.unit_id);
    setEditUnitOfMeasurementId(ingredient.default_unit_id);
    setEditShoppingAisle(ingredient.shopping_aisle);
    setEditShoppingAisleId(ingredient.custom_shopping_aisle_id);
    setEditProtein(ingredient.protein);
    setEditCarbs(ingredient.carbs);
    setEditFat(ingredient.fat);
    setEditFiber(ingredient.fiber);
    setEditSugar(ingredient.sugar);
    setEditStoreAmount(ingredient.store_amount);
    setEditPrice(ingredient.store_price);
    setEditCalculatedPrice(ingredient.price);
    setEditCustomUnitFlag(ingredient.custom_unit);

    const data = await fetchIngredientConversions(ingredient.id);
    setIngredientConversionList(data);
  };

  // Add Edit Unit Handle Form Changes
  const handleEditIngredientNameChange = (event) => {
    setEditIngredientName(event.target.value);
  };

  const handleEditStoreBaseAmountChange = (event) => {
    const value = event.target.value;
    setEditStoreAmount(value);
    setEditCalculatedPrice(editPrice / value);
  };

  const handleEditStorePriceAmountChange = (event) => {
    const value = event.target.value;
    setEditPrice(value);
    setEditCalculatedPrice(value / editStoreAmount);
  };

  // Edit Unit Macros:
  const handleEditProteinChange = (newProteinValue) => {
    if (!isNaN(newProteinValue)) {
      setEditProtein(newProteinValue);
    } else {
      setEditProtein(""); // Set a default value
    }
  };

  const handleEditCarbsChange = (newCarbsValue) => {
    if (!isNaN(newCarbsValue)) {
      setEditCarbs(newCarbsValue);
    } else {
      setEditCarbs(""); // Set a default value
    }
  };

  const handleEditFatChange = (newFatValue) => {
    if (!isNaN(newFatValue)) {
      setEditFat(newFatValue);
    } else {
      setEditFat(""); // Set a default value
    }
  };

  const handleEditFiberChange = (newFiberValue) => {
    if (!isNaN(newFiberValue)) {
      setEditFiber(newFiberValue);
    } else {
      setEditFiber(""); // Set a default value
    }
  };

  const handleEditSugarChange = (newSugarValue) => {
    if (!isNaN(newSugarValue)) {
      setEditSugar(newSugarValue);
    } else {
      setEditSugar(""); // Set a default value
    }
  };

  const handleSaveIngredient = () => {
    if (selectedIngredient.id.includes("user")) {
      const id = selectedIngredient.id.split(",")[0];
      // This is a user ingredient, send PUT request to backend
      fetch(`${process.env.REACT_APP_API_URL}/edit-ingredient?id=${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          ingredientName: editIngredientName.trim(),
          unitOfMeasurement: editUnitOfMeasurementId,
          customUnitFlag: editCustomUnitFlag,
          shoppingAisleId: editShoppingAisleId,
          protein: editProtein,
          carbs: editCarbs,
          fat: editFat,
          fiber: editFiber,
          sugar: editSugar,
          price: editCalculatedPrice,
          storePrice: editPrice,
          storeAmount: editStoreAmount,
        }),
      })
        .then((res) => {
          if (res.ok) {
            // Reload ingredients data
            fetchIngredients();
            fetchHiddenIngredients();
            setSelectedIngredient(null);
            setEditIngredientName("");
            setEditUnitOfMeasurement("");
            setEditUnitOfMeasurementId("");
            setEditShoppingAisle("");
            setEditShoppingAisleId("");
            setEditProtein("");
            setEditCarbs("");
            setEditFat("");
            setEditFiber("");
            setEditSugar("");
            setEditStoreAmount("");
            setEditPrice("");
            setEditCalculatedPrice(0);
            setEditCustomUnitFlag(0);
          } else {
            throw new Error("Error updating ingredient");
          }
        })
        .catch((err) => {
          console.error("Error updating ingredient:", err);
        });
    } else {
      const id = selectedIngredient.id.split(",")[0];
      // This is not a user ingredient, query user_ingredient_customizations table
      const url = `${process.env.REACT_APP_API_URL}/user-ingredient-customization?ingredient_id=${id}`;
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error querying user ingredient customization");
          }
        })
        .then((data) => {
          console.log(data);
          try {
            if (data.length === 0) {
              // No match, create new entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/user-ingredient-customization`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    shoppingAisleId: editShoppingAisleId,
                    price: editCalculatedPrice,
                    storePrice: editPrice,
                    storeAmount: editStoreAmount,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    fetchIngredients();
                    fetchHiddenIngredients();
                    setSelectedIngredient(null);
                    setEditIngredientName("");
                    setEditUnitOfMeasurement("");
                    setEditUnitOfMeasurementId("");
                    setEditShoppingAisle("");
                    setEditShoppingAisleId("");
                    setEditProtein("");
                    setEditCarbs("");
                    setEditFat("");
                    setEditFiber("");
                    setEditSugar("");
                    setEditStoreAmount("");
                    setEditPrice("");
                    setEditCalculatedPrice(0);
                    setEditCustomUnitFlag(0);
                  } else {
                    throw new Error(
                      "Error creating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error creating user ingredient customization:",
                    err
                  );
                });
            } else {
              // Match found, update existing entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/user-ingredient-customization/${data[0].id}`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    shoppingAisleId: editShoppingAisleId,
                    price: editCalculatedPrice,
                    storePrice: editPrice,
                    storeAmount: editStoreAmount,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    fetchIngredients();
                    fetchHiddenIngredients();
                    setSelectedIngredient(null);
                    setEditIngredientName("");
                    setEditUnitOfMeasurement("");
                    setEditUnitOfMeasurementId("");
                    setEditShoppingAisle("");
                    setEditShoppingAisleId("");
                    setEditProtein("");
                    setEditCarbs("");
                    setEditFat("");
                    setEditFiber("");
                    setEditSugar("");
                    setEditStoreAmount("");
                    setEditPrice("");
                    setEditCalculatedPrice(0);
                    setEditCustomUnitFlag(0);
                  } else {
                    throw new Error(
                      "Error updating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error updating user ingredient customization:",
                    err
                  );
                });
            }
          } catch (err) {
            console.error("Error parsing JSON response:", err);
          }
        })
        .catch((err) => {
          console.error("Error querying user ingredient customization:", err);
        });
    }
  };

  const handleAddUnitConversion = () => {
    if (!addConversionInput) {
      alert("Please add a conversion qty.");
      return;
    }
    const conversionFactor = inverseConversion
      ? 1 / addConversionInput
      : addConversionInput;

    const customIngredientFlag = selectedIngredient.id.includes("user") ? 1 : 0;
    const ingredientId = selectedIngredient.id.split(",")[0];

    // Check for existing unit conversion
    fetch(
      `${process.env.REACT_APP_API_URL}/api/check-unit-conversion?ingredientId=${ingredientId}&newUnitOfMeasurement=${newUnitOfMeasurement}&newCustomUnitFlag=${newCustomUnitFlag}&customIngredientFlag=${customIngredientFlag}`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          // No existing conversion found, continue with adding a new conversion
          return fetch(
            `${process.env.REACT_APP_API_URL}/api/add-unit-conversion`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customIngredientFlag: customIngredientFlag,
                ingredientId: ingredientId,
                newUnitOfMeasurement: newUnitOfMeasurement,
                newCustomUnitFlag: newCustomUnitFlag,
                addConversionInput: conversionFactor,
              }),
            }
          );
        } else {
          // Existing conversion found, display error message
          const error =
            "Duplicate Entry - You already have an existing conversion for this unit";
          throw new Error(error);
        }
      })
      .then((response) => response.json())
      .then((data) => {
        //Fetch the updated ingredient conversions after successful addition
        fetchIngredientConversions(selectedIngredient.id)
          .then((data) => setIngredientConversionList(data))
          .catch((error) => console.error(error));
        //Clear the addConversionInput field
        setAddConversionInput("");
      })
      .catch((error) => {
        console.error(error.message);
        alert(error.message);
      });
  };

  // Update Unit Conversion
  const handleUpdateUnitConversion = async () => {
    try {
      const conversionFactor = inverseConversion
      ? 1 / editConversionFactor
      : editConversionFactor;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/${selectedConversionToEditId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            unit_id: editConversionUnitOfMeasurementId,
            custom_unit: editConversionCustomUnitFlag,
            conversion_factor: conversionFactor,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Update the state with the new values
      setSelectedConversionToEdit({
        id: selectedConversionToEditId,
        unit_id: editConversionUnitOfMeasurementId,
        custom_unit: editConversionCustomUnitFlag,
        conversion_factor: editConversionFactor,
      });

      // Clear the form fields
      fetchIngredientConversions(selectedIngredient.id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
      setSelectedConversionToEdit(null);
      setEditConversionUnitOfMeasurement("");
      setEditConversionUnitOfMeasurementId("");
      setEditConversionCustomUnitFlag(0);
      setEditConversionFactor("");
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Delete Conversion
  const handleDeleteConversion = async (conversion) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the conversion "${conversion.unit_name}"?`
    );

    if (!confirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/conversions/delete/${conversion.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Remove the deleted conversion from the ingredientConversionList state
      fetchIngredientConversions(selectedIngredient.id)
        .then((data) => setIngredientConversionList(data))
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
  //hide unit
  const handleConfirmHideIngredient = async () => {
    if (selectedIngredientToHide.id.includes("user")) {
      const id = selectedIngredientToHide.id.split(",")[0];
      // This is a user ingredient, send PUT request to backend
      fetch(`${process.env.REACT_APP_API_URL}/user/hide-ingredient?id=${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          display: 0,
        }),
      })
        .then((res) => {
          if (res.ok) {
            // Reload ingredients data
            setSelectedIngredientToHide(null);
            // Reload your table data here to reflect the updated display status
            fetchIngredients();
            fetchHiddenIngredients();
          } else {
            throw new Error("Error updating ingredient");
          }
        })
        .catch((err) => {
          console.error("Error updating ingredient:", err);
        });
    } else {
      const id = selectedIngredientToHide.id.split(",")[0];
      // This is not a user ingredient, query user_ingredient_customizations table
      const url = `${process.env.REACT_APP_API_URL}/user-ingredient-customization?ingredient_id=${id}`;
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error querying user ingredient customization");
          }
        })
        .then((data) => {
          console.log(data);
          try {
            if (data.length === 0) {
              // No match, create new entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/default/new-hide-ingredient?id=${id}`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    display: 0,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    // Reload ingredients data
                    setSelectedIngredientToHide(null);
                    // Reload your table data here to reflect the updated display status
                    fetchIngredients();
                    fetchHiddenIngredients();
                  } else {
                    throw new Error(
                      "Error creating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error creating user ingredient customization:",
                    err
                  );
                });
            } else {
              // Match found, update existing entry in user_ingredient_customizations table
              console.log(data[0].id);
              fetch(
                `${process.env.REACT_APP_API_URL}/default/hide-ingredient/${data[0].id}`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    display: 0,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    // Reload ingredients data
                    setSelectedIngredientToHide(null);
                    // Reload your table data here to reflect the updated display status
                    fetchIngredients();
                    fetchHiddenIngredients();
                  } else {
                    throw new Error(
                      "Error updating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error updating user ingredient customization:",
                    err
                  );
                });
            }
          } catch (err) {
            console.error("Error parsing JSON response:", err);
          }
        })
        .catch((err) => {
          console.error("Error querying user ingredient customization:", err);
        });
    }
  };

  //UNHIDE unit
  const handleConfirmUnhideIngredient = async () => {
    if (selectedIngredientToUnhide.id.includes("user")) {
      const id = selectedIngredientToUnhide.id.split(",")[0];
      // This is a user ingredient, send PUT request to backend
      fetch(
        `${process.env.REACT_APP_API_URL}/user/unhide-ingredient?id=${id}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            display: 1,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            // Reload ingredients data
            setSelectedIngredientToUnhide(null);
            // Reload your table data here to reflect the updated display status
            fetchIngredients();
            fetchHiddenIngredients();
          } else {
            throw new Error("Error updating ingredient");
          }
        })
        .catch((err) => {
          console.error("Error updating ingredient:", err);
        });
    } else {
      const id = selectedIngredientToUnhide.id.split(",")[0];
      // This is not a user ingredient, query user_ingredient_customizations table
      const url = `${process.env.REACT_APP_API_URL}/user-ingredient-customization?ingredient_id=${id}`;
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error querying user ingredient customization");
          }
        })
        .then((data) => {
          console.log(data);
          try {
            if (data.length === 0) {
              // No match, create new entry in user_ingredient_customizations table
              fetch(
                `${process.env.REACT_APP_API_URL}/default/new-unhide-ingredient?id=${id}`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    display: 1,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    // Reload ingredients data
                    setSelectedIngredientToUnhide(null);
                    // Reload your table data here to reflect the updated display status
                    fetchIngredients();
                    fetchHiddenIngredients();
                  } else {
                    throw new Error(
                      "Error creating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error creating user ingredient customization:",
                    err
                  );
                });
            } else {
              // Match found, update existing entry in user_ingredient_customizations table
              console.log(data[0].id);
              fetch(
                `${process.env.REACT_APP_API_URL}/default/unhide-ingredient/${data[0].id}`,
                {
                  method: "PUT",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    display: 1,
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    // Reload ingredients data
                    // Reload ingredients data
                    setSelectedIngredientToUnhide(null);
                    // Reload your table data here to reflect the updated display status
                    fetchIngredients();
                    fetchHiddenIngredients();
                  } else {
                    throw new Error(
                      "Error updating user ingredient customization"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error updating user ingredient customization:",
                    err
                  );
                });
            }
          } catch (err) {
            console.error("Error parsing JSON response:", err);
          }
        })
        .catch((err) => {
          console.error("Error querying user ingredient customization:", err);
        });
    }
  };

  // Hidden Units Only
  const [hiddenIngredients, setHiddenIngredients] = useState([]);

  const [hiddenSearchQuery, setHiddenSearchQuery] = useState("");
  const [hiddenCurrentPage, setHiddenCurrentPage] = useState(0);
  const [hiddenPageSize, setHiddenPageSize] = useState(25);
  const [hiddenSortedColumn, setHiddenSortedColumn] = useState("unit_name");
  const [hiddenSortDirection, setHiddenSortDirection] = useState("asc");
  const [selectedUnitToUnHide, setSelectedUnitToUnHide] = useState(null);

  const hiddenFilteredIngredients = hiddenIngredients.filter((hiddenUnit) =>
    hiddenUnit.ingredient_name
      .toLowerCase()
      .includes(hiddenSearchQuery.toLowerCase())
  );

  const hiddenPagesCount = Math.ceil(
    hiddenFilteredIngredients.length / hiddenPageSize
  );
  const hiddenStartIndex = hiddenCurrentPage * hiddenPageSize;
  const hiddenEndIndex = hiddenStartIndex + hiddenPageSize;

  const hiddenHandlePrevPage = () => {
    setHiddenCurrentPage((prev) => prev - 1);
  };

  const hiddenHandleNextPage = () => {
    setHiddenCurrentPage((prev) => prev + 1);
  };

  const hiddenSortedUnits = hiddenFilteredIngredients.sort((a, b) => {
    try {
      if (hiddenSortDirection === "asc") {
        return a[hiddenSortedColumn].toLowerCase() >
          b[hiddenSortedColumn].toLowerCase()
          ? 1
          : -1;
      } else {
        return a[hiddenSortedColumn].toLowerCase() <
          b[hiddenSortedColumn].toLowerCase()
          ? 1
          : -1;
      }
    } catch {}
  });

  const hiddenHandleSort = (columnName) => {
    if (hiddenSortedColumn === columnName) {
      // toggle the sort direction if column is already sorted
      setHiddenSortDirection(hiddenSortDirection === "asc" ? "desc" : "asc");
    } else {
      // otherwise, sort by the selected column in ascending order
      setHiddenSortedColumn(columnName);
      setHiddenSortDirection("asc");
    }
  };



  // Fetch Ingredients for Chart
  const fetchHiddenIngredients = () => {
    fetch(`${process.env.REACT_APP_API_URL}/hidden-ingredients`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setHiddenIngredients(data);
      })
      .catch((err) => {
        console.error("Error retrieving user ingredients:", err);
      });
  };

  useEffect(() => {
    // Fetch user units when component is mounted
    fetchIngredients();
    fetchHiddenIngredients();
  }, []);

  if (!isLoggedIn) {
    return (
      <h3>
        Please{" "}
        <a
          className="btn btn-primary"
          href={`/signin?returnUrl=${encodeURIComponent(
            window.location.pathname
          )}`}
        >
          Sign In
        </a>{" "}
        to view
      </h3>
    );
  }

  return (
    <div>
      <div>
        <h2>Add Ingredient</h2>
        <div className="mb-3 col-sm-4">
          <button
            className="btn btn-secondary mt-2"
            onClick={handleAddClickModal}
          >
            Add Ingredient
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Active Ingredients</h2>
          <p>(D) = Default Ingredients</p>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4 ">
              <label htmlFor="page-size-select" className="mr-2">
                Show:
              </label>{" "}
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {pageSize}
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(25)}
                  >
                    25
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(50)}
                  >
                    50
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search units"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={handlePrevPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: pagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          currentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(currentPage - halfMaxPagesToShow, 0),
                pagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, pagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== pagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${currentPage === i && "active"}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          currentPage === pagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={handleNextPage}
          disabled={currentPage === pagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("ingredient_name")}
                  >
                    Ingredient{" "}
                    {sortedColumn === "ingredient_name" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "ingredient_name" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("unit_id")}
                  >
                    Base Unit{" "}
                    {sortedColumn === "unit_id" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "unit_id" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("calories")}
                  >
                    Calories{" "}
                    {sortedColumn === "calories" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "calories" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("protein")}
                  >
                    Protein{" "}
                    {sortedColumn === "protein" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "protein" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("carbs")}
                  >
                    Carbs{" "}
                    {sortedColumn === "carbs" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "carbs" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("fat")}
                  >
                    Fat{" "}
                    {sortedColumn === "fat" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "fat" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("fiber")}
                  >
                    Fiber{" "}
                    {sortedColumn === "fiber" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "fiber" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("sugar")}
                  >
                    Sugar{" "}
                    {sortedColumn === "sugar" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "sugar" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("price")}
                  >
                    Price Per Unit{" "}
                    {sortedColumn === "price" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "price" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th
                    className="text-center clickable"
                    title="Click to sort"
                    onClick={() => handleSort("shopping_aisle")}
                  >
                    Aisle{" "}
                    {sortedColumn === "shopping_aisle" && (
                      <i
                        className={`fas fa-chevron-${
                          sortDirection === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                    {sortedColumn !== "shopping_aisle" && (
                      <i className="fas fa-chevron-down fa-opac" />
                    )}
                  </th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Hide</th>
                </tr>
              </thead>
              <tbody>
                {filteredIngredients
                  .slice(startIndex, endIndex)
                  .map((ingredient) => (
                    <tr key={ingredient.id}>
                      <td>
                        {ingredient.id.toLowerCase().includes("user")
                          ? ingredient.ingredient_name
                          : `${ingredient.ingredient_name} (D)`}
                      </td>
                      <td className="text-center">{ingredient.unit_id}</td>
                      <td className="text-center">
                        {Number.isInteger(ingredient.calories)
                          ? parseFloat(ingredient.calories).toFixed(0)
                          : parseFloat(ingredient.calories).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.protein)
                          ? parseFloat(ingredient.protein).toFixed(0)
                          : parseFloat(ingredient.protein).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.carbs)
                          ? parseFloat(ingredient.carbs).toFixed(0)
                          : parseFloat(ingredient.carbs).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.fat)
                          ? parseFloat(ingredient.fat).toFixed(0)
                          : parseFloat(ingredient.fat).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.fiber)
                          ? parseFloat(ingredient.fiber).toFixed(0)
                          : parseFloat(ingredient.fiber).toFixed(1)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {Number.isInteger(ingredient.sugar)
                          ? parseFloat(ingredient.sugar).toFixed(0)
                          : parseFloat(ingredient.sugar).toFixed(1)}
                      </td>
                      <td className="text-center">${ingredient.price}</td>
                      <td className="text-center">
                        {ingredient.shopping_aisle}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleSelectIngredient(ingredient)}
                        >
                          Edit
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleHideIngredient(ingredient)}
                        >
                          Hide
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* -------------------- */}
        {/* Add Ingredient Modal */}
        {/* -------------------- */}
        <Modal show={showNewModal} onHide={handleAddCloseModal} size="lg">
          <ModalHeader closeButton>
            <ModalTitle>Add Ingredient</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="mb-2 col-sm">
                <label htmlFor="add-ingredient-name" className="form-label">
                  Ingredient Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new ingredient name"
                  value={newIngredientName}
                  onChange={handleNewIngredientNameChange}
                />
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <label htmlFor="add-base-unit" className="form-label">
                    Base Unit of Measurement
                  </label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (unit) => unit.unit_id === newUnitOfMeasurement
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption.unit_id.startsWith("D")) {
                        setNewUnitOfMeasurement(
                          parseInt(selectedOption.unit_id.substring(1))
                        );
                        SetNewUnitOfMeasurementLabel(selectedOption.unit_name);
                        setNewCustomUnitFlag(0);
                      } else {
                        setNewUnitOfMeasurement(selectedOption.unit_id);
                        SetNewUnitOfMeasurementLabel(selectedOption.unit_name);
                        setNewCustomUnitFlag(1);
                      }
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                  />
                </div>

                <div className="mb-3 col-sm">
                  <label htmlFor="add-shopping-aisle" className="form-label">
                    Shopping Aisle
                  </label>
                  <Select
                    options={shoppingOptions}
                    value={shoppingOptions.find(
                      (aisle) => aisle.id === newShoppingAisle
                    )}
                    onChange={(selectedOption) => {
                      setNewShoppingAisle(selectedOption.id);
                    }}
                    getOptionLabel={(option) => option.aisle_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-protein" className="form-label">
                    Protein (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-protein"
                    min="0"
                    value={newProtein}
                    onChange={(e) =>
                      handleNewProteinChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-carbs" className="form-label">
                    Carbs (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-carbs"
                    min="0"
                    value={newCarbs}
                    onChange={(e) =>
                      handleNewCarbsChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-fat" className="form-label">
                    Fat (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-fat"
                    min="0"
                    value={newFat}
                    onChange={(e) =>
                      handleNewFatChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-fiber" className="form-label">
                    Fiber (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-fiber"
                    min="0"
                    value={newFiber}
                    onChange={(e) =>
                      handleNewFiberChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="add-new-sugar" className="form-label">
                    Sugar (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="add-new-sugar"
                    min="0"
                    value={newSugar}
                    onChange={(e) =>
                      handleNewSugarChange(parseFloat(e.target.value))
                    }
                  />
                </div>
                <h4>Set Price:</h4>
                <div className="row">
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="add-store-amount" className="form-label">
                      How many <b>{newUnitOfMeasurementLabel}</b> per store
                      container?
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                      min="0"
                      value={newStoreAmount}
                      onChange={handleNewStoreBaseAmountChange}
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="add-store-price" className="form-label">
                      Price for store container?
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter"
                      min="0"
                      value={newPrice}
                      onChange={handleNewStorePriceAmountChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddIngredient}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* --------------------- */}
        {/* Edit Ingredient Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedIngredient !== null}
          onHide={() => setSelectedIngredient(null)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Ingredient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-2 col-sm">
                <label htmlFor="edit-ingredient-name" className="form-label">
                  Ingredient Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={editIngredientName}
                  onChange={handleEditIngredientNameChange}
                  disabled={
                    !selectedIngredient ||
                    !selectedIngredient.id.includes("user")
                  }
                />
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <label htmlFor="edit-base-unit" className="form-label">
                    Base Unit of Measurement
                  </label>
                  <Select
                    options={unitOptions}
                    value={{
                      unit_id: editUnitOfMeasurementId,
                      unit_name: editUnitOfMeasurement,
                    }}
                    onChange={(selectedOption) => {
                      if (selectedOption.unit_id.startsWith("D")) {
                        setEditUnitOfMeasurementId(
                          parseInt(selectedOption.unit_id.substring(1))
                        );
                        setEditUnitOfMeasurement(selectedOption.unit_name);
                        setEditCustomUnitFlag(0);
                      } else {
                        setEditUnitOfMeasurementId(selectedOption.unit_id);
                        setEditUnitOfMeasurement(selectedOption.unit_name);
                        setEditCustomUnitFlag(1);
                      }
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                    isDisabled={true}
                  />
                </div>

                <div className="mb-3 col-sm">
                  <label htmlFor="edit-shopping-aisle" className="form-label">
                    Shopping Aisle
                  </label>
                  <Select
                    options={shoppingOptions}
                    value={{
                      aisle_name: editShoppingAisle,
                      id: editShoppingAisleId,
                    }}
                    onChange={(selectedOption) => {
                      setEditShoppingAisleId(selectedOption.id);
                      setEditShoppingAisle(selectedOption.aisle_name);
                    }}
                    getOptionLabel={(option) => option.aisle_name}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-protein" className="form-label">
                    Protein (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-protein"
                    min="0"
                    value={editProtein !== null ? editProtein : ""}
                    onChange={(e) =>
                      handleEditProteinChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !selectedIngredient ||
                      !selectedIngredient.id.includes("user")
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-carbs" className="form-label">
                    Carbs (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-carbs"
                    min="0"
                    value={editCarbs !== null ? editCarbs : ""}
                    onChange={(e) =>
                      handleEditCarbsChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !selectedIngredient ||
                      !selectedIngredient.id.includes("user")
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-fat" className="form-label">
                    Fat (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-fat"
                    min="0"
                    value={editFat !== null ? editFat : ""}
                    onChange={(e) =>
                      handleEditFatChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !selectedIngredient ||
                      !selectedIngredient.id.includes("user")
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-fiber" className="form-label">
                    Fiber (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-fiber"
                    min="0"
                    value={editFiber !== null ? editFiber : ""}
                    onChange={(e) =>
                      handleEditFiberChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !selectedIngredient ||
                      !selectedIngredient.id.includes("user")
                    }
                  />
                </div>
                <div className="mb-3 col-sm-1-5">
                  <label htmlFor="edit-new-sugar" className="form-label">
                    Sugar (g)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="edit-new-sugar"
                    min="0"
                    value={editSugar !== null ? editSugar : ""}
                    onChange={(e) =>
                      handleEditSugarChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !selectedIngredient ||
                      !selectedIngredient.id.includes("user")
                    }
                  />
                </div>
                <h4>Set Price:</h4>
                <div className="row">
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="edit-store-amount" className="form-label">
                      How many <b>{editUnitOfMeasurement}</b> per store
                      container?
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                      min="0"
                      value={editStoreAmount !== null ? editStoreAmount : ""}
                      onChange={handleEditStoreBaseAmountChange}
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="edit-store-price" className="form-label">
                      Price for store container?
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter"
                      min="0"
                      value={editPrice !== null ? editPrice : ""}
                      onChange={handleEditStorePriceAmountChange}
                    />
                  </div>
                </div>
              </div>
            </form>
            <Button
              variant="secondary"
              onClick={() => setSelectedIngredient(null)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveIngredient}>
              Save Changes
            </Button>
            <hr />
            <h4>Edit Unit Conversions</h4>
            <div className="row">
              <div
                className="col"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Switch
                  type="switch"
                  id="custom-switch"
                  label={"Inverse Conversion"}
                  checked={inverseConversion}
                  onChange={handleToggleConversion}
                />{" "}
                <OverlayTrigger
                  delay={{ hide: 150, show: 150 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Toggle this to change the conversion statement below to
                      make inputs easier. Eg, swithes "How many Serving(s) in 1x
                      Cup(s)" to "How many Cup(s) in 1x Serving(s)".
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                </OverlayTrigger>{" "}
              </div>
              <div className="row">
                <div className="mb-3 col-sm-4">
                  <label htmlFor="add-base-unit" className="form-label">
                    Add unit to convert to:
                  </label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (unit) => unit.unit_id === newUnitOfMeasurement
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption.unit_id.startsWith("D")) {
                        setNewUnitOfMeasurement(
                          parseInt(selectedOption.unit_id.substring(1))
                        );
                        setNewCustomUnitFlag(0);
                        setSelectedUnitOption(selectedOption.unit_name);
                      } else {
                        setNewUnitOfMeasurement(selectedOption.unit_id);
                        setNewCustomUnitFlag(1);
                        setSelectedUnitOption(selectedOption.unit_name);
                      }
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                  />
                </div>

                <div className="mb-3 col-sm-5">
                  {inverseConversion ? (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is true */}
                      How many <b>{selectedUnitOption}</b> in 1x{" "}
                      <b>{editUnitOfMeasurement}</b>
                    </label>
                  ) : (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is false */}
                      How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                      <b>{selectedUnitOption}</b>
                    </label>
                  )}
                  <input
                    type="number"
                    className="form-control"
                    id="add-conversion-input"
                    min="0"
                    value={addConversionInput}
                    onChange={(e) =>
                      handleAddConversionInput(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>
              <div className="mb-3 col-sm-2 d-flex align-items-end">
                <Button variant="primary" onClick={handleAddUnitConversion}>
                  Add
                </Button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Unit Conversion</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {ingredientConversionList.map((conversion) => (
                    <tr key={conversion.id}>
                      <td>{`${conversion.unit_name} (${conversion.conversion_factor})`}</td>
                      <td>
                        {" "}
                        {conversion.user_id !== 1 ? (
                          <Button
                            variant="primary"
                            onClick={() => handleEditConversion(conversion)}
                          >
                            Edit
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {" "}
                        {conversion.user_id !== 1 ? (
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteConversion(conversion)}
                          >
                            Delete
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
        {/* --------------------- */}
        {/* Hide Ingredient Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedIngredientToHide !== null}
          onHide={() => setSelectedIngredientToHide(null)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Hide Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to hide this ingredient? Hidden ingredients
            are still shown in your existing recipes, but you will no longer see
            them in the dropdown when editing or adding ingredients.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedIngredientToHide(null)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmHideIngredient}>
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
        {/* -------------------- */}
        {/* Unhide Ingredient Modal */}
        {/* -------------------- */}
        <Modal
          show={selectedIngredientToUnhide !== null}
          onHide={() => setSelectedIngredientToUnhide(null)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Hide Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to unhide this ingredient?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedIngredientToUnhide(null)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmUnhideIngredient}>
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
        {/* --------------------- */}
        {/* Edit Conversion Modal */}
        {/* --------------------- */}
        <Modal
          show={selectedConversionToEdit !== null}
          onHide={() => setSelectedConversionToEdit(null)}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Conversion</Modal.Title>
          </Modal.Header>
          <Modal.Body>


              <div className="row">
                <div
                  className="col"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Form.Switch
                    type="switch"
                    id="custom-switch"
                    label={"Inverse Conversion"}
                    checked={inverseConversion}
                    onChange={handleToggleConversion}
                  />{" "}
                  <OverlayTrigger
                    delay={{ hide: 150, show: 150 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Toggle this to change the conversion statement below to
                        make inputs easier. Eg, swithes "How many Serving(s) in
                        1x Cup(s)" to "How many Cup(s) in 1x Serving(s)".
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faQuestionCircle} />
                  </OverlayTrigger>{" "}
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col">
                <label htmlFor="add-base-unit" className="form-label">
                    Edit unit to convert to:
                  </label>
                  <Select
                    options={unitOptions}
                    value={{
                      unit_id: editConversionUnitOfMeasurementId,
                      unit_name: editConversionUnitOfMeasurement,
                    }}
                    onChange={(selectedOption) => {
                      if (selectedOption.unit_id.startsWith("D")) {
                        setEditConversionUnitOfMeasurementId(
                          parseInt(selectedOption.unit_id.substring(1))
                        );
                        setEditConversionUnitOfMeasurement(
                          selectedOption.unit_name
                        );
                        setEditConversionCustomUnitFlag(0);
                      } else {
                        setEditConversionUnitOfMeasurementId(
                          selectedOption.unit_id
                        );
                        setEditConversionUnitOfMeasurement(
                          selectedOption.unit_name
                        );
                        setEditConversionCustomUnitFlag(1);
                      }
                    }}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option.unit_id}
                    isSearchable={true}
                  />
                </div>

                <div className="mb-3 col">
                                  
                  {inverseConversion ? (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is true */}
                      How many <b>{editConversionUnitOfMeasurement}</b> in 1x{" "}
                      <b>{editUnitOfMeasurement}</b>
                    </label>
                  ) : (
                    <label
                      htmlFor="add-conversion-input"
                      className="form-label"
                    >
                      {/* Content when inverseConversion is false */}
                      How many <b>{editUnitOfMeasurement}</b> in 1x{" "}
                      <b>{editConversionUnitOfMeasurement}</b>
                    </label>
                  )}
                  <input
                    type="number"
                    className="form-control"
                    id="add-conversion-input"
                    min="0"
                    value={editConversionFactor}
                    onChange={(e) =>
                      handleEditConversionInput(parseFloat(e.target.value))
                    }
                  />
                </div>
              </div>

              <div className="mb-3 col-sm-2 d-flex align-items-end">
                <Button variant="primary" onClick={handleUpdateUnitConversion}>
                  Update
                </Button>
              </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setSelectedConversionToEdit(null)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/*
      -------------
      Hidden Units
    Hide and Unhide Option
    ----------
     */}

      <div>
        <h2>Hidden Ingredients</h2>
        <p>
          Hidden ingredients are still shown in existing recipes; however, they
          will not appear in the dropdown when customizing recipes.
        </p>
        <div className="row">
          <div className="col-sm-4 ">
            <label htmlFor="page-size-select" className="mr-2">
              Show:
            </label>{" "}
            <div className="btn-group mr-2">
              <button
                type="button"
                className="btn btn-sm btn-secondary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {hiddenPageSize}
              </button>
              <div className="dropdown-menu">
                <button
                  className="dropdown-item"
                  onClick={() => setHiddenPageSize(10)}
                >
                  10
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setHiddenPageSize(25)}
                >
                  25
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setHiddenPageSize(50)}
                >
                  50
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setHiddenPageSize(100)}
                >
                  100
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search units"
              value={hiddenSearchQuery}
              onChange={(e) => setHiddenSearchQuery(e.target.value)}
            />
          </div>
          <div className="col-sm-4">
  <nav>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${hiddenCurrentPage === 0 && "disabled"}`}>
        <button
          className="page-link"
          onClick={hiddenHandlePrevPage}
          disabled={hiddenCurrentPage === 0}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: hiddenPagesCount }, (_, i) => {
        // Display only a subset of pages around the current page
        const maxPagesToShow = 5; // Adjust as needed
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        const startPage =
          hiddenCurrentPage <= halfMaxPagesToShow
            ? 0
            : Math.min(
                Math.max(hiddenCurrentPage - halfMaxPagesToShow, 0),
                hiddenPagesCount - maxPagesToShow
              );
        const endPage = Math.min(startPage + maxPagesToShow, hiddenPagesCount);

        if (i === startPage && startPage !== 0) {
          return (
            <li key="ellipsis-start" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i === endPage && endPage !== hiddenPagesCount) {
          return (
            <li key="ellipsis-end" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }

        if (i >= startPage && i < endPage) {
          return (
            <li
              key={i}
              className={`page-item ${
                hiddenCurrentPage === i && "active"
              }`}
            >
              <button
                className="page-link"
                onClick={() => setHiddenCurrentPage(i)}
              >
                {i + 1}
              </button>
            </li>
          );
        }

        return null;
      })}
      <li
        className={`page-item ${
          hiddenCurrentPage === hiddenPagesCount - 1 && "disabled"
        }`}
      >
        <button
          className="page-link"
          onClick={hiddenHandleNextPage}
          disabled={hiddenCurrentPage === hiddenPagesCount - 1}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
</div>

        </div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("ingredient_name")}
                >
                  Ingredient{" "}
                  {hiddenSortedColumn === "ingredient_name" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "ingredient_name" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("unit_id")}
                >
                  Base Unit{" "}
                  {hiddenSortedColumn === "unit_id" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {sortedColumn !== "unit_id" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("calories")}
                >
                  Calories{" "}
                  {hiddenSortedColumn === "calories" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "calories" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("protein")}
                >
                  Protein{" "}
                  {hiddenSortedColumn === "protein" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "protein" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("carbs")}
                >
                  Carbs{" "}
                  {hiddenSortedColumn === "carbs" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "carbs" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("fat")}
                >
                  Fat{" "}
                  {hiddenSortedColumn === "fat" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "fat" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("fiber")}
                >
                  Fiber{" "}
                  {hiddenSortedColumn === "fiber" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "fiber" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("sugar")}
                >
                  Sugar{" "}
                  {hiddenSortedColumn === "sugar" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "sugar" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() => hiddenHandleSort("price")}
                >
                  Price Per Unit{" "}
                  {hiddenSortedColumn === "price" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "price" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th
                  className="text-center clickable"
                  title="Click to sort"
                  onClick={() =>
                    hiddenHandleSort("shopping_aisleshopping_aisle")
                  }
                >
                  Aisle{" "}
                  {hiddenSortedColumn === "shopping_aisle" && (
                    <i
                      className={`fas fa-chevron-${
                        hiddenSortDirection === "asc" ? "up" : "down"
                      }`}
                    />
                  )}
                  {hiddenSortedColumn !== "shopping_aisle" && (
                    <i className="fas fa-chevron-down fa-opac" />
                  )}
                </th>
                <th className="text-center">Edit</th>
                <th className="text-center">Hide</th>
              </tr>
            </thead>
            <tbody>
              {hiddenFilteredIngredients
                .slice(hiddenStartIndex, hiddenEndIndex)
                .map((hiddenIngredient) => (
                  <tr key={hiddenIngredient.id}>
                    <td>
                      {hiddenIngredient.id.toLowerCase().includes("user")
                        ? hiddenIngredient.ingredient_name
                        : `${hiddenIngredient.ingredient_name} (D)`}
                    </td>
                    <td className="text-center">{hiddenIngredient.unit_id}</td>
                    <td className="text-center">
                      {Number.isInteger(hiddenIngredient.calories)
                        ? parseFloat(hiddenIngredient.calories).toFixed(0)
                        : parseFloat(hiddenIngredient.calories).toFixed(1)}
                    </td>
                    <td className="text-center">
                      {" "}
                      {Number.isInteger(hiddenIngredient.protein)
                        ? parseFloat(hiddenIngredient.protein).toFixed(0)
                        : parseFloat(hiddenIngredient.protein).toFixed(1)}
                    </td>
                    <td className="text-center">
                      {" "}
                      {Number.isInteger(hiddenIngredient.carbs)
                        ? parseFloat(hiddenIngredient.carbs).toFixed(0)
                        : parseFloat(hiddenIngredient.carbs).toFixed(1)}
                    </td>
                    <td className="text-center">
                      {" "}
                      {Number.isInteger(hiddenIngredient.fat)
                        ? parseFloat(hiddenIngredient.fat).toFixed(0)
                        : parseFloat(hiddenIngredient.fat).toFixed(1)}
                    </td>
                    <td className="text-center">
                      {" "}
                      {Number.isInteger(hiddenIngredient.fiber)
                        ? parseFloat(hiddenIngredient.fiber).toFixed(0)
                        : parseFloat(hiddenIngredient.fiber).toFixed(1)}
                    </td>
                    <td className="text-center">
                      {" "}
                      {Number.isInteger(hiddenIngredient.sugar)
                        ? parseFloat(hiddenIngredient.sugar).toFixed(0)
                        : parseFloat(hiddenIngredient.sugar).toFixed(1)}
                    </td>
                    <td className="text-center">${hiddenIngredient.price}</td>
                    <td className="text-center">
                      {hiddenIngredient.shopping_aisle}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleSelectIngredient(hiddenIngredient)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleUnhideIngredient(hiddenIngredient)}
                      >
                        Unhide
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Ingredients;
