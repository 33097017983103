import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/style.scss";

function ShoppingAisles() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/isLoggedIn`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          setIsLoggedIn(true);
        } else {
          throw new Error("User is not logged in");
        }
      })
      .catch((error) => {
        console.error("Error checking if user is logged in:", error);
        setIsLoggedIn(false);
      });
  }, []);

  const [aisles, setAisles] = useState([]);
  const [newAisleName, setNewAisleName] = useState("");
  const [selectedAisle, setSelectedAisle] = useState(null);
  const [editAisleName, setAisleName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);


  const filteredAisles = aisles.filter((aisles) =>
    aisles.aisle_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pagesCount = Math.ceil(filteredAisles.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleNewAisleNameChange = (event) => {
    setNewAisleName(event.target.value);

  };

  const fetchAisles = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/shopping_aisle`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Check if data is loaded correctly
        setAisles(data);
      })
      .catch((err) => {
        console.error("Error retrieving user units:", err);
      });
  };

  const handleAddAisle = () => {
    // Send POST request to add new unit
    fetch(`${process.env.REACT_APP_API_URL}/add-aisle`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        aisleName: newAisleName.trim(),

      }),
    })
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchAisles();
          setNewAisleName("");
        } else {
          throw new Error("Error adding new unit");
        }
      })
      .catch((err) => {
        console.error("Error adding new unit:", err);
      });
  };

  const handleSelectAisle = (aisle) => {
    setSelectedAisle(aisle);
    setAisleName(aisle.aisle_name);
  };

  const handleEditUnitNameChange = (event) => {
    setAisleName(event.target.value);
  };

  const handleSaveAisle = () => {
    // Send POST request to update unit
    fetch(
      `${process.env.REACT_APP_API_URL}/edit-aisle?id=${selectedAisle.id}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedAisle.id,
          aisleName: editAisleName.trim(),
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          // Reload units data
          fetchAisles();
          setSelectedAisle(null);
          setAisleName("");
        } else {
          throw new Error("Error updating aisle");
        }
      })
      .catch((err) => {
        console.error("Error updating aisle:", err);
      });
  };
  
  const handleDeleteAisle = (aisle) => {
    if (
      window.confirm(
        `Are you sure you want to delete the aisle "${aisle.aisle_name}"?`
      )
    ) {
      fetch(`${process.env.REACT_APP_API_URL}/delete-aisle?id=${aisle.id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            // Reload aisles data
            fetchAisles();
          } else {
            throw new Error("Error deleting aisle");
          }
        })
        .catch((err) => {
          console.error("Error deleting aisle:", err);
        });
    }
  };

// Move a shopping aisle up by swapping its sortOrder with the previous aisle (where sortOrder = currentSortOrder - 1)
  const handleMoveAisleUp = (aisle) => {
    const userAisles = filteredAisles.filter((a) => a.user_id === aisle.user_id);
    const prevAisle = userAisles
      .filter((a) => a.sort_order < aisle.sort_order)
      .sort((a, b) => b.sort_order - a.sort_order)[0];
    
    if (prevAisle) {
      // Move current aisle up (swap sort orders with previous aisle)
      fetch(`${process.env.REACT_APP_API_URL}/swap-aisles`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id1: aisle.id,
          sortOrder1: prevAisle.sort_order,
          id2: prevAisle.id,
          sortOrder2: aisle.sort_order,
        }),
      })
        .then((res) => {
          if (res.ok) {
            // Reload aisles data
            fetchAisles();
          } else {
            throw new Error("Error swapping aisles");
          }
        })
        .catch((err) => {
          console.error("Error swapping aisles:", err);
        });
    }
  };

// Move a shopping aisle down by swapping its sortOrder with the next aisle (where sortOrder = currentSortOrder + 1)
const handleMoveAisleDown = (aisle) => {
  const userAisles = filteredAisles.filter((a) => a.user_id === aisle.user_id);
  const maxSortOrder = Math.max(...userAisles.map((a) => a.sort_order)); // Find the maximum sort order for the user
  let nextAisle = userAisles.find(next => next.sort_order > aisle.sort_order);
if (!nextAisle && aisle.sort_order < maxSortOrder) {
  nextAisle = userAisles.find(next => next.sort_order === maxSortOrder);
}

if (nextAisle) {
  // Move current aisle down (swap sort orders with next aisle)
  fetch(`${process.env.REACT_APP_API_URL}/swap-aisles`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id1: aisle.id,
      sortOrder1: nextAisle.sort_order,
      id2: nextAisle.id,
      sortOrder2: aisle.sort_order,
    }),
  })
    .then((res) => {
      if (res.ok) {
        // Reload aisles data
        fetchAisles();
      } else {
        throw new Error("Error swapping aisles");
      }
    })
    .catch((err) => {
      console.error("Error swapping aisles:", err);
    });
}
};


  useEffect(() => {
    // Fetch user units when component is mounted
    fetchAisles();
  }, []);

  if (!isLoggedIn) {
    return <h3>Please <a className="btn btn-primary" href={`/signin?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
    Sign In
  </a> to view</h3>;
  }

  return (
    <div>
      <div>
        <h2>Add Shopping Aisle</h2>
        <div className="mb-3 col-sm-4">
          <input
            type="text"
            className="form-control"
            placeholder="Enter aisle name or number"
            value={newAisleName}
            onChange={handleNewAisleNameChange}

          />

          <button className="btn btn-secondary mt-2" onClick={handleAddAisle}>
            Add Aisle
          </button>
        </div>
        <div className="mb-3 col-6">
          <h2>Shopping Aisles</h2>
          <p>To customize the order that your shopping aisles appear on the list, simply move aisles up and down using the buttons in each row. Aisles will appear on the list in the order that you arrange them. For example, to have your list display aisles in the order 'Produce, 1, 2, 3, 4 ... 19, 20, Dairy', simply move each aisle in that order.</p>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4 ">
              <label htmlFor="page-size-select" className="mr-2">
                Show:
              </label>{" "}
              <div className="btn-group mr-2">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {pageSize}
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(25)}
                  >
                    25
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(50)}
                  >
                    50
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => setPageSize(100)}
                  >
                    100
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search aisles"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
              <nav>
                <ul className="pagination justify-content-end">
                  <li
                    className={`page-item ${currentPage === 0 && "disabled"}`}
                  >
                    <button
                      className="page-link"
                      onClick={handlePrevPage}
                      disabled={currentPage === 0}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: pagesCount }, (_, i) => (
                    <li
                      key={i}
                      className={`page-item ${currentPage === i && "active"}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(i)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === pagesCount - 1 && "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={handleNextPage}
                      disabled={currentPage === pagesCount - 1}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="container" style={{ padding: "0 0px" }}>
            <div className="table-responsive">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="text-center w-50">Aisle Name/#</th>
                    <th className="text-center w-25">Edit</th>
                    <th className="text-center w-10">Move Up</th>
                    <th className="text-center w-10">Move Down</th>
                    <th className="text-center w-10">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAisles.slice(startIndex, endIndex).map((aisle) => (
                    <tr key={aisle.id}>
                      <td className="text-center">{aisle.aisle_name}</td>

                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleSelectAisle(aisle)}
                        >
                          Edit
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleMoveAisleUp(aisle)}
                        >
                          Up
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleMoveAisleDown(aisle)}
                        >
                          Down
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteAisle(aisle)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
          show={selectedAisle !== null}
          onHide={() => setSelectedAisle(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="edit-unit-name" className="form-label">
                  Unit Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="edit-unit-name"
                  value={editAisleName}
                  onChange={handleEditUnitNameChange}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedAisle(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveAisle}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ShoppingAisles;
