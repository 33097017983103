import React, { useState } from "react";
import "../styles/style.scss";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const returnUrl = urlSearchParams.get('returnUrl');

  
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!emailOrUsername || !password) {
      alert("Email/Username and password are required");
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/signin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ emailOrUsername, password }),
      credentials: "include",
    })
      .then((response) => {

        return response.json(); // parse response body as JSON
      })
      .then((data) => {
        if (data.errorMessage) {
          console.error("Error signing in:", data.errorMessage);
        } else {
          console.log("User signed in successfully");
          if (
            document.referrer &&
            document.referrer.startsWith(window.location.origin) && returnUrl &&
            !returnUrl.includes("reset-password")
          ) {
            window.location.href = returnUrl

      
          } else {
            window.location.href = `${process.env.REACT_APP_CLIENT_URL}/recipes`
          }
        }
      })
      .catch((error) => console.error("Error signing in:", error.stack));
  };

  return (
    <div className="container mx-auto" style={{maxWidth: "720px"}}>
      <h2>Please Sign In:</h2>
    <form className="p-3 shadow-lg rounded" onSubmit={handleSubmit} action="/signin">
      <div className="form-group">
        <label htmlFor="emailOrUsername">Email/Username:</label>
        <input
          type="text"
          className="form-control"
          id="emailOrUsername"
          name="emailOrUsername"
          value={emailOrUsername}
          onChange={(event) => setEmailOrUsername(event.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
      </div>
      <div>
      <a href={`/forgot-password`}>Forgot Password</a></div>
      <button type="submit" className="btn btn-primary mt-2">Sign in</button>
    </form>
    </div>
  );
}

export default SignIn;
